import React from 'react';
import { Redirect, Route } from 'react-router';
import { AnimatedSwitch } from 'react-router-transition';

import { ExchangeRatesProvider } from '../../../contexts/ExchangeRatesContext';
import { LocaleContext } from '../../../contexts/LocaleContext';
import { useUserOrRedirect } from '../../../hooks/useUserOrRedirect';

import { getRouteGroupList, getRouteGroupNew, getRouteGroupShow } from '../../../utils/navRoutes';
import { routeTransitionProps } from '../../../utils/visual';

import { GroupSpecific } from '../GroupSpecific';

import { GroupList } from './List';
import { GroupNew } from './New';

export const Groups: React.FC = () => {
  const { locale } = React.useContext(LocaleContext);
  const routePrefix = `/${locale}`;
  const { returnContent, user } = useUserOrRedirect();

  if (!user) {
    return returnContent;
  }

  return (
    <ExchangeRatesProvider>
      <AnimatedSwitch {...routeTransitionProps}>
        <Route exact path={`${routePrefix}/${getRouteGroupList()}`} component={GroupList} />

        <Route exact path={`${routePrefix}/${getRouteGroupNew()}`} component={GroupNew} />

        <Route path={`${routePrefix}/${getRouteGroupShow({ groupId: ':groupId' })}`} component={GroupSpecific} />

        <Redirect to={routePrefix} />
      </AnimatedSwitch>
    </ExchangeRatesProvider>
  );
};
