import { complaintEmail, siteUrl, companyData, mainPrivacyUrl, serviceName } from '../../../../utils/contactData';

export const pl = `
# Regulamin

## §1 Definicje
1. **Usługodawca** – ${companyData.name} z siedzibą pod adresem ${companyData.addressStreet}, ${companyData.addressZipCode} ${companyData.addressCity}, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla M. St. Warszawy XII Wydział Gospodarczy KRS, pod numerem KRS ${companyData.krs}, NIP ${companyData.nip}, REGON ${companyData.regon}.
1. **Serwis** – portal internetowy ${serviceName} prowadzony przez Usługodawcę pod adresem URL: ${siteUrl} do którego uprawniony jest Usługodawca.
1. **Użytkownik** – osoba, która dokonała Rejestracji Konta Użytkownika.
1. **Rejestracja** – proces zakładania Konta.
Konto Użytkownika – wpis w bazie danych Serwisu, za pomocą którego Użytkownik otrzymuje możliwość dostępu do określonych Usług po podaniu loginu i hasła.
1. **Usługa** – usługa świadczona przez Usługodawcę drogą elektroniczną, bez jednoczesnej fizycznej obecności stron. Usługa polega na nieformalnym odnotowywaniu i rozliczaniu należności zaistniałych pomiędzy Użytkownikami. Usługa oferowana jest w formie zamkniętej, dostępnej dla Użytkowników, który dokonali Rejestracji - umożliwiająca korzystanie z dostępnych funkcjonalności Serwisu za pomocą Konta Użytkownika.
1. **Grupa** - zapisany w bazie danych zbiór informacji o powiązanych Członkach Grupy, ich Wydatkach oraz Płatnościach
1. **Członek Grupy** - zapisane w bazie danych przypisanie Konta Użytkownika do Grupy 
1. **Wydatek** - zapisana w bazie danych informacja o wydatku poniesionym przez Członka Grupy
1. **Płatność** - zapisana w bazie danych informacja o płatności dokonanej przez Członka Grupy
1. **Bilans** - nieformale rozliczenie zaległości finansowych pomiędzy Użytkownikami
1. **Regulamin** – niniejszy Regulamin.

## §2 Rejestracja Konta Użytkownika
1. Użytkownikiem Serwisu może być osoba pełnoletnia, posiadająca pełną zdolność do czynności prawnych.
1. Rejestracja w Serwisie jest nieodpłatna. Rejestracja Konta Użytkownika odbywa się za pomocą formularza elektronicznego dostępnego online.
1. W celu Rejestracji należy podać następujące dane: email, hasło, nazwę użytkownika. Wprowadzając powyższe dane, Użytkownik oświadcza, że są one prawdziwe, kompletne i rzetelne.
1. Na wskazany podczas wypełniania formularza rejestracyjnego adres e-mail, w terminie nie dłuższym niż 14 dni, zostanie wysłana wiadomość, wskazująca sposób potwierdzenia Rejestracji Konta Użytkownika. Z chwilą potwierdzenia Rejestracji dochodzi do zawarcia pomiędzy Użytkownikiem a Usługodawcą Umowy. Umowa zawierana jest na czas nieokreślony.
1. W terminie 14 dni od zawarcia Umowy, Użytkownik może od niej odstąpić bez podania przyczyny. Odstąpienie od Umowy następuje przez złożenie oświadczenia o odstąpieniu od Umowy. Oświadczenie o odstąpieniu należy przesłać na adres siedziby Usługodawcy w formie papierowej (pocztą tradycyjną). Do zachowania terminu wystarczy wysłanie oświadczenia przed jego upływem. Użytkownik traci prawo do odstąpienia od Umowy, jeżeli za wyraźną zgodą Użytkownika, nastąpiło rozpoczęcie świadczenia usług przez Usługodawcę przed terminem do odstąpienia od Umowy.
1. Po prawidłowej Rejestracji Usługodawca, w terminie nie dłuższym niż 14 dni, tworzy Konto Użytkownika. Jeden Użytkownik może posiadać wyłącznie jedno Konto Użytkownika. Nie jest dopuszczalne udostępnianie Konta Użytkownika innym Użytkownikom, ani korzystanie z innych Kont Użytkowników. Użytkownik powinien zachować swoje hasło do Konta Użytkownika w tajemnicy.
1. Rozwiązanie Umowy następuje przez usunięcie Konta Użytkownika. Usługodawca udostępnia funkcjonalność do usunięcia Konta Użytkownika.
1. Usługodawca może rozwiązać Umowę z Użytkownikiem ze skutkiem natychmiastowym w przypadku gdy:
    - Użytkownik zakłada wiele Kont Użytkownika;
    - Użytkownik obraża innych Użytkowników, narusza dobra osobiste innych Użytkowników, narusza dobre obyczaje, Regulamin lub obowiązujący porządek prawny;
    - Użytkownik udostępnia swój login lub hasło innemu Użytkownikowi lub osobie trzeciej;
    - zostanie podjęta decyzja o zaprzestaniu świadczenia Usług - w takim przypadku Usługodawca powiadomi Użytkownika w sposób przyjęty dla zmiany Regulaminu.
    
    W powyższych przypadkach Usługodawca zablokuje Konto Użytkownika i wyśle do Użytkownika wiadomość na adres e-mail podany podczas Rejestracji z podaniem przyczyny wypowiedzenia Umowy lub wyświetli w Serwisie informację o wypowiedzeniu Umowy.

## §3 Rodzaje i warunki Usług
1. Usługodawca świadczy między innymi następujące Usługi:
    - umożliwienie tworzenia Grup,
    - umożliwienie zapraszania istniejących Użytkowników do Grup,
    - umożliwienie zapraszania osób trzecich do Serwisu,
    - umożliwienie dodawania, edycji i usuwania Wydatku,
    - umożliwienie dodawania i usuwania Płatności,
    - umożliwienie wglądu w Bilans,
1. Usługodawca zastrzega sobie prawo do wprowadzenia nowych rodzajów Usług (funkcjonalności Serwisu), jak również do zaprzestania świadczenia wybranych Usług.
1. Usługodawca nie pobiera opłat za Rejestrację. Usługodawca zastrzega sobie prawo wprowadzenia odpłatności za dostęp do poszczególnych funkcjonalności Serwisu.
1. Usługodawca może kierować reklamy do Użytkowników Serwisu.
1. Użytkownicy mogą:
    - tworzyć Grupy,
    - zapraszać istniejących Użytkowników do Grup,
    - zapraszać osoby trzecie do Serwisu,
    - dodawać, edytować i usuwać Wydatki,
    - dodawać i usuwać Płatności,

## §4 Zasady ponoszenia odpowiedzialności
1. Usługodawca nie odpowiada za jakichkolwiek dane Grup, Wydatków i Płatności, w szczególności Usługodawca nie odpowiada za kompletność, rzetelność, prawdziwość lub przydatność tych danych.
1. Usługodawca nie odpowiada za faktyczne uregulowanie należności wynikających z wporwadzonych Wydatków i Płatności.
1. Osoba posiadająca Konto w Serwisie zobowiązana jest do utrzymywania danych dostępowych do Konta w poufności, w szczególności nie powinna udostępniać loginu i hasła osobom trzecim. Usługodawca nie ponosi odpowiedzialności za nieuprawnione udostępnienie loginu i hasła, jak również za nieuprawniony dostęp do Konta lub Profilu.
1. Użytkownik może ponosić odpowiedzialność z tytułu dodania treści naruszającej dobra osobiste innych Użytkowników, jak również w inny sposób wkraczającą w prawa innych Użytkowników.
1. W przypadku otrzymania przez Usługodawcę urzędowego zawiadomienia lub uzyskania wiarygodnej wiadomości o bezprawnym charakterze przechowywanych danych dostarczonych przez Użytkownika i niezwłocznego uniemożliwienia dostępu do tych danych, Usługodawca nie ponosi odpowiedzialności za szkodę powstałą w wyniku uniemożliwienia dostępu do tych danych.
1. Usługodawca odpowiada za niewykonanie lub nienależyte wykonanie Usługi w zakresie określonym postanowieniami Regulaminu oraz obowiązującymi przepisami prawa.
1. Usługodawca nie ponosi jakiejkolwiek odpowiedzialności za utracone korzyści.
1. Niewykonania lub nienależytego wykonania Usługi nie powoduje:
    - niesprawność sprzętu należącego do Użytkownika lub przez niego użytkowanego;
    - ograniczenie lub zakłócenia w dostępie do sieci Internet;
    - inne zakłócenia w korzystaniu z Usługi wynikające ze sprzecznego z Umową lub Regulaminem działania albo zaniechania Użytkownika.

## §5 Dane osobowe
1. Korzystanie ze wszystkich funkcji Serwisu może wymagać udostępnienia danych osobowych.
1. Przetwarzanie danych osobowych odbywa się zgodnie z [Polityką prywatności Serwisu](${mainPrivacyUrl}).
1. Podczas Rejestracji należy wyrazić zgodę na nieodpłatne przetwarzanie danych osobowych dla potrzeb świadczenia Usług przez Usługodawcę na warunkach określonych przepisami ustawy z dnia 29 sierpnia 1997 r. o ochronie danych osobowych. Administratorem danych osobowych jest Usługodawca.
1. Usługodawca zapewnia możliwość weryfikacji, poprawienia lub też usunięcia danych osobowych. Usługodawca jako administrator danych osobowych, dba o bezpieczeństwo udostępnionych danych, w szczególności przed dostępem osób nieupoważnionych. Baza danych osobowych prowadzona przez Usługodawcę może podlegać zgłoszeniu przez do Generalnego Inspektora Ochrony Danych Osobowych, o ile wynikać to będzie z przepisów prawa.
1. Zawierając umowę o świadczenie Usług Użytkownik wyraża zgodę na otrzymywanie na wskazane konto pocztowe komunikatów systemowych o przerwach technicznych w działaniu Serwisu, zmianach w Regulaminie, nowych funkcjonalnościach oraz informacji handlowych.
1. Użytkownik może w każdym czasie odwołać zgodę na przesyłanie informacji handlowej. Usługodawca rekomenduje, aby odwołanie zgody na przesyłanie informacji handlowej nastąpiło w formie pisemnej.

## §6 Warunki techniczne
1. Do korzystania z Serwisu niezbędne jest korzystanie z komputera z dostępem do Internetu, wyposażonego w przeglądarkę Internet Explorer, Mozilla Firefox, Google Chrome albo inną porównywalną przeglądarkę zaktualizowaną do najnowszej wersji.
1. Do korzystania z Serwisu w pełnej funkcjonalności może być konieczne ustalenie w ustawieniach przeglądarki odpowiedniego poziomu Cookies, protokołu bezpiecznej transmisji danych SSL bądź obsługi aplikacji JavaScript.
1. Usługodawca informuje, że niespełnienie wymogów technicznych może powodować nieprawidłowe działanie usługi, za co Usługodawca nie ponosi odpowiedzialności.
1. Usługodawca zapewnia, że Serwis będzie dostępny przez czas nie krótszy niż 360 godzin miesięcznie. Przerwy w dostępie do Serwisu mogą być spowodowane w szczególności pracami technicznymi lub konserwacyjnymi. W przypadku przerw w możliwości dostępu do Serwisu z powodu awarii, Usługodawca dołoży starań, aby usunąć awarię w najkrótszym możliwym czasie.

## §7 Reklamacje
1. Każdemu Użytkownikowi przysługuje prawo do złożenia reklamacji w sprawach związanych z Usługą. Reklamacje rozpatruje Usługodawca.
1. Reklamacje należy zgłaszać pisemnie (pocztą tradycyjną) na adres siedziby Usługodawcy lub przesłać za pośrednictwem poczty elektronicznej na adres: ${complaintEmail}. Reklamacje należy zgłaszać bez zbędnej zwłoki.
1. Zgłoszenie reklamacyjne powinno zawierać dane umożliwiające kontakt ze zgłaszającym (np. adres korespondencyjny, nr telefonu kontaktowego lub adres poczty elektronicznej), a także wskazywać przedmiot reklamacji i okoliczności ją uzasadniające. Reklamacja powinna również zawierać oznaczenie sposobu i formy kontaktu ze zgłaszającym.
1. Usługodawca ma obowiązek rozpatrzyć reklamację w terminie 21 dni roboczych i niezwłocznie poinformować o wyniku postępowania reklamacyjnego.
1. Reklamacje zawierające treści wulgarne lub obraźliwe dla Usługodawcy nie będą rozpatrywane.

## §8 Postanowienia końcowe
1. W sprawach nieuregulowanych Regulaminem stosuje się przepisy prawa polskiego, w szczególności Kodeksu cywilnego, ustawy o prawie autorskim i prawach pokrewnych, ustawy Prawo telekomunikacyjne, ustawy o ochronie danych osobowych oraz ustawy o świadczeniu usług drogą elektroniczną.
1. Właściwym do rozstrzygania sporów wynikających ze świadczenia Usług, w przypadku sporów z Użytkownikami niebędącymi konsumentami jest sąd właściwy dla siedziby Usługodawcy. W przypadku sporów z Użytkownikiem będącym konsumentem sądem właściwym jest sąd określony w przepisach Kodeksu postępowania cywilnego.
1. Aktualny tekst Regulaminu opublikowany jest w Serwisie w podstronie „Regulamin” oraz dostępny jest w siedzibie Usługodawcy. Regulamin jest udostępniany nieodpłatnie przed zawarciem Umowy oraz na każde żądanie Użytkownika, w formie umożliwiającej jego pobranie, utrwalenie i wydrukowanie.
1. Usługodawca zastrzega sobie prawo do zmiany Regulaminu. O planowanej zmianie Regulaminu, Usługodawca powiadomi w terminie nie krótszym niż 7 dni do dnia wejścia w życie zmienionego Regulaminu przez wysłanie wiadomości na adres e-mail wskazany podczas Rejestracji, bądź przez wyświetlenie informacji o zmianie Regulaminu w Serwisie.
`;
