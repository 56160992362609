import React, { useEffect, useState } from 'react';
import { IItemRendererProps, ItemRenderer, Select } from '@blueprintjs/select';
import { Button, MenuItem } from '@blueprintjs/core';
import { FormattedMessage } from 'react-intl';

import { lowerCase, startCase } from 'lodash';

import { SplitKind } from '../../../models/SplitKind';

import { getMessage } from '../../../utils/getMessages';

import { messages } from './messages';

const splitKindValues: SplitKind[] = Object.keys(SplitKind).map(k => SplitKind[k as keyof typeof SplitKind]);

const areSplitKindKeysEqual = (splitKind1: SplitKind, splitKind2: SplitKind) => splitKind1 === splitKind2;

const itemRenderer: ItemRenderer<SplitKind> = (splitKind, { handleClick, modifiers }: IItemRendererProps) => (
  <MenuItem
    key={splitKind}
    active={modifiers.active}
    disabled={modifiers.disabled}
    text={<FormattedMessage {...getMessage(`split${startCase(lowerCase(splitKind))}`, messages)} />}
    onClick={handleClick}
  />
);

const TypedSelect = Select.ofType<SplitKind>();

type Props = {
  onChange: (selectedSplitKind: SplitKind) => unknown;
  splitKind?: SplitKind;
  disabled?: boolean;
};

export const SplitKindSelect: React.FC<Props> = ({ splitKind, onChange, disabled = false }) => {
  const [selectedSplitKind, setSelectedSplitKind] = useState<SplitKind | undefined>(splitKind);

  const onItemSelect = (splitKind: SplitKind) => {
    setSelectedSplitKind(splitKind);
    onChange(splitKind);
  };

  useEffect(() => {
    splitKind && onItemSelect(splitKind);
  }, [splitKind]);

  return (
    <TypedSelect
      disabled={disabled}
      items={splitKindValues}
      itemRenderer={itemRenderer}
      onItemSelect={onItemSelect}
      itemsEqual={areSplitKindKeysEqual}
      filterable={false}
    >
      {selectedSplitKind ? (
        <Button
          text={<FormattedMessage {...getMessage(`split${startCase(lowerCase(selectedSplitKind))}`, messages)} />}
          rightIcon="double-caret-vertical"
        />
      ) : (
        <Button text={<FormattedMessage {...messages.emptyLabel} />} rightIcon="double-caret-vertical" />
      )}
    </TypedSelect>
  );
};
