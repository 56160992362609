import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  deleteAccount: {
    id: 'settingsDeleteAccountTitle',
    defaultMessage: 'Delete account',
  },
  deleteInstruction: {
    id: 'settingsDeleteInstruction',
    defaultMessage:
      "Don't leave us, we can improve!{br}Just let us know how at {emailSupport}.{br}{br}If you feel sure about your decision, send an email titled 'I want to delete my account' to {emailDelete} from the address associated with that account.",
  },
});
