import React, { ReactNode } from 'react';

import { TimestampString } from '../../models/TimestampString';

export enum FilterKind {
  TEXT = 'text',
  CURRENCY = 'currency',
  MEMBER_MULTIPLE = 'memberMultiple',
  MEMBER_SINGLE = 'memberSingle',
  DATE_RANGE = 'dateRange',
  NUMBER_RANGE = 'numberRange',
}

export type FilterValueRange<T> = { from?: T; to?: T };
export type FilterValueNumberRange = FilterValueRange<number>;
export type FilterValueTimestampRange = FilterValueRange<TimestampString>;
export type FilterValueSingle = string | number | FilterValueTimestampRange | FilterValueNumberRange;
export type FilterValueMultiple = FilterValueSingle[];
export type FilterValue = FilterValueSingle | FilterValueMultiple;

export type Filter = {
  label: string;
} & FilterValue;

export enum FilterConjunctive {
  AND = 'and',
  OR = 'or',
}

export type FilterConfig = {
  kind: FilterKind;
  id: string;
  value?: FilterValue;
  label?: ReactNode;
  options?: { label: React.ReactNode; value: FilterValue }[];
  optionsConjunctive?: FilterConjunctive;
};

export type FilterChange = Pick<FilterConfig, 'id' | 'value' | 'optionsConjunctive'>;
