import { Intent, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { commonMessages } from '../../../utils/commonMessages';

import { DialogButton } from '../../DialogButton';
import { FilterFields, FilterFieldsProps } from '../FilterFields';
import { getActiveFilters } from '../helpers';
import { FilterConfig } from '../_types';

import { messages } from '../messages';

export type FiltersDialogButtonProps = {
  onChange: FilterFieldsProps['onChange'];
  filters: FilterConfig[];
  resultsCount?: number;
};

export const FiltersDialogButton: React.FC<FiltersDialogButtonProps> = ({ onChange, filters, resultsCount }) => {
  const onClear = () => {
    onChange(filters.map(filter => ({ ...filter, value: undefined })));
  };
  const activeFiltersNumber = getActiveFilters(filters).length;
  const activeFiltersTag =
    activeFiltersNumber > 0 ? (
      <Tag className="ml-tiny" round intent={Intent.PRIMARY}>
        <strong>{activeFiltersNumber}</strong>
      </Tag>
    ) : null;
  return (
    <DialogButton
      dialogProps={{
        title: (
          <>
            <FormattedMessage {...commonMessages.filters} />
            {activeFiltersTag}
          </>
        ),
      }}
      toggleButtonProps={{
        icon: activeFiltersNumber > 0 ? IconNames.FILTER_KEEP : IconNames.FILTER,
        outlined: true,
        text: (
          <>
            <FormattedMessage {...commonMessages.filters} />
            {activeFiltersTag}
          </>
        ),
      }}
      actionButtons={[
        {
          onClick: onClear,
          intent: Intent.DANGER,
          outlined: true,
          text: <FormattedMessage {...commonMessages.actionClear} />,
          preventClose: true,
        },
        {
          text:
            resultsCount === undefined ? (
              <FormattedMessage {...commonMessages.actionClose} />
            ) : (
              <FormattedMessage {...messages.showResultsNumber} values={{ number: resultsCount }} />
            ),
        },
      ]}
    >
      <FilterFields onChange={onChange} filters={filters} />
    </DialogButton>
  );
};
