import { getRoutePrivacy, getRouteTerms } from './navRoutes';

export const serviceName = 'Settler';
export const siteUrl = 'https://www.settler-app.com';
export const complaintEmail = 'complaint@settler-app.com';
export const privacyEmail = 'privacy@settler-app.com';
export const mainTosUrl = `/pl/${getRouteTerms()}`;
export const mainPrivacyUrl = `/pl/${getRoutePrivacy()}`;
export const companyData = {
  name: 'Fitplanner Sp. z o.o.',
  addressStreet: 'ul. Mazowiecka 11/49',
  addressZipCode: '00-052',
  addressCity: 'Warszawa',
  addressCountry: 'Polska',
  krs: '0000582596',
  nip: '5252639106',
  regon: '362817396',
};
