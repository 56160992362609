import React from 'react';

import { FilterField, FilterFieldProps } from '../FilterField';
import { FilterConfig } from '../_types';

export type FilterFieldsProps = {
  onChange: (filters: FilterConfig[]) => unknown;
  filters: FilterConfig[];
};

export const FilterFields: React.FC<FilterFieldsProps> = ({ filters, onChange }) => {
  const onFilterChange: FilterFieldProps['onChange'] = newFilter => {
    const index = filters.findIndex(({ id }) => id === newFilter.id);
    if (index >= 0) {
      const newFilters = [...filters];
      newFilters[index].value = newFilter.value;

      onChange(newFilters);
    }
  };

  return (
    <>
      {filters.map(filterConfig => (
        <FilterField
          key={`${filterConfig.id}-${JSON.stringify(filterConfig.value)}`}
          {...filterConfig}
          onChange={onFilterChange}
        />
      ))}
    </>
  );
};
