import { IItemRendererProps, ItemRenderer, MultiSelect } from '@blueprintjs/select';
import { Button, Intent, MenuItem } from '@blueprintjs/core';
import { MultiSelectProps } from '@blueprintjs/select';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { usePrevious } from '../../../../hooks/usePrevious';

import { GroupMember, GroupMemberId } from '../../../../models/GroupMember';

import { commonMessages } from '../../../../utils/commonMessages';

import { messages } from '../messages';
import { areMemberOptionsEqual, MemberOption, filterMember } from '../helpers';

import { GroupContext } from '../../../../contexts/GroupContext';

import style from './style.module.scss';

const TypedMultiSelect = MultiSelect.ofType<MemberOption>();

type Props = Partial<MultiSelectProps<MemberOption>> & {
  members?: GroupMember[];
  onChange: (selectedMemberIds: GroupMemberId[]) => unknown;
  initialMemberIds?: GroupMemberId[];
  intent?: Intent;
  className?: string;
  id?: string;
  forceClear?: boolean;
  disabled?: boolean;
};

export const MemberSelectMultiple: React.FC<Props> = ({
  initialMemberIds = [],
  onChange,
  className = '',
  forceClear,
  members: customMembers,
  ...other
}) => {
  const { formatMessage } = useIntl();
  const { group } = useContext(GroupContext);
  const members = customMembers || group.members;

  const memberOptions: MemberOption[] = Object.values(members).map(
    ({ id, displayName }) => ({ id, displayName } as MemberOption)
  );

  const initialMemberOptions = members.filter(({ id }) => initialMemberIds.includes(id));
  const [selectedMemberOptions, setSelectedMemberOptions] = useState(initialMemberOptions);

  const previousSelectedMemberOptions = usePrevious(selectedMemberOptions);

  useEffect(() => {
    const selectedIds = selectedMemberOptions.map(({ id }) => id);
    const previousSelectedIds = previousSelectedMemberOptions
      ? previousSelectedMemberOptions.map(({ id }) => id)
      : null;

    if (previousSelectedIds) {
      onChange(selectedIds);
    }
  }, [selectedMemberOptions]);

  useEffect(() => {
    if (forceClear) setSelectedMemberOptions([]);
  }, [forceClear]);

  const isSelected = (memberOption: MemberOption) => selectedMemberOptions.some(({ id }) => id === memberOption.id);

  const select = (memberOption: MemberOption) => {
    setSelectedMemberOptions([...selectedMemberOptions, memberOption]);
  };

  const deselect = (memberOption: MemberOption) => {
    setSelectedMemberOptions(selectedMemberOptions.filter(({ id }) => memberOption.id !== id));
  };

  const onItemClick = (memberOption: MemberOption) => {
    if (isSelected(memberOption)) {
      deselect(memberOption);
    } else {
      select(memberOption);
    }
  };

  const onTagRemove = (_tag: React.ReactNode, index: number) => {
    deselect(selectedMemberOptions[index]);
  };

  const onClearClick = () => {
    setSelectedMemberOptions([]);
  };

  const clearButton =
    selectedMemberOptions.length > 0 ? (
      <Button icon="cross" minimal={true} onClick={onClearClick} data-testid="clear-button" />
    ) : undefined;

  const itemRenderer: ItemRenderer<MemberOption> = (memberOption, { handleClick, modifiers }: IItemRendererProps) => (
    <MenuItem
      key={memberOption.id}
      active={modifiers.active}
      disabled={modifiers.disabled}
      text={memberOption.displayName}
      icon={isSelected(memberOption) ? 'tick' : 'person'}
      onClick={handleClick}
    />
  );

  return (
    <TypedMultiSelect
      className={classNames([className, style.selectWrapper])}
      resetOnSelect={true}
      items={memberOptions}
      itemRenderer={itemRenderer}
      onItemSelect={onItemClick}
      itemsEqual={areMemberOptionsEqual}
      itemPredicate={filterMember}
      selectedItems={selectedMemberOptions}
      noResults={<MenuItem disabled={true} text={<FormattedMessage {...commonMessages.noResults} />} />}
      tagRenderer={({ displayName }) => displayName}
      tagInputProps={{
        onRemove: onTagRemove,
        rightElement: clearButton,
        tagProps: { intent: Intent.PRIMARY },
      }}
      placeholder={formatMessage(messages.emptyLabelMultiple)}
      {...other}
    />
  );
};
