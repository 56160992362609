import classNames from 'classnames';
import React from 'react';

import style from './style.module.scss';

type StickyFooterProps = { className?: string };

export const StickyFooter: React.FC<StickyFooterProps> = ({ className, children }) => {
  return (
    <footer className={classNames(className, style.wrapper)}>
      <div className={style.content}>{children}</div>
    </footer>
  );
};
