import React, { useContext } from 'react';

import { Redirect } from 'react-router';

import { User } from '../../../models/User';

import { AuthContext } from '../../../contexts/AuthContext';
import { LocaleContext } from '../../../contexts/LocaleContext';

import { getRouteGroupList } from '../../../utils/navRoutes';

import { Landing } from '../Landing';

export const Home: React.FC = () => {
  const currentUser: User | null = useContext(AuthContext).user;

  const { locale } = React.useContext(LocaleContext);
  const routePrefix = `/${locale}`;

  if (currentUser) {
    return <Redirect to={`${routePrefix}/${getRouteGroupList()}`} />;
  }
  return <Landing />;
};
