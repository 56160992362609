import React, { useCallback, useState } from 'react';
import { Alignment, Navbar } from '@blueprintjs/core';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useLocation } from 'react-router';

import { commonMessages } from '../../../../../utils/commonMessages';
import { getRouteHome, getRouteSignIn, getRouteSignUp } from '../../../../../utils/navRoutes';

import { LocaleLink } from '../../../../LocaleLink';
import { LocaleSwitch } from '../../../../LocaleSwitch';
import { Container } from '../../../../Container';
import style from '../../../../Navigation/style.module.scss';

import { ReactComponent as Logo } from '../../../../../assets/logo.svg';

type Props = {
  isTransparent?: boolean;
  isFixed?: boolean;
};

export const NavigationSimple: React.FC<Props> = ({ isTransparent, isFixed }) => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const [isActive, setIsActive] = useState(false);

  const closeMenu = useCallback(() => {
    setIsActive(false);
  }, [setIsActive]);

  const toggleMenu = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  return (
    <Navbar
      className={classNames([
        style.wrapper,
        {
          [style['wrapper--active']]: isActive,
          [style['wrapper--transparent']]: isTransparent,
          [style['wrapper--fixed']]: isFixed,
        },
      ])}
    >
      <Container className={style.content}>
        <Navbar.Group align={Alignment.LEFT} className={style.group}>
          <Navbar.Heading>
            <LocaleLink to={getRouteHome()}>
              <Logo title={formatMessage(commonMessages.title)} height="1.2em" />
            </LocaleLink>
          </Navbar.Heading>
        </Navbar.Group>

        <button
          onClick={toggleMenu}
          className={classNames([
            'bp3-button',
            'bp3-minimal',
            'bp3-intent-primary',
            style.hamburgerButton,
            style['hamburgerButton--noText'],
          ])}
        >
          <span className={classNames([style.hamburgerBar, style['hamburgerBar--one']])}></span>
          <span className={classNames([style.hamburgerBar, style['hamburgerBar--two']])}></span>
          <span className={classNames([style.hamburgerBar, style['hamburgerBar--three']])}></span>
        </button>

        <Navbar.Group align={Alignment.RIGHT} className={classNames(style.group, style.primary)}>
          <LocaleLink
            onClick={closeMenu}
            isNav
            activeClassName={style.navLinkCurrent}
            to={{
              pathname: getRouteSignUp(),
              state: {
                from: pathname,
              },
            }}
            className={classNames([
              'bp3-button',
              'bp3-intent-primary',
              style.navLink,
              { 'bp3-minimal': isTransparent },
            ])}
          >
            <FormattedMessage {...commonMessages.authSignUp} />
          </LocaleLink>
        </Navbar.Group>

        <div className={style.secondary}>
          <Navbar.Group align={Alignment.RIGHT} className={style.group}>
            <LocaleSwitch className={style.localeSwitch} />

            <Navbar.Divider className={style.divider} />

            <LocaleLink
              onClick={closeMenu}
              isNav
              activeClassName={style.navLinkCurrent}
              to={{
                pathname: getRouteSignIn(),
                state: {
                  from: pathname,
                },
              }}
              className={classNames(['bp3-button', 'bp3-minimal', 'bp3-intent-primary', style.navLink])}
            >
              <FormattedMessage {...commonMessages.authSignIn} />
            </LocaleLink>
          </Navbar.Group>
        </div>

        <div className={style.overlay} onClick={closeMenu} />
      </Container>
    </Navbar>
  );
};
