import { useMutation } from '@apollo/client';
import { merge, omit } from 'lodash';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useLocation } from 'react-router';
import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import startOfToday from 'date-fns/startOfToday';

import { SplitKind } from '../../../../models/SplitKind';
import { ExpenseData, ExpenseInputData } from '../../../../models/Expense';
import { GroupMember } from '../../../../models/GroupMember';
import { DEFAULT_CURRENCY_CODE } from '../../../../models/Currency';

import { CREATE_EXPENSE, GET_GROUP } from '../../../../api/queries';

import { LocaleContext } from '../../../../contexts/LocaleContext';
import { GroupContext } from '../../../../contexts/GroupContext';

import { commonMessages } from '../../../../utils/commonMessages';
import { scaleExpenseToCents } from '../../../../utils/scaleAmount';
import { getRouteExpenseList } from '../../../../utils/navRoutes';
import { MainToaster } from '../../../../utils/toaster';

import { Page } from '../../../Page';
import { Container, Size as ContainerSize } from '../../../Container';
import { Title } from '../../../Title';
import { ExpenseForm } from '../../../ExpenseForm';
import { BackLink } from '../../../BackLink';

export const ExpenseNew: React.FC = () => {
  const { state } = useLocation<{ from: string }>();
  const { group, currentMember } = useContext(GroupContext);
  const { state: locationState } = useLocation<{ data: ExpenseData }>();

  const { locale } = React.useContext(LocaleContext);
  const { formatMessage } = useIntl();

  const routePrefix = `/${locale}`;
  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createExpense] = useMutation(CREATE_EXPENSE, { refetchQueries: [GET_GROUP] });

  const showSuccessToast = () => {
    MainToaster.show({
      message: `${formatMessage(commonMessages.success)}`,
      icon: IconNames.TICK,
      intent: Intent.SUCCESS,
    });
  };

  const showErrorToast = (error: Error) => {
    MainToaster.show({
      message: `${formatMessage(commonMessages.genericError)}: ${error.message}`,
      icon: IconNames.DISABLE,
      intent: Intent.DANGER,
    });
  };

  const handleSubmit = async (expenseInputData: ExpenseInputData) => {
    setIsSubmitting(true);
    try {
      await createExpense({
        variables: {
          groupId: group.id,
          input: scaleExpenseToCents(expenseInputData),
        },
      });

      setShouldRedirectToList(true);
      showSuccessToast();
    } catch (e) {
      showErrorToast(e as Error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (shouldRedirectToList) {
    return <Redirect to={state.from || `${routePrefix}/${getRouteExpenseList({ groupId: group.id })}`} />;
  }

  return (
    <Page size={ContainerSize.SM}>
      <Container size={ContainerSize.SM} top bottom>
        <Title
          center
          condensed
          backLink={
            <BackLink to={getRouteExpenseList({ groupId: group.id })} messageDescriptor={commonMessages.actionCancel} />
          }
        >
          <FormattedMessage {...commonMessages.expenseAdd} />
        </Title>
      </Container>

      <ExpenseForm
        onSubmit={handleSubmit}
        initialData={merge(
          {
            subject: '',
            amount: 0,
            splitAmounts: group.members.map((member: GroupMember) => ({ amount: 0, memberId: member.id })),
            currencyCode: group.expenses[0]?.currencyCode || group.selectedSingleCurrency || DEFAULT_CURRENCY_CODE,
            groupId: group.id,
            payerId: currentMember.id,
            splitKind: SplitKind.EVEN,
            paidAt: startOfToday().getTime().toString(),
          },
          omit(locationState?.data || {}, 'id')
        )}
        isSubmitting={isSubmitting}
      />
    </Page>
  );
};
