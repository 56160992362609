import React from 'react';
import { useRouteMatch } from 'react-router';
import { createIntl, RawIntlProvider } from 'react-intl';

//IMPORTANT NOTE: When adding new locale, update all places marked like this
import { pl } from 'date-fns/locale';

import { storage } from '../utils/storage';
import { OtherLocale, defaultLocale, AvailableLocale, isLocale, messages as allMessages } from '../lang';

interface ContextProps {
  locale: AvailableLocale;
  timeZone?: string;
  readonly setLocale: (locale: AvailableLocale) => void;
  dateFnsLocaleOption: { locale?: typeof pl };
}

export const LocaleContext = React.createContext<ContextProps>({
  locale: defaultLocale,
  setLocale: () => null,
  timeZone: undefined,
  dateFnsLocaleOption: {},
});

export type LocaleProviderProps = {
  lang: AvailableLocale;
  timeZone?: string;
};

export const LocaleProvider: React.FC<LocaleProviderProps> = ({ lang, timeZone, children }) => {
  const [locale, setLocale] = React.useState<AvailableLocale>(lang);
  const match = useRouteMatch<{ lang: string }>('/:lang');

  React.useEffect(() => {
    if (locale !== storage.get('locale')) {
      storage.set('locale', locale);
    }
  }, [locale]);

  React.useEffect(() => {
    if (typeof match?.params?.lang === 'string' && isLocale(match.params.lang) && locale !== match.params.lang) {
      const lang = match.params.lang as AvailableLocale;
      setLocale(lang);
    }
  }, [match?.params?.lang, locale]);

  const intl = createIntl({
    locale,
    messages: allMessages[locale as AvailableLocale],
    ...(timeZone ? { timeZone } : {}),
  });

  //IMPORTANT NOTE: When adding new locale, update all places marked like this
  const dateFnsLocales = { pl };
  const dateFnsLocaleOption = locale === defaultLocale ? {} : { locale: dateFnsLocales[locale as OtherLocale] };

  return (
    <LocaleContext.Provider value={{ locale, setLocale, dateFnsLocaleOption }}>
      <RawIntlProvider value={intl}>{children}</RawIntlProvider>
    </LocaleContext.Provider>
  );
};
