import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  paidBy: {
    id: 'groupShowFilterExpensePaidBy',
    defaultMessage: 'Paid by',
  },
  paidFor: {
    id: 'groupShowFilterExpensePaidFor',
    defaultMessage: 'Paid for',
  },
});
