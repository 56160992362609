import { useMutation, useQuery } from '@apollo/client';
import { IconNames } from '@blueprintjs/icons';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Intent, NonIdealState } from '@blueprintjs/core';

import { Group } from '../../../../models/Group';

import {
  GET_GROUP_JOIN_REQUESTS,
  GET_USER_GROUPS,
  GROUP_INVITE_ACCEPT,
  GROUP_INVITE_REJECT,
} from '../../../../api/queries';

import { commonMessages } from '../../../../utils/commonMessages';
import { getRouteGroupNew, getRouteGroupShow } from '../../../../utils/navRoutes';
import { ICON_BG_COLOR, ICON_COLOR } from '../../../../utils/visual';
import { MainToaster } from '../../../../utils/toaster';

import { Page } from '../../../Page';
import { IndicatorLoadingPage } from '../../../IndicatorLoadingPage';
import { Container, Size as ContainerSize } from '../../../Container';
import { Title } from '../../../Title';
import { LocaleLink } from '../../../LocaleLink';
import { EnhancedList } from '../../../EnhancedList';
import { ErrorUnknown } from '../../_error/Unknown';

import { messages } from './messages';

export const GroupList: React.FC = () => {
  const { formatMessage } = useIntl();

  const { loading: isLoading, data } = useQuery(GET_USER_GROUPS);
  const { loading: isJoinRequestsLoading, data: joinRequestsData } = useQuery(GET_GROUP_JOIN_REQUESTS);

  const [
    inviteAccept,
    { data: inviteAcceptData, loading: isLoadingInviteAccept, error: inviteAcceptError },
  ] = useMutation(GROUP_INVITE_ACCEPT, {
    refetchQueries: [GET_USER_GROUPS, GET_GROUP_JOIN_REQUESTS],
  });
  const [
    inviteReject,
    { data: inviteRejectData, loading: isLoadingInviteReject, error: inviteRejectError },
  ] = useMutation(GROUP_INVITE_REJECT, {
    refetchQueries: [GET_USER_GROUPS, GET_GROUP_JOIN_REQUESTS],
  });

  const showSuccessToast = () => {
    MainToaster.show({
      message: `${formatMessage(commonMessages.success)}`,
      icon: IconNames.TICK,
      intent: Intent.SUCCESS,
    });
  };

  const showErrorToast = (error: Error) => {
    MainToaster.show({
      message: `${formatMessage(commonMessages.genericError)}: ${error.message}`,
      icon: IconNames.DISABLE,
      intent: Intent.DANGER,
    });
  };

  useEffect(() => {
    if (inviteAcceptData?.inviteAccept || inviteRejectData?.inviteReject) {
      showSuccessToast();
    }
  }, [inviteAcceptData, inviteRejectData]);
  useEffect(() => {
    if (inviteAcceptError) {
      showErrorToast(inviteAcceptError);
    } else if (inviteRejectError) {
      showErrorToast(inviteRejectError);
    }
  }, [inviteAcceptError, inviteRejectError]);

  const groups: Group[] = data?.getUserGroups;
  const joinRequests: Group[] = joinRequestsData?.getGroupJoinRequests;

  if (isLoading || isJoinRequestsLoading) {
    return <IndicatorLoadingPage size={ContainerSize.SM} />;
  }

  if (!groups || !joinRequests) {
    return <ErrorUnknown />;
  }

  return (
    <Page size={ContainerSize.SM}>
      {joinRequests.length > 0 && (
        <Container size={ContainerSize.SM} top bottom>
          <Title condensed center>
            <FormattedMessage {...commonMessages.groupInvites} />
          </Title>

          <EnhancedList
            defaultIconName={IconNames.PEOPLE}
            defaultIconBgColor={ICON_BG_COLOR.default}
            defaultIconColor={ICON_COLOR.defaultOverBg}
            items={joinRequests.map(({ id, displayName, description }) => ({
              id,
              header: <span className="text-size-increased">{displayName}</span>,
              detail: description ? <small>{description}</small> : undefined,
              actions: (
                <>
                  <Button
                    intent={Intent.SUCCESS}
                    icon={IconNames.TICK}
                    outlined
                    small
                    text={<FormattedMessage {...commonMessages.actionAccept} />}
                    disabled={isLoadingInviteAccept || isLoadingInviteReject}
                    onClick={() => {
                      inviteAccept({ variables: { groupId: id } });
                    }}
                  />
                  <Button
                    className="ml-small"
                    intent={Intent.DANGER}
                    icon={IconNames.CROSS}
                    outlined
                    small
                    text={<FormattedMessage {...commonMessages.actionReject} />}
                    disabled={isLoadingInviteAccept || isLoadingInviteReject}
                    onClick={() => {
                      inviteReject({ variables: { groupId: id } });
                    }}
                  />
                </>
              ),
            }))}
          />
        </Container>
      )}

      <Container size={ContainerSize.SM} top bottom>
        <Title
          condensed
          center
          wrapped
          secondaryContent={
            <LocaleLink to={getRouteGroupNew()} className="bp3-button bp3-intent-primary bp3-outlined">
              <FormattedMessage {...commonMessages.groupAdd} />
            </LocaleLink>
          }
        >
          <FormattedMessage {...commonMessages.groupsYour} />
        </Title>

        <EnhancedList
          defaultIconName={IconNames.PEOPLE}
          defaultIconBgColor={ICON_BG_COLOR.default}
          defaultIconColor={ICON_COLOR.defaultOverBg}
          items={groups.map(({ id, displayName }) => ({
            id,
            header: (
              <LocaleLink className="text-size-increased" to={getRouteGroupShow({ groupId: id })}>
                {displayName}
              </LocaleLink>
            ),
          }))}
        />

        {!groups.length && (
          <NonIdealState
            icon={IconNames.PEOPLE}
            title={<FormattedMessage {...messages.noItems} />}
            description={<FormattedMessage {...messages.noItemsDescription} />}
          />
        )}
      </Container>
    </Page>
  );
};
