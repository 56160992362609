import React from 'react';
import classNames from 'classnames';

import style from './style.module.scss';

export enum Size {
  DEFAULT = 'default',
  SM = 'sm',
  XS = 'xs',
}

type Props = {
  className?: string;
  top?: boolean;
  bottom?: boolean;
  left?: boolean;
  right?: boolean;
  centered?: boolean;
  size?: Size;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tagName?: React.ElementType<any>;
};

export const Container: React.FC<Props> = ({
  children,
  className,
  left = true,
  right = true,
  top,
  bottom,
  centered,
  size = Size.DEFAULT,
  tagName = 'section',
}) => {
  const wrapperClasses = [
    className,
    [style.container],
    { [style['container--top']]: top },
    { [style['container--right']]: right },
    { [style['container--bottom']]: bottom },
    { [style['container--left']]: left },
    { [style['container--centered']]: centered },
    { [style[`container--size-${size}`]]: size !== Size.DEFAULT },
  ];
  const Component = tagName;
  return <Component className={classNames([...wrapperClasses])}>{children}</Component>;
};

type AntiContainerProps = {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tagName?: React.ElementType<any>;
};

export const AntiContainer: React.FC<AntiContainerProps> = ({ children, className, tagName = 'div' }) => {
  const wrapperClasses = [className, style.antiContainer];
  const Component = tagName;
  return <Component className={classNames([...wrapperClasses])}>{children}</Component>;
};
