import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';

import { LocaleContext } from '../../contexts/LocaleContext';

import { defaultLocale, languageNames } from '../../lang';

import { Container } from '../Container';
import { Page } from '../Page';

type Props = {
  content: typeof languageNames;
};

export const TextPage: React.FC<Props> = ({ content }) => {
  const { locale } = useContext(LocaleContext);

  const markdown = content[locale] || content[defaultLocale];

  return (
    <Page>
      <Container top bottom>
        <ReactMarkdown children={markdown} linkTarget="_blank" />
      </Container>
    </Page>
  );
};
