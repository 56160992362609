import { Button, ControlGroup, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import { kebabCase } from 'lodash';
import React from 'react';

import { GroupMember, GroupMemberId } from '../../../models/GroupMember';

import { AmountInput } from '../AmountInput';

import style from './style.module.scss';

type Props = {
  member: GroupMember;
  initialAmount: number;
  onChange: (memberId: GroupMemberId, amount: number) => void;
  onDelete: (memberId: GroupMemberId) => void;
  currencyPrecision?: number;
  unit: string;
  instanceIdPrefix?: string;
  className?: string;
  disabled?: boolean;
};

export const MemberAmountInputRow: React.FC<Props> = ({
  member,
  initialAmount,
  onChange,
  onDelete,
  unit,
  currencyPrecision = 2,
  instanceIdPrefix = 'member-amount-input-',
  className = '',
  disabled = false,
}) => {
  const handleDelete = () => onDelete(member.id);

  const handleChange = (value: number) => {
    onChange(member.id, value);
  };

  return (
    <ControlGroup fill className={classNames(style.row, className)}>
      <AmountInput
        disabled={disabled}
        className={style.amountInput}
        defaultValue={initialAmount}
        onChange={handleChange}
        currencyPrecision={currencyPrecision}
        instanceId={`${instanceIdPrefix}-${kebabCase(member.displayName)}`}
      />

      <span>{unit}</span>

      <span className={style.rowLabel} title={member.displayName}>
        {member.displayName}
      </span>

      <Button
        disabled={disabled}
        minimal
        intent={Intent.DANGER}
        icon="cross"
        onClick={handleDelete}
        data-testid="AmountInput_deleteButton"
        title="remove"
      />
    </ControlGroup>
  );
};
