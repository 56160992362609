import React, { useState } from 'react';
import { IItemRendererProps, ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';
import { Button, MenuItem } from '@blueprintjs/core';
import { FormattedMessage } from 'react-intl';

import { Currency, CURRENCIES_MAP, CurrencyCode } from '../../../models/Currency';
import { commonMessages } from '../../../utils/commonMessages';

import style from './style.module.scss';
import { messages } from './messages';

type CurrencyOption = Currency;

const currencyOptions: CurrencyOption[] = Object.values(CURRENCIES_MAP).map(
  ({ code, symbol }) => ({ code, symbol } as CurrencyOption)
);

const areCurrencyOptionsEqual = (currency1: CurrencyOption, currency2: CurrencyOption) =>
  currency1.code === currency2.code;

const Icon: React.FC<Pick<Currency, 'symbol'>> = ({ symbol }) => <span className={style.icon}>{symbol}</span>;

const itemRenderer: ItemRenderer<CurrencyOption> = (
  { symbol, code },
  { handleClick, modifiers }: IItemRendererProps
) => (
  <MenuItem
    key={symbol}
    icon={<Icon symbol={symbol} />}
    active={modifiers.active}
    disabled={modifiers.disabled}
    text={code}
    onClick={handleClick}
  />
);

export const filterCurrency: ItemPredicate<CurrencyOption> = (query, item) => {
  const { code, symbol } = item;
  const normalizedCode = code.toUpperCase();
  const normalizedSymbol = symbol.toUpperCase();
  const normalizedQuery = query.toUpperCase();

  return normalizedCode.indexOf(normalizedQuery) >= 0 || normalizedSymbol.indexOf(normalizedQuery) >= 0;
};

const TypedSelect = Select.ofType<CurrencyOption>();

type Props = {
  onChange: (selectedCurrencyCode: CurrencyCode) => unknown;
  initialCurrencyCode?: CurrencyCode;
  disabled?: boolean;
};

export const CurrencySelect: React.FC<Props> = ({ initialCurrencyCode, onChange, disabled = false }) => {
  const initialCurrencyOption = initialCurrencyCode
    ? currencyOptions.find(({ code }) => code === initialCurrencyCode)
    : null;
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState(initialCurrencyOption);

  const onItemSelect = (currencyOption: CurrencyOption) => {
    setSelectedCurrencyOption(currencyOption);
    onChange(currencyOption.code);
  };

  return (
    <TypedSelect
      disabled={disabled}
      items={currencyOptions}
      itemRenderer={itemRenderer}
      onItemSelect={onItemSelect}
      itemsEqual={areCurrencyOptionsEqual}
      itemPredicate={filterCurrency}
      noResults={<MenuItem disabled={true} text={<FormattedMessage {...commonMessages.noResults} />} />}
    >
      {selectedCurrencyOption ? (
        <Button
          icon={<Icon symbol={selectedCurrencyOption.symbol} />}
          text={selectedCurrencyOption.code}
          rightIcon="double-caret-vertical"
        />
      ) : (
        <Button text={<FormattedMessage {...messages.emptyLabel} />} rightIcon="double-caret-vertical" />
      )}
    </TypedSelect>
  );
};
