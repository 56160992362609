import classNames from 'classnames';
import React from 'react';

import { Size as ContainerSize } from '../Container';

import style from './style.module.scss';

type Props = {
  size?: ContainerSize;
  className?: string;
};

export const Page: React.FC<Props> = ({ children, size = ContainerSize.DEFAULT, className = '' }) => (
  <div
    className={classNames(className, style.wrapper, {
      [style[`wrapper--size-${size}`]]: size !== ContainerSize.DEFAULT,
    })}
  >
    {children}
  </div>
);
