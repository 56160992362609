import { storage } from '../utils/storage';

import { AvailableLocale, isLocale, defaultLocale } from '.';

export function getInitialLocale(): AvailableLocale {
  // preference from the previous session
  const localSetting = storage.get('locale');

  if (localSetting && isLocale(localSetting as string)) {
    return localSetting as AvailableLocale;
  }

  const [browserSetting] = navigator.language.split('-');

  if (isLocale(browserSetting)) {
    return browserSetting as AvailableLocale;
  }

  return defaultLocale;
}
