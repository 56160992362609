enum MessageLevel {
  WARN = 'warn',
  ERROR = 'error',
}

const SUPRESSED_MESSAGES = [
  {
    level: MessageLevel.WARN,
    message: 'Warning: componentWillReceiveProps has been renamed, and is not recommended for use.',
    args: ['TransitionMotion'],
    reason: 'waiting for a fix in "reat-router-transition"',
  },
  {
    level: MessageLevel.ERROR,
    message:
      'Warning: %s is deprecated in StrictMode. %s was passed an instance of %s which is inside StrictMode. Instead, add a ref directly to the element you want to reference.',
    args: ['findDOMNode', 'findDOMNode', 'TransitionGroup'],
    reason: 'waiting for a fix in "@blueprintjs/core"',
  },
  {
    level: MessageLevel.ERROR,
    message:
      'Warning: %s is deprecated in StrictMode. %s was passed an instance of %s which is inside StrictMode. Instead, add a ref directly to the element you want to reference.',
    args: ['findDOMNode', 'findDOMNode', 'Transition'],
    reason: 'waiting for a fix in "@blueprintjs/core"',
  },
  {
    level: MessageLevel.ERROR,
    message:
      'Warning: %s is deprecated in StrictMode. %s was passed an instance of %s which is inside StrictMode. Instead, add a ref directly to the element you want to reference.',
    args: ['findDOMNode', 'findDOMNode', 'Blueprint3.ResizeSensor'],
    reason: 'waiting for a fix in "@blueprintjs/core"',
  },
  {
    level: MessageLevel.ERROR,
    message: 'Warning: Legacy context API has been detected within a strict-mode tree',
    args: ['', '', 'Blueprint3.ResizeSensor'],
    reason: 'waiting for a fix in "@blueprintjs/core"',
  },
  {
    level: MessageLevel.ERROR,
    message: 'Warning: Legacy context API has been detected within a strict-mode tree',
    args: ['Blueprint3.Portal'],
    reason: 'waiting for a fix in "@blueprintjs/core"',
  },
  {
    level: MessageLevel.ERROR,
    message: 'Warning: Legacy context API has been detected within a strict-mode tree',
    args: ['Transition'],
    reason: 'waiting for a fix in "@blueprintjs/core"',
  },
  {
    level: MessageLevel.ERROR,
    message: 'Warning: Legacy context API has been detected within a strict-mode tree',
    args: ['Transition, TransitionGroup'],
    reason: 'waiting for a fix in "@blueprintjs/core"',
  },
  {
    level: MessageLevel.WARN,
    message: "It looks like you're using the development build of the Firebase JS SDK.",
    args: [],
    reason: 'disable dev env message',
  },
  {
    level: MessageLevel.WARN,
    message:
      '[Blueprint] <NumericInput> controlled value prop does not adhere to stepSize, min, and/or max constraints.',
    args: [],
    reason: 'weird warning from BlueprintJS',
  },
];

const { warn, error } = console;

export const suppressKnownConsoleMessages = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function warnCopy(message: any, ...args: any) {
    const isSupressed = SUPRESSED_MESSAGES.some(supressed => {
      let doArgumentsMatch = supressed.args.length ? false : true;

      supressed.args.forEach((arg, index) => {
        if (arg === args[index]) {
          doArgumentsMatch = true;
        } else {
          doArgumentsMatch = false;
          return;
        }
      });

      return (
        supressed.level === MessageLevel.WARN &&
        message.includes &&
        message.includes(supressed.message) &&
        doArgumentsMatch
      );
    });

    if (!isSupressed) {
      warn(message, ...args);
    }
  }
  console.warn = warnCopy;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function errorCopy(message: any, ...args: any) {
    const isSupressed = SUPRESSED_MESSAGES.some(supressed => {
      let doArgumentsMatch = false;

      supressed.args.forEach((arg, index) => {
        if (arg === args[index]) {
          doArgumentsMatch = true;
        } else {
          doArgumentsMatch = false;
          return;
        }
      });
      if (!message.includes) {
        console.info('message', message);
      }
      return (
        supressed.level === MessageLevel.ERROR &&
        message.includes &&
        message.includes(supressed.message) &&
        doArgumentsMatch
      );
    });

    if (!isSupressed) {
      error(message, ...args);
    }
  }
  console.error = errorCopy;
};
