import { Button, ButtonProps, FormGroup, Icon, InputGroup, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { MultiSelectProps } from '@blueprintjs/select';
import { partition } from 'lodash';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GroupMember, GroupMemberId, GroupMemberStatus } from '../../../../models/GroupMember';

import { commonMessages } from '../../../../utils/commonMessages';
import { ICON_BG_COLOR, ICON_COLOR, ICON_SIZE } from '../../../../utils/visual';
import yup from '../../../../utils/yup';

import { DialogButton } from '../../../DialogButton';
import { EnhancedList } from '../../../EnhancedList';
import { Title } from '../../../Title';

import { MemberOption } from '../helpers';
import { messages } from '../messages';

import { AuthContext } from '../../../../contexts/AuthContext';

import style from './style.module.scss';

export type MemberSelection = {
  selected: GroupMemberId[];
  created: string[];
};

type Props = Partial<MultiSelectProps<MemberOption>> & {
  members: GroupMember[];
  onAdd: (selection: MemberSelection) => unknown;
  onDelete: (id: GroupMemberId) => unknown;
  initialMemberIds?: GroupMemberId[];
  preventRemoveWhen?: (id: GroupMemberId) => boolean;
  isSubmittingAdd?: boolean;
  submittingDeleteIds?: GroupMemberId[];
  disabled?: boolean;
  shouldConfirmDelete?: boolean;
};

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

export const MemberSelectListSimple: React.FC<Props> = ({
  members,
  initialMemberIds = [],
  onDelete,
  onAdd,
  preventRemoveWhen,
  isSubmittingAdd = false,
  submittingDeleteIds = [],
  disabled = false,
  shouldConfirmDelete = true,
}) => {
  const { formatMessage } = useIntl();
  const { user } = useContext(AuthContext);
  const [initialMembers] = partition(members, ({ id }) => initialMemberIds.includes(id));
  const [membersLeft, membersNotLeft] = partition(members, ({ status }) => status === GroupMemberStatus.LEFT);
  const [selection, setSelection] = useState<MemberSelection>({ selected: [], created: [] });
  const [forceClearSelect, setForceClearSelect] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [hasInputLeft, setHasInputLeft] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);

  const onAddClick = () => {
    setInputValue('');
    setIsInputValid(false);
    onAdd(selection);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setSelection({ selected: [], created: [newValue] });
    const hasLeft = membersLeft.some(({ displayName }) => displayName === newValue);
    setHasInputLeft(hasLeft);
    setIsInputValid(!hasLeft && schema.isValidSync({ email: e.target.value }));
  };

  useEffect(() => {
    if (forceClearSelect) setForceClearSelect(false);
  }, [forceClearSelect]);

  return (
    <>
      {initialMembers.length ? (
        <EnhancedList
          defaultIconName={IconNames.PERSON}
          defaultIconBgColor={ICON_BG_COLOR.default}
          defaultIconColor={ICON_COLOR.defaultOverBg}
          iconSize={ICON_SIZE.listSmall}
          items={membersNotLeft.map(({ id, displayName, profile, status }) => {
            const isMe = user!.profile.id === profile?.id || user!.profile.id === id;
            const shouldPreventDelete = preventRemoveWhen && preventRemoveWhen(id);
            const onDeleteClick = () => {
              if (!shouldPreventDelete) {
                onDelete(id);
              }
            };
            const buttonProps: ButtonProps = {
              intent: Intent.DANGER,
              small: true,
              icon: isMe ? IconNames.AIRPLANE : IconNames.TRASH,
              outlined: true,
              disabled: disabled || shouldPreventDelete,
              loading: submittingDeleteIds.includes(id),
              text: isMe ? (
                <FormattedMessage {...commonMessages.actionLeave} />
              ) : (
                <FormattedMessage {...commonMessages.actionDelete} />
              ),
            };

            return {
              id,
              header: displayName,
              detail:
                status === GroupMemberStatus.INVITED ? (
                  <small className="bp3-text-muted">
                    <Icon size={14} icon={IconNames.ARROW_TOP_RIGHT} className={'mr-tiny'} />
                    <FormattedMessage {...messages.memberStatusInvited} />
                  </small>
                ) : status === GroupMemberStatus.REJECTED ? (
                  <small className="bp3-text-muted">
                    <Icon size={14} icon={IconNames.CROSS} className={'mr-tiny'} />
                    <FormattedMessage {...messages.memberStatusRejected} />
                  </small>
                ) : undefined,
              actions: shouldConfirmDelete ? (
                <DialogButton
                  dialogProps={{ title: <FormattedMessage {...commonMessages.confirmationLabel} /> }}
                  toggleButtonProps={buttonProps}
                  actionButtons={[
                    {
                      onClick: onDeleteClick,
                      intent: Intent.DANGER,
                      text: isMe ? (
                        <FormattedMessage {...commonMessages.actionLeave} />
                      ) : (
                        <FormattedMessage {...commonMessages.actionDelete} />
                      ),
                    },
                    {
                      text: <FormattedMessage {...commonMessages.actionCancel} />,
                    },
                  ]}
                >
                  <p>
                    {isMe ? (
                      <FormattedMessage {...messages.deleteYourselfConfirmationMessage} />
                    ) : (
                      <FormattedMessage {...messages.deleteConfirmationMessage} values={{ displayName }} />
                    )}
                  </p>
                </DialogButton>
              ) : (
                <Button onClick={onDeleteClick} {...buttonProps} />
              ),
            };
          })}
        />
      ) : (
        <FormattedMessage {...commonMessages.noResults} />
      )}

      <section className={style.addSection}>
        <Title tagName="h3" condensed>
          <FormattedMessage {...commonMessages.actionAdd} />
        </Title>

        <FormGroup
          label={formatMessage(messages.emptyLabelInput)}
          helperText={hasInputLeft ? <FormattedMessage {...messages.leftErrorInput} /> : ''}
        >
          <div className={style.addContainer}>
            <InputGroup
              fill
              value={inputValue}
              onChange={onInputChange}
              intent={isInputValid ? Intent.SUCCESS : Intent.NONE}
            />

            <Button
              intent={Intent.SUCCESS}
              onClick={onAddClick}
              icon={IconNames.PLUS}
              disabled={
                disabled ||
                (!selection.selected.length && !selection.created.length) ||
                !selection.created[0].length ||
                !isInputValid
              }
              loading={isSubmittingAdd}
            >
              <FormattedMessage {...commonMessages.actionAdd} />
            </Button>
          </div>
        </FormGroup>
      </section>
    </>
  );
};
