import { useQuery } from '@apollo/client';

import React, { useContext } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { AnimatedSwitch } from 'react-router-transition';

import { GroupId } from '../../../models/Group';

import { GET_GROUP } from '../../../api/queries';

import { ExchangeRatesContext } from '../../../contexts/ExchangeRatesContext';
import { GroupProvider } from '../../../contexts/GroupContext';
import { LocaleContext } from '../../../contexts/LocaleContext';

import {
  getRouteExpenseNew,
  getRouteGroupEdit,
  getRouteGroupShow,
  getRouteExpenseShow,
} from '../../../utils/navRoutes';
import { routeTransitionProps } from '../../../utils/visual';
import { scaleGroupFromCents } from '../../../utils/scaleAmount';

import { IndicatorLoadingPage, Size } from '../../IndicatorLoadingPage';

import { ExpenseSpecific, RouteProps as ExpenseSpecificRouteProps } from '../ExpenseSpecific';
import { ExpenseNew } from '../Expenses/New';
import { Error404 } from '../_error/404';

import { GroupEdit } from './Edit';
import { GroupShow } from './Show';

type RouteProps = {
  groupId: GroupId;
};

export const GroupSpecific: React.FC<RouteComponentProps<RouteProps>> = ({
  match: {
    params: { groupId },
  },
}) => {
  const { locale } = useContext(LocaleContext);
  const { isInitialized } = useContext(ExchangeRatesContext);
  const routePrefix = `/${locale}`;

  const { loading: isLoading, data } = useQuery(GET_GROUP, { variables: { id: groupId } });

  if (isLoading || !isInitialized) {
    return <IndicatorLoadingPage size={Size.SM} />;
  }

  const group = data?.getGroup ? scaleGroupFromCents(data.getGroup) : undefined;

  if (!group) {
    return <Error404 />;
  }

  return (
    <GroupProvider group={group}>
      <AnimatedSwitch {...routeTransitionProps}>
        <Route
          exact
          path={`${routePrefix}/${getRouteGroupEdit({ groupId: ':groupId' })}`}
          render={() => <GroupEdit />}
        />

        <Route
          exact
          path={`${routePrefix}/${getRouteGroupShow({ groupId: ':groupId' })}`}
          render={() => <GroupShow />}
        />

        <Route
          exact
          path={`${routePrefix}/${getRouteExpenseNew({ groupId: ':groupId' })}`}
          render={() => <ExpenseNew />}
        />

        <Route
          path={`${routePrefix}/${getRouteExpenseShow({ groupId: ':groupId', expenseId: ':expenseId' })}`}
          component={(routeProps: RouteComponentProps<ExpenseSpecificRouteProps>) => (
            <ExpenseSpecific {...routeProps} />
          )}
        />

        <Redirect to={`${routePrefix}/${getRouteGroupShow({ groupId: group.id })}`} />
      </AnimatedSwitch>
    </GroupProvider>
  );
};
