import React from 'react';
import { FormattedNumber } from 'react-intl';

import { CURRENCIES_MAP, CurrencyCode } from '../../models/Currency';

type Props = { amount: number; currencyCode: CurrencyCode };

export const CurrencyAmount: React.FC<Props> = ({ amount, currencyCode }) => {
  const currency = CURRENCIES_MAP[currencyCode];

  return (
    <FormattedNumber
      value={amount}
      style="currency"
      currencyDisplay="symbol"
      currency={currencyCode}
      maximumFractionDigits={currency.precision}
    />
  );
};
