import React, { useContext } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { AnimatedSwitch } from 'react-router-transition';

import { GroupContext } from '../../../contexts/GroupContext';
import { LocaleContext } from '../../../contexts/LocaleContext';

import { ExpenseId } from '../../../models/Expense';

import { getRouteExpenseEdit, getRouteGroupShow } from '../../../utils/navRoutes';
import { routeTransitionProps } from '../../../utils/visual';

import { Error404 } from '../_error/404';

import { ExpenseEdit, RouteProps as EditRouteProps } from './Edit';

export type RouteProps = {
  expenseId: ExpenseId;
};

export const ExpenseSpecific: React.FC<RouteComponentProps<RouteProps>> = ({
  match: {
    params: { expenseId },
  },
}) => {
  const { locale } = useContext(LocaleContext);
  const { group } = useContext(GroupContext);

  const routePrefix = `/${locale}`;

  const expense = group.expenses.filter(({ id }) => id === expenseId)?.[0];

  if (!expense) {
    return <Error404 />;
  }

  return (
    <AnimatedSwitch {...routeTransitionProps}>
      <Route
        exact
        path={`${routePrefix}/${getRouteExpenseEdit({ groupId: ':groupId', expenseId: ':expenseId' })}`}
        component={(routeProps: RouteComponentProps<EditRouteProps>) => (
          <ExpenseEdit {...routeProps} expense={expense} />
        )}
      />

      <Redirect to={`${routePrefix}/${getRouteGroupShow({ groupId: group.id })}`} />
    </AnimatedSwitch>
  );
};
