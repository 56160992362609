/* eslint-disable no-unreachable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Intent } from '@blueprintjs/core';

import { pick } from 'lodash';

import { useUserOrRedirect } from '../../../hooks/useUserOrRedirect';
import { commonMessages } from '../../../utils/commonMessages';
import { getRouteChangePassword, getRouteHome } from '../../../utils/navRoutes';

import { Page } from '../../Page';
import { Container, Size } from '../../Container';
import { ProfileForm } from '../../ProfileForm';
import { BackLink } from '../../BackLink';
import { Title } from '../../Title';
import { LocaleLink } from '../../LocaleLink';
import { DialogButton } from '../../DialogButton';

import { messages } from './messages';

export const Settings: React.FC = () => {
  const { returnContent, user } = useUserOrRedirect();

  if (!user) {
    return returnContent;
  }

  const initialValues = pick(user.profile, ['id', 'displayName']);

  const onSubmit = console.info;

  return (
    <Page size={Size.XS}>
      <Container top size={Size.XS}>
        <Title
          backLink={<BackLink to={getRouteHome()} messageDescriptor={commonMessages.navHome} />}
          condensed
          center
          wrapped
        >
          <FormattedMessage {...commonMessages.navSettings} />
        </Title>

        <Title tagName="h2" condensed>
          <FormattedMessage {...commonMessages.profileData} />
        </Title>

        <ProfileForm onSubmit={onSubmit} initialData={initialValues} />
      </Container>
      <Container top bottom size={Size.XS}>
        <Title tagName="h2" condensed>
          <FormattedMessage {...commonMessages.password} />
        </Title>

        <LocaleLink
          to={getRouteChangePassword({ email: user.email })}
          className="bp3-button bp3-minimal bp3-small bp3-intent-primary"
        >
          <FormattedMessage {...commonMessages.authChangePassword} />
        </LocaleLink>
      </Container>

      <Container top bottom size={Size.XS}>
        <Title tagName="h2" condensed>
          <FormattedMessage {...messages.deleteAccount} />
        </Title>

        <DialogButton
          dialogProps={{ title: <FormattedMessage {...messages.deleteAccount} /> }}
          toggleButtonProps={{
            intent: Intent.DANGER,
            small: true,
            minimal: true,
            text: <FormattedMessage {...messages.deleteAccount} />,
          }}
          actionButtons={[
            {
              text: <FormattedMessage {...commonMessages.actionClose} />,
            },
          ]}
        >
          <FormattedMessage
            {...messages.deleteInstruction}
            values={{
              emailSupport: (
                <a href="mailto:support@settler.com" target="_blank">
                  support@settler.com
                </a>
              ),
              emailDelete: 'goodbye@settler.com',
              br: <br />,
            }}
          />
        </DialogButton>
      </Container>
    </Page>
  );
};
