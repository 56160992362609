import React, { FC, ImgHTMLAttributes } from 'react';
import classNames from 'classnames';

import { Icon, IconName } from '@blueprintjs/core';

import style from './style.module.scss';

export enum Size {
  DEFAULT = 'default',
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export const ICON_SIZES = {
  [Size.LARGE]: 0.8 * 160,
  [Size.DEFAULT]: 0.8 * 96,
  [Size.MEDIUM]: 0.8 * 48,
  [Size.SMALL]: 0.8 * 30,
};

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  icon?: IconName;
  size?: Size;
};

export const Avatar: FC<Props> = ({ className, size = Size.DEFAULT, icon = 'member', ...props }) => {
  const wrapperClasses = [
    className,
    style.wrapper,
    ...Object.values(Size).map(s => ({ [style[`wrapper-${s}`]]: size === s })),
  ];
  if (!props.src) {
    return (
      <span className={classNames([...wrapperClasses])}>
        <Icon icon={icon as IconName} iconSize={ICON_SIZES[size]} className={style.icon} />
      </span>
    );
  }
  return <img {...props} className={classNames(wrapperClasses)} />;
};
