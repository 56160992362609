import { gql } from '@apollo/client';

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      email
      profile {
        id
        displayName
      }
    }
  }
`;

export const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      id
      profile {
        id
        displayName
      }
    }
  }
`;

export const SIGN_OUT = gql`
  mutation Signout {
    signOut
  }
`;

export const SIGN_UP = gql`
  mutation SignUp($email: String!, $displayName: String!, $password: String!) {
    signUp(email: $email, password: $password, displayName: $displayName) {
      id
      profile {
        id
        displayName
      }
    }
  }
`;

export const SIGN_UP_CONFIRM = gql`
  mutation SignUpConfirm($email: String!, $token: String!) {
    signUpConfirm(email: $email, token: $token) {
      id
      profile {
        id
        displayName
      }
    }
  }
`;

export const SIGN_UP_VIA_INVITE_CONFIRM = gql`
  mutation SignUpViaInviteConfirm($email: String!, $token: String!, $displayName: String!, $password: String!) {
    signUpViaInviteConfirm(email: $email, token: $token, displayName: $displayName, password: $password) {
      id
      profile {
        id
        displayName
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($email: String!, $passwordNew: String!, $passwordOld: String, $token: String) {
    changePassword(email: $email, passwordNew: $passwordNew, passwordOld: $passwordOld, token: $token) {
      id
      profile {
        id
        displayName
      }
    }
  }
`;

export const GET_USER_GROUPS = gql`
  query GetUserGroups {
    getUserGroups {
      id
      displayName
    }
  }
`;

export const GET_GROUP_JOIN_REQUESTS = gql`
  query GetGroupJoinRequests {
    getGroupJoinRequests {
      id
      displayName
      description
    }
  }
`;

export const GET_EXCHANGE_RATES = gql`
  query getExchangeRates {
    getExchangeRates {
      timestamp
      exchangeRates {
        baseCode
        quoteCode
        value
      }
    }
  }
`;

export const GET_GROUP = gql`
  query GetGroup($id: String!) {
    getGroup(id: $id) {
      id
      displayName
      description
      members {
        id
        displayName
        status
        profile {
          id
          displayName
        }
      }
      expenses {
        id
        subject
        amount
        currencyCode
        paidAt
        payer {
          id
          displayName
        }
        splitKind
        splitAmounts {
          amount
          member {
            id
            displayName
          }
        }
      }
      payments {
        id
        payer {
          id
          displayName
        }
        payee {
          id
          displayName
        }
        amount
        currencyCode
        paidAt
      }
      balanceCurrencyKind
      selectedSingleCurrency
      exchangeRates {
        baseCode
        quoteCode
        value
      }
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation CreateGroup($input: GroupInput!) {
    createGroup(input: $input) {
      id
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup(
    $id: String!
    $displayName: String!
    $description: String!
    $balanceCurrencyKind: GroupBalanceCurrencyKind!
    $selectedSingleCurrency: String
    $exchangeRates: [ExchangeRateInput]
  ) {
    updateGroup(
      id: $id
      displayName: $displayName
      description: $description
      balanceCurrencyKind: $balanceCurrencyKind
      selectedSingleCurrency: $selectedSingleCurrency
      exchangeRates: $exchangeRates
    ) {
      id
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation DeleteGroup($id: String!) {
    deleteGroup(id: $id)
  }
`;

export const INVITE_TO_GROUP_BY_EMAILS = gql`
  mutation InviteToGroupByEmail($groupId: ID!, $emails: [String!]!) {
    inviteToGroupByEmails(groupId: $groupId, emails: $emails) {
      id
    }
  }
`;

export const GROUP_INVITE_ACCEPT = gql`
  mutation groupInviteAccept($groupId: ID!) {
    groupInviteAccept(groupId: $groupId)
  }
`;

export const GROUP_INVITE_REJECT = gql`
  mutation groupInviteReject($groupId: ID!) {
    groupInviteReject(groupId: $groupId)
  }
`;

export const REMOVE_GROUP_MEMBER = gql`
  mutation RemoveGroupMember($groupId: ID!, $memberId: String!) {
    removeGroupMember(groupId: $groupId, memberId: $memberId) {
      id
    }
  }
`;

export const CREATE_EXPENSE = gql`
  mutation CreateExpense($groupId: ID!, $input: ExpenseInput!) {
    createExpense(groupId: $groupId, input: $input)
  }
`;

export const UPDATE_EXPENSE = gql`
  mutation UpdateExpense($groupId: ID!, $expenseId: ID!, $input: ExpenseInput!) {
    updateExpense(groupId: $groupId, expenseId: $expenseId, input: $input)
  }
`;

export const DELETE_EXPENSE = gql`
  mutation DeleteExpense($groupId: ID!, $expenseId: ID!) {
    deleteExpense(groupId: $groupId, expenseId: $expenseId)
  }
`;

export const CREATE_PAYMENT = gql`
  mutation CreatePayment($groupId: ID!, $input: PaymentInput!) {
    createPayment(groupId: $groupId, input: $input)
  }
`;

export const DELETE_PAYMENT = gql`
  mutation DeletePayment($groupId: ID!, $paymentId: ID!) {
    deletePayment(groupId: $groupId, paymentId: $paymentId)
  }
`;
