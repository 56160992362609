import { ApolloProvider } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom';

import { apolloClient } from './utils/apolloClient';
import { suppressKnownConsoleMessages } from './utils/console';
import reportWebVitals from './reportWebVitals';

import { App } from './components/App';

import './styles/index.scss';

suppressKnownConsoleMessages();

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
