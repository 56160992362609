import { sortBy } from 'lodash';

import { Expense } from '../../../../models/Expense';
import {
  applyFilters,
  FilterConfig,
  FilterValueNumberRange,
  FilterValueSingle,
  FilterValueTimestampRange,
} from '../../../Filters';

export enum ExpenseFilterId {
  EXPENSE_SUBJECT = 'subject',
  EXPENSE_CURRENCY = 'currency',
  EXPENSE_PAID_BY = 'paidBy',
  EXPENSE_PAID_FOR = 'paidFor',
  EXPENSE_DATE_RANGE = 'dateRange',
  EXPENSE_AMOUNT_RANGE = 'amountRange',
}

const getExpenseFilteringFunction = (filterConfig: FilterConfig) => {
  let testFn: (e: Expense, value: FilterValueSingle) => boolean;

  switch (filterConfig.id) {
    case ExpenseFilterId.EXPENSE_SUBJECT: {
      testFn = (expense, val) => !!val && expense.subject.toLowerCase().includes((val as string).toLowerCase());
      break;
    }
    case ExpenseFilterId.EXPENSE_CURRENCY: {
      testFn = (expense, val) => !!val && (expense.currencyCode as string) === (val as string);
      break;
    }
    case ExpenseFilterId.EXPENSE_PAID_BY: {
      testFn = (expense, val) => expense.payer.id === val;
      break;
    }
    case ExpenseFilterId.EXPENSE_PAID_FOR: {
      testFn = (expense, val) => expense.splitAmounts.some(({ member: { id } }) => id === val);
      break;
    }
    case ExpenseFilterId.EXPENSE_DATE_RANGE: {
      testFn = (expense, val) => {
        const v = (val as unknown) as FilterValueTimestampRange;

        const isLaterThanFrom = v?.from ? parseInt(expense.paidAt) >= parseInt(v.from) : true;
        const isEarlierThanTo = v?.to ? parseInt(expense.paidAt) <= parseInt(v.to) : true;

        return isLaterThanFrom && isEarlierThanTo;
      };
      break;
    }
    case ExpenseFilterId.EXPENSE_AMOUNT_RANGE: {
      testFn = (expense, val) => {
        const v = (val as unknown) as FilterValueNumberRange;

        const isGreaterThanFrom = v?.from ? expense.amount >= v.from : true;
        const isLessThanTo = v?.to ? expense.amount <= v.to : true;

        return isGreaterThanFrom && isLessThanTo;
      };
      break;
    }
    default:
      testFn = () => false;
  }

  return testFn;
};

export const filterExpenses = (allExpenses: Expense[], filters: FilterConfig[]): Expense[] => {
  return applyFilters(allExpenses, filters, getExpenseFilteringFunction);
};

export const sortExpenses = (expenses: Expense[]): Expense[] => sortBy(expenses, 'paidAt').reverse();
