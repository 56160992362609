import React from 'react';

import { ErrorUnknown } from '../_pages/_error/Unknown';

type Props = {
  path?: string;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  };

  constructor(props: Props) {
    super(props);
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  // componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
  //   callErrorLoggingService(error, errorInfo);
  // }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.path !== this.props.path) {
      this.setState({ hasError: false });
    }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorUnknown />;
    }

    return this.props.children;
  }
}
