import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  heroTitle: {
    id: 'landingHeroTitle',
    defaultMessage: 'Share expenses{br}without the hassle',
  },
  heroCta: {
    id: 'landingHeroCta',
    defaultMessage: 'Start settling',
  },
  usecaseSplitBuzzword: { id: 'landingUsecaseSplitBuzzword', defaultMessage: 'Settle later' },
  usecaseSplitTitle: { id: 'landingUsecaseSplitTitle', defaultMessage: 'Pay for all, spend for yourself' },
  usecaseSplitDescription: {
    id: 'landingUsecaseSplitDescription',
    defaultMessage:
      'Asking to split the bill feels awkward, yet paying for everyone is a bit much?{br}{buzzword} within the group, tip and service charge included.',
  },
  usecaseAccumulateBuzzword: { id: 'landingUsecaseAccumulateBuzzword', defaultMessage: 'periodically balance' },
  usecaseAccumulateTitle: { id: 'landingUsecaseAccumulateTitle', defaultMessage: 'Rent vs. groceries vs. streaming' },
  usecaseAccumulateDescription: {
    id: 'landingUsecaseAccumulateDescription',
    defaultMessage:
      'You pay for some things, while your roomates or family take care of the rest?{br}Keep track of the payments and {buzzword} them out.',
  },
  usecaseInRoundBuzzword: { id: 'landingUsecaseInRoundBuzzword', defaultMessage: 'whose turn' },
  usecaseInRoundTitle: { id: 'landingUsecaseInRoundTitle', defaultMessage: 'This one is on me' },
  usecaseInRoundDescription: {
    id: 'landingUsecaseInRoundDescription',
    defaultMessage:
      'You attend some recurring group activity and pay in the round?{br}Never lose track {buzzword} is it this time.',
  },
  usecaseSummaryBuzzword: { id: 'landingUsecaseSummaryBuzzword', defaultMessage: 'you are settled' },
  usecaseSummaryTitle: { id: 'landingUsecaseSummaryTitle', defaultMessage: 'Wherever, whenever, whomever with' },
  usecaseSummaryDescription: {
    id: 'landingUsecaseSummaryDescription',
    defaultMessage:
      'On vacation, at home, at the office, or maybe out in the city?{br}Whatever the occasion to share the expenses, {buzzword}.',
  },
  ctaMessage: {
    id: 'landingCtaMessage',
    defaultMessage: 'Stop having awkward money discussions',
  },
  ctaAction: {
    id: 'landingCtaAction',
    defaultMessage: 'Use Settler',
  },
});
