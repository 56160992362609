import { ItemPredicate } from '@blueprintjs/select';

import { GroupMember } from '../../../models/GroupMember';

export type MemberOption = GroupMember;

export const filterMember: ItemPredicate<MemberOption> = (query, item) => {
  const { displayName } = item;
  const normalizedDisplayName = displayName.toUpperCase();
  const normalizedQuery = query.toUpperCase();

  return normalizedDisplayName.indexOf(normalizedQuery) >= 0;
};

export const areMemberOptionsEqual = (member1: MemberOption, member2: MemberOption): boolean =>
  member1.id === member2.id;
