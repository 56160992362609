import ls from 'local-storage';

type StorageValue = string | Record<string, unknown> | null;

type EventFunction = (key: string, cb?: (value: StorageValue, old?: StorageValue, url?: string) => void) => void;

type StorageHandler = {
  get: (key: string) => StorageValue;
  set: (key: string, value: StorageValue) => boolean;
  remove: (key: string) => boolean | void;
  clear: (key: string) => void;
  on: EventFunction;
  off: EventFunction;
};

class Storage {
  static KEY_PREFIX = 'STLR_';
  static getPrefixedKey = (key: string) => `${Storage.KEY_PREFIX}${key}`;

  handler = (ls as unknown) as StorageHandler;

  constructor(handler?: StorageHandler) {
    if (handler) {
      this.handler = handler;
    }
  }

  get: StorageHandler['get'] = key => this.handler.get(Storage.getPrefixedKey(key));
  set: StorageHandler['set'] = (key, value) => this.handler.set(Storage.getPrefixedKey(key), value);
  remove: StorageHandler['remove'] = key => this.handler.remove(Storage.getPrefixedKey(key));
  clear: StorageHandler['clear'] = key => this.handler.clear(Storage.getPrefixedKey(key));
  on: StorageHandler['on'] = (key, cb) => this.handler.on(Storage.getPrefixedKey(key), cb);
  off: StorageHandler['off'] = (key, cb) => this.handler.off(Storage.getPrefixedKey(key), cb);
}

export const storage = new Storage();
