import { ExpenseId } from '../models/Expense';
import { GroupId } from '../models/Group';

export const getRouteHome = (): string => '';

//auth
export const getRouteSignIn = (): string => 'sign-in';
export const getRouteSignUp = (): string => 'sign-up';
export const getRouteSignUpConfirm = (): string => 'sign-up-confirm';
export const getRouteInviteConfirm = (): string => 'invite-confirm';
export const getRouteResetPassword = (args?: { email?: string } | undefined): string => {
  const { email } = { email: '', ...(args || {}) };
  return `reset-password${email ? `?email=${email}` : ''}`;
};
export const getRouteChangePassword = (args?: { email?: string } | undefined): string => {
  const { email } = { email: '', ...(args || {}) };
  return `change-password${email ? `?email=${email}` : ''}`;
};
//groups
export const getRouteGroupList = (): string => `groups`;
export const getRouteGroupNew = (): string => `groups/new`;
export const getRouteGroupShow = ({ groupId }: { groupId: GroupId }): string => `groups/${groupId}`;
export const getRouteGroupEdit = ({ groupId }: { groupId: GroupId }): string => `groups/${groupId}/edit`;
export const getRouteGroupSettle = ({ groupId }: { groupId: GroupId }): string => `groups/${groupId}#settle`;
export const getRouteGroupHistory = ({ groupId }: { groupId: GroupId }): string => `groups/${groupId}#history`;

//expenses
export const getRouteExpenseList = ({ groupId }: { groupId: GroupId }): string => `groups/${groupId}/expenses`;
export const getRouteExpenseNew = ({ groupId }: { groupId: GroupId }): string => `groups/${groupId}/expenses/new`;
export const getRouteExpenseShow = ({ groupId, expenseId }: { groupId: GroupId; expenseId: ExpenseId }): string =>
  `groups/${groupId}/expenses/${expenseId}`;
export const getRouteExpenseEdit = ({ groupId, expenseId }: { groupId: GroupId; expenseId: ExpenseId }): string =>
  `groups/${groupId}/expenses/${expenseId}/edit`;

//admin
export const getRouteSettings = (): string => 'settings';

//static
export const getRouteAbout = (): string => 'about';
export const getRouteTerms = (): string => 'terms';
export const getRoutePrivacy = (): string => 'privacy';
