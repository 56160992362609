//IMPORTANT NOTE: When adding new locale, update all places marked like this
import en from './en.json';
import pl from './pl.json';

export type BaseLocale = 'en';
export type OtherLocale = 'pl';
//IMPORTANT NOTE: When adding new locale, update all places marked like this
export type AvailableLocale = BaseLocale | OtherLocale;

export const defaultLocale: AvailableLocale = 'en';

//IMPORTANT NOTE: When adding new locale, update all places marked like this
export const messages = { en, pl };

export const locales: AvailableLocale[] = Object.keys(messages) as AvailableLocale[];

//IMPORTANT NOTE: When adding new locale, update all places marked like this
export const languageNames = {
  en: 'English',
  pl: 'Polski',
};

export function isLocale(tested: string): boolean {
  return locales.some(locale => locale === tested);
}

export { getInitialLocale } from './getInitialLocale';
