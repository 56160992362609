import { throttle } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Footer } from '../../Footer';

import { NavigationSimple } from './components/NavigationSimple';
import { LandingContentMemoized } from './components/LandingContent';
import style from './style.module.scss';

const NavigationMemoized = React.memo(NavigationSimple);
const FooterMemoized = React.memo(Footer);

export const Landing: React.FC = () => {
  const [isNavTransparent, setIsNavTransparent] = useState(true);

  const [scrollTop, setScrollTop] = useState(document.documentElement.scrollTop);

  useEffect(() => {
    setScrollTop(document.documentElement.scrollTop);
    window.addEventListener('scroll', handleOnScroll);
    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };
  }, []);

  const handleOnScroll = throttle(() => {
    setScrollTop(document.documentElement.scrollTop);
  }, 100);

  useEffect(() => {
    setIsNavTransparent(scrollTop < 100);
  }, [scrollTop]);

  return (
    <>
      <NavigationMemoized isTransparent={isNavTransparent} isFixed />

      <LandingContentMemoized />

      <FooterMemoized
        className={style.footer}
        secondaryCopyright={
          <>
            Illustrations designed by{' '}
            <a href="https://www.freepik.com" target="_blank" rel="nofollow" className="text-color-inherit">
              Freepik
            </a>
          </>
        }
      />
    </>
  );
};
