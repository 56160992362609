import { Intent } from '@blueprintjs/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useForm, Control, SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Redirect, useLocation } from 'react-router';

import { AuthContext } from '../../../../contexts/AuthContext';
import { getRouteHome, getRouteResetPassword } from '../../../../utils/navRoutes';
import yup from '../../../../utils/yup';
import { commonMessages } from '../../../../utils/commonMessages';

import { CHANGE_PASSWORD } from '../../../../api/queries';

import { Page } from '../../../Page';
import { Container, Size as ContainerSize } from '../../../Container';
import { MainButton } from '../../../MainButton';
import { Field } from '../../../_form/Field';
import { LocaleLink } from '../../../LocaleLink';
import { Title } from '../../../Title';

import { messages } from './messages';

type ChangePasswordMutationVariables = {
  email: string;
  passwordNew: string;
  passwordOld?: string;
  token?: string;
};

export const ChangePassword: React.FC = () => {
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const token = query.get('token');
  const email = query.get('email') || '';

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    passwordNew: yup.string().required().min(8),
    ...(token
      ? {
          token: yup.string().required(),
        }
      : {
          passwordOld: yup.string().required(),
        }),
  });

  const emptyInitialValues = {
    email,
    passwordNew: '',
    ...(token ? { token } : { passwordOld: '' }),
  };

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<ChangePasswordMutationVariables>({
    resolver: yupResolver(schema),
    defaultValues: emptyInitialValues,
    mode: 'onChange',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const { isInitialized, user, updateUser } = useContext(AuthContext);
  const [shouldRedirectHome, setShouldRedirectHome] = useState(false);
  const [changePassword, { data, error }] = useMutation(CHANGE_PASSWORD);

  const onSubmit: SubmitHandler<ChangePasswordMutationVariables> = async variables => {
    reset(variables);
    await changePassword({ variables });
  };

  const onPasswordChangeSuccess = async () => {
    const wasSignedIn = !!user;

    await updateUser(data.changePassword);

    if (!wasSignedIn) {
      setShouldRedirectHome(true);
    }
  };

  useEffect(() => {
    if (data?.changePassword) {
      onPasswordChangeSuccess();
    }
  }, [data]);

  useEffect(() => {
    if (!token && isInitialized && !user) {
      setShouldRedirectHome(true);
    }
  }, [token, isInitialized, user]);

  if (shouldRedirectHome) {
    return <Redirect to={getRouteHome()} />;
  }

  return (
    <Page size={ContainerSize.XS}>
      <Container size={ContainerSize.XS} top bottom>
        <Title condensed center>
          <FormattedMessage {...commonMessages.authChangePassword} />
        </Title>

        {data && (
          <p className="text-intent-success">
            <FormattedMessage {...messages.success} />
          </p>
        )}

        {error && (
          <p className="text-intent-danger">
            <FormattedMessage {...commonMessages.genericError} />
            <span>: {error ? error.message : ''}</span>
          </p>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Field name="email" hidden isRequired control={(control as unknown) as Control<Record<string, unknown>>} />

          {token ? (
            <Field name="token" hidden isRequired control={(control as unknown) as Control<Record<string, unknown>>} />
          ) : (
            <Field
              name="passwordOld"
              type="password"
              isRequired
              control={(control as unknown) as Control<Record<string, unknown>>}
            />
          )}

          <Field
            name="passwordNew"
            type="password"
            isRequired
            control={(control as unknown) as Control<Record<string, unknown>>}
          />

          <MainButton large type="submit" intent={Intent.SUCCESS} disabled={!isValid || isSubmitting}>
            <FormattedMessage {...commonMessages.authChangePassword} />
          </MainButton>

          {!token && (
            <p className="mb-zero text-center">
              <LocaleLink to={getRouteResetPassword({ email: user?.email || '' })}>
                <FormattedMessage {...commonMessages.authResetPassword} />
              </LocaleLink>
            </p>
          )}
        </form>
      </Container>
    </Page>
  );
};
