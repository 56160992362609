import { Intent } from '@blueprintjs/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import React from 'react';
import { useForm, Control, SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';

import { commonMessages } from '../../../../utils/commonMessages';
import yup from '../../../../utils/yup';

import { RESET_PASSWORD } from '../../../../api/queries';

import { Page } from '../../../Page';
import { Container, Size as ContainerSize } from '../../../Container';
import { Field } from '../../../_form/Field';
import { MainButton } from '../../../MainButton';
import { Title } from '../../../Title';

import { messages } from './messages';

type ResetPasswordMutationVariables = { email: string; token: string };

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const emptyInitialValues = {
  email: '',
};

export const ResetPassword: React.FC = () => {
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const initialEmail = query.get('email') || emptyInitialValues.email;

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<ResetPasswordMutationVariables>({
    resolver: yupResolver(schema),
    defaultValues: { ...emptyInitialValues, email: initialEmail },
    mode: 'onChange',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [resetPassword, { data, error }] = useMutation(RESET_PASSWORD);

  const onSubmit: SubmitHandler<ResetPasswordMutationVariables> = async variables => {
    reset(variables);
    await resetPassword({ variables });
  };

  return (
    <Page size={ContainerSize.XS}>
      <Container size={ContainerSize.XS} top bottom>
        <Title condensed center>
          <FormattedMessage {...commonMessages.authResetPassword} />
        </Title>
        {error && (
          <p className="text-intent-danger">
            <FormattedMessage {...commonMessages.genericError} />
            <span>: {error ? error.message : ''}</span>
          </p>
        )}
        {data && (
          <p className="text-intent-success">
            <FormattedMessage {...messages.success} />
          </p>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field name="email" isRequired control={(control as unknown) as Control<Record<string, unknown>>} />

          <MainButton large type="submit" intent={Intent.SUCCESS} disabled={!isValid || isSubmitting}>
            <FormattedMessage {...commonMessages.authResetPassword} />
          </MainButton>
        </form>
      </Container>
    </Page>
  );
};
