import { Spinner } from '@blueprintjs/core';
import React from 'react';

import { Container, Size } from '../Container';
import { Page } from '../Page';

import style from './style.module.scss';

export { Size } from '../Container';
type Props = {
  size?: Size;
};

export const IndicatorLoadingPage: React.FC<Props> = ({ size = Size.DEFAULT }) => (
  <Page size={size}>
    <Container size={size} className={style.wrapper}>
      <Spinner />
    </Container>
  </Page>
);
