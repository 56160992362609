import { InputGroup, InputGroupProps } from '@blueprintjs/core';
import React, { useEffect, useRef, useState } from 'react';

import { storage } from '../../../utils/storage';

type Props = Omit<InputGroupProps, 'onChange'> & {
  value?: string;
  defaultValue?: string;
  instanceId: string;
  onChange: (val: string) => unknown;
};

const STORAGE_KEY_LAST_ACTIVE_ID = 'lastActiveInput';

export const ForceFocusInput: React.FC<Props> = ({ instanceId, value, defaultValue, onChange, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [_value, _setValue] = useState(defaultValue || value || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    _setValue(newValue);
    onChange(newValue);
    storage.set(STORAGE_KEY_LAST_ACTIVE_ID, instanceId);
  };

  useEffect(() => {
    if (storage.get(STORAGE_KEY_LAST_ACTIVE_ID) === instanceId && inputRef && inputRef.current) {
      inputRef.current!.focus();
      storage.remove(STORAGE_KEY_LAST_ACTIVE_ID);
    }
  });

  return (
    <InputGroup {...props} onChange={handleChange} inputRef={inputRef} value={value !== undefined ? value : _value} />
  );
};
