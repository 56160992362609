import { NumericInput, NumericInputProps } from '@blueprintjs/core';
import classNames from 'classnames';
import { round } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { storage } from '../../../utils/storage';

import style from './style.module.scss';

type Props = NumericInputProps & {
  defaultValue?: number;
  onChange: (amount: number) => void;
  instanceId: string;
  currencyPrecision?: number;
  className?: string;
  disabled?: boolean;
};

const STORAGE_KEY_LAST_ACTIVE_ID = 'lastActiveInput';

export const AmountInput: React.FC<Props> = ({
  defaultValue,
  onChange,
  instanceId,
  currencyPrecision = 2,
  disabled = false,
  className = '',
  value,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [_value, _setValue] = useState(defaultValue?.toString() || value?.toString() || '');

  useEffect(() => {
    if (storage.get(STORAGE_KEY_LAST_ACTIVE_ID) === instanceId && inputRef && inputRef.current) {
      inputRef.current!.focus();
      storage.remove(STORAGE_KEY_LAST_ACTIVE_ID);
    }
  });

  const handleChange = (valueAsNumber: number, valueAsString: string) => {
    const valueAsStringNormalized = valueAsString
      .slice()
      .replace(',', '.')
      .replace(/[^\d.]/g, '');
    const amountRounded = valueAsStringNormalized ? round(parseFloat(valueAsStringNormalized), currencyPrecision) : 0;
    _setValue(amountRounded.toString() + (valueAsStringNormalized.endsWith('.') ? '.' : ''));
    onChange(amountRounded);
    storage.set(STORAGE_KEY_LAST_ACTIVE_ID, instanceId);
  };

  return (
    <NumericInput
      {...props}
      allowNumericCharactersOnly={false}
      inputMode="decimal"
      disabled={disabled}
      defaultValue={defaultValue}
      value={value !== undefined ? value : _value}
      onValueChange={handleChange}
      minorStepSize={1 / 10 ** currencyPrecision}
      min={0}
      inputRef={inputRef}
      className={classNames(style.input, className)}
    />
  );
};
