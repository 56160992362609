import { NonIdealState } from '@blueprintjs/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IconNames } from '@blueprintjs/icons';

import { Page } from '../../Page';
import { Container, Size as ContainerSize } from '../../Container';

import { messages } from './messages';

export const ErrorUnknown: React.FC = () => {
  return (
    <Page size={ContainerSize.SM}>
      <Container size={ContainerSize.SM} top bottom>
        <NonIdealState
          icon={IconNames.LIGHTNING}
          title={<FormattedMessage {...messages.eUnknownTitle} />}
          description={
            <>
              <p>
                <FormattedMessage {...messages.eUnknownDescription} />
              </p>
            </>
          }
        />
      </Container>
    </Page>
  );
};
