import React, { ReactNode, useContext, useState } from 'react';
import { IItemRendererProps, ItemRenderer, Select } from '@blueprintjs/select';
import { Button, MenuItem } from '@blueprintjs/core';
import { FormattedMessage } from 'react-intl';

import { GroupMember, GroupMemberId } from '../../../../models/GroupMember';
import { commonMessages } from '../../../../utils/commonMessages';

import { messages } from '../messages';
import { areMemberOptionsEqual, MemberOption, filterMember } from '../helpers';
import { GroupContext } from '../../../../contexts/GroupContext';

const TypedSelect = Select.ofType<MemberOption>();

type Props = {
  onChange: (selectedMemberId: GroupMemberId) => unknown;
  members?: GroupMember[];
  label?: ReactNode;
  shouldSortOprions?: boolean;
  disabled?: boolean;
  initialMemberId?: GroupMemberId;
};

const itemRenderer: ItemRenderer<MemberOption> = (memberOption, { handleClick, modifiers }: IItemRendererProps) => (
  <MenuItem
    key={memberOption.id}
    active={modifiers.active}
    disabled={modifiers.disabled}
    text={memberOption.displayName}
    onClick={handleClick}
  />
);

export const MemberSelectSingle: React.FC<Props> = ({
  members: customMembers,
  onChange,
  label,
  initialMemberId,
  disabled = false,
}) => {
  const { group } = useContext(GroupContext);
  const members = customMembers || group.members;
  const memberOptions: MemberOption[] = Object.values(members).map(
    ({ id, displayName }) => ({ id, displayName } as MemberOption)
  );
  const [activeItem, setActiveItem] = useState(memberOptions.find(({ id }) => id === initialMemberId));

  const onItemSelect = (memberOption: MemberOption) => {
    setActiveItem(memberOption);
    onChange(memberOption.id);
  };

  return (
    <TypedSelect
      disabled={disabled}
      items={memberOptions}
      itemRenderer={itemRenderer}
      onItemSelect={onItemSelect}
      activeItem={activeItem}
      itemsEqual={areMemberOptionsEqual}
      itemPredicate={filterMember}
      noResults={<MenuItem disabled={true} text={<FormattedMessage {...commonMessages.noResults} />} />}
    >
      <Button
        text={activeItem?.displayName || label || <FormattedMessage {...messages.emptyLabelSingle} />}
        rightIcon="double-caret-vertical"
      />
    </TypedSelect>
  );
};
