import React, { useCallback, useContext, useState } from 'react';
import { Alignment, Button, Divider, Navbar } from '@blueprintjs/core';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useLocation } from 'react-router';

import { AuthContext } from '../../contexts/AuthContext';
import { commonMessages } from '../../utils/commonMessages';
import { getRouteHome, getRouteSettings, getRouteSignIn, getRouteSignUp } from '../../utils/navRoutes';

import { LocaleLink } from '../LocaleLink';
import { LocaleSwitch } from '../LocaleSwitch';

import { Container } from '../Container';
import { NavigationFooterContent } from '../Footer';

import { ReactComponent as Logo } from '../../assets/logo.svg';

import style from './style.module.scss';

type Props = {
  isTransparent?: boolean;
  isFixed?: boolean;
};

export const Navigation: React.FC<Props> = ({ isTransparent, isFixed }) => {
  const { user: currentUser, signOut } = useContext(AuthContext);
  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState(false);
  const { formatMessage } = useIntl();

  const closeMenu = useCallback(() => {
    setIsActive(false);
  }, [setIsActive]);

  const onSignoutClick = useCallback(() => {
    signOut();
    closeMenu();
  }, []);

  const toggleMenu = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  return (
    <Navbar
      className={classNames([
        style.wrapper,
        {
          [style['wrapper--active']]: isActive,
          [style['wrapper--transparent']]: isTransparent,
          [style['wrapper--fixed']]: isFixed,
        },
      ])}
    >
      <Container className={style.content}>
        <Navbar.Group align={Alignment.LEFT} className={style.group}>
          <Navbar.Heading>
            <LocaleLink to={getRouteHome()}>
              <Logo title={formatMessage(commonMessages.title)} height="1.2em" />
            </LocaleLink>
          </Navbar.Heading>
        </Navbar.Group>

        <button
          onClick={toggleMenu}
          className={classNames(['bp3-button', 'bp3-minimal', 'bp3-intent-primary', style.hamburgerButton])}
        >
          <FormattedMessage {...commonMessages.menu} />

          <span className={classNames([style.hamburgerBar, style['hamburgerBar--one']])}></span>
          <span className={classNames([style.hamburgerBar, style['hamburgerBar--two']])}></span>
          <span className={classNames([style.hamburgerBar, style['hamburgerBar--three']])}></span>
        </button>

        <div className={style.secondary}>
          <Navbar.Group align={Alignment.RIGHT} className={style.group}>
            <LocaleSwitch />

            <Navbar.Divider className={style.divider} />

            {currentUser ? (
              <>
                <LocaleLink
                  onClick={closeMenu}
                  isNav
                  activeClassName={style.navLinkCurrent}
                  to={getRouteSettings()}
                  className={classNames([
                    'bp3-button',
                    'bp3-minimal',
                    'bp3-intent-primary',
                    style.navLink,
                    style.profile,
                  ])}
                >
                  <FormattedMessage {...commonMessages.navSettings} />
                </LocaleLink>

                <Button
                  onClick={onSignoutClick}
                  className={classNames(['bp3-button', 'bp3-minimal', 'bp3-intent-primary', style.navLink])}
                >
                  <FormattedMessage {...commonMessages.authSignOut} /> {currentUser.profile.displayName}
                </Button>
              </>
            ) : (
              <>
                <LocaleLink
                  onClick={closeMenu}
                  isNav
                  activeClassName={style.navLinkCurrent}
                  to={{
                    pathname: getRouteSignIn(),
                    state: {
                      from: pathname,
                    },
                  }}
                  className={classNames(['bp3-button', 'bp3-minimal', 'bp3-intent-primary', style.navLink])}
                >
                  <FormattedMessage {...commonMessages.authSignIn} />
                </LocaleLink>

                <LocaleLink
                  onClick={closeMenu}
                  isNav
                  activeClassName={style.navLinkCurrent}
                  to={{
                    pathname: getRouteSignUp(),
                    state: {
                      from: pathname,
                    },
                  }}
                  className={classNames(['bp3-button', 'bp3-minimal', 'bp3-intent-primary', style.navLink])}
                >
                  <FormattedMessage {...commonMessages.authSignUp} />
                </LocaleLink>
              </>
            )}
          </Navbar.Group>

          <Divider className="hidden-from-sm" />

          <NavigationFooterContent className="hidden-from-sm" />
        </div>

        <div className={style.overlay} onClick={closeMenu} />
      </Container>
    </Navbar>
  );
};
