import { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';

import { validationMessages } from './validationMessages';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ValidationMessageDescriptor = MessageDescriptor & { values?: any };

const mixed = {
  default: () => validationMessages.mixedDefault,
  required: () => validationMessages.mixedRequired,
  oneOf: ({ values }: any): ValidationMessageDescriptor => ({ ...validationMessages.mixedOneOf, values: { values } }),
  notOneOf: ({ values }: any): ValidationMessageDescriptor => ({
    ...validationMessages.mixedNotOneOf,
    values: { values },
  }),
  defined: () => validationMessages.mixedDefined,
};

const string = {
  length: ({ length }: any): ValidationMessageDescriptor => ({
    ...validationMessages.stringLength,
    values: { length },
  }),
  min: ({ min }: any): ValidationMessageDescriptor => ({ ...validationMessages.stringMin, values: { min } }),
  max: ({ max }: any): ValidationMessageDescriptor => ({ ...validationMessages.stringMax, values: { max } }),
  matches: ({ regex }: any): ValidationMessageDescriptor => ({
    ...validationMessages.stringMatches,
    values: { regex: regex.toString().replaceAll('/', '') },
  }),
  email: () => validationMessages.stringEmail,
  url: () => validationMessages.stringUrl,
  uuid: () => validationMessages.stringUuid,
  trim: () => validationMessages.stringTrim,
  lowercase: () => validationMessages.stringLowercase,
  uppercase: () => validationMessages.stringUppercase,
};

const number = {
  min: ({ min }: any): ValidationMessageDescriptor => ({ ...validationMessages.numberMin, values: { min } }),
  max: ({ max }: any): ValidationMessageDescriptor => ({ ...validationMessages.numberMax, values: { max } }),
  less: ({ less }: any): ValidationMessageDescriptor => ({ ...validationMessages.numberLessThan, values: { less } }),
  more: ({ more }: any): ValidationMessageDescriptor => ({ ...validationMessages.numberMoreThan, values: { more } }),
  notEqual: ({ notEqual }: any): ValidationMessageDescriptor => ({
    ...validationMessages.numberNotEqual,
    values: { notEqual },
  }),
  positive: () => validationMessages.numberPositive,
  negative: () => validationMessages.numberNegative,
  integer: () => validationMessages.numberInteger,
};

const date = {
  min: ({ min }: any): ValidationMessageDescriptor => ({ ...validationMessages.dateMin, values: { min } }),
  max: ({ max }: any): ValidationMessageDescriptor => ({ ...validationMessages.dateMax, values: { max } }),
};

const boolean = {};

const object = {
  noUnknown: ({ unknown }: any): ValidationMessageDescriptor => ({
    ...validationMessages.objectNoUnknown,
    values: { unknown },
  }),
};

const array = {
  min: ({ min }: any): ValidationMessageDescriptor => ({ ...validationMessages.arrayMin, values: { min } }),
  max: ({ max }: any): ValidationMessageDescriptor => ({ ...validationMessages.arrayMax, values: { max } }),
};

const locale = {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
};

yup.setLocale(locale);

export default yup;
