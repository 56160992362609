import { round, shuffle } from 'lodash';

export function splitAmountEvenly(total: number, groupCount: number, precision = 2): number[] {
  const amounts = new Array(groupCount);

  const precisionMultiplier = 10 ** precision;
  const base = Math.floor((total * precisionMultiplier) / groupCount) / precisionMultiplier;

  amounts.fill(base);

  const rest = total - base * groupCount;
  const restRounded = round(rest, precision);
  const restUnit = 1 / precisionMultiplier;

  const restRecipientsCount = restRounded / restUnit;
  const restRecipientsIndices = shuffle(Array.from(amounts.keys())).slice(0, restRecipientsCount);

  restRecipientsIndices.forEach(index => {
    amounts[index] += restUnit;
  });

  return amounts.map(a => round(a, precision));
}
