import React, { useContext } from 'react';
import { Redirect, useLocation } from 'react-router';

import { AuthContext } from '../../../../contexts/AuthContext';

import { Page } from '../../../Page';
import { Container, Size as ContainerSize } from '../../../Container';

import { getRouteHome } from '../../../../utils/navRoutes';

export const RegistrationLayout: React.FC = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { state } = useLocation<{ from: string }>();

  if (user) {
    if (state && state.from) {
      return <Redirect to={state.from} />;
    }
    return <Redirect to={getRouteHome()} />;
  }

  return (
    <Page size={ContainerSize.XS}>
      <Container size={ContainerSize.XS} top bottom>
        {children}
      </Container>
    </Page>
  );
};
