import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { commonMessages } from '../../utils/commonMessages';

import style from './style.module.scss';

export const IndicatorLoading: React.FC = () => (
  <span className={style.wrapper}>
    <FormattedMessage {...commonMessages.loading} />
    <span className={classNames(style.dot, style['dot--first'])}>.</span>
    <span className={classNames(style.dot, style['dot--second'])}>.</span>
    <span className={classNames(style.dot, style['dot--third'])}>.</span>
  </span>
);
