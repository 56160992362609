import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  emptyLabelSingle: {
    id: 'memberSelectEmptyLabelSingle',
    defaultMessage: 'Select member',
  },
  emptyLabelMultiple: {
    id: 'memberSelectEmptyLabelMultiple',
    defaultMessage: 'Select members',
  },
  emptyLabelMultipleWithQuery: {
    id: 'memberSelectEmptyLabelMultipleWithQuery',
    defaultMessage: 'Select members or type in their emails',
  },
  emptyLabelInput: {
    id: 'memberSelectEmptyLabelInput',
    defaultMessage: 'Type the email of member you wish to invite',
  },
  leftErrorInput: {
    id: 'memberSelectLeftErrorInput',
    defaultMessage: 'This person has left the group',
  },
  deleteConfirmationMessage: {
    id: 'memberSelectDeleteConfirmationMessage',
    defaultMessage: 'Are you sure you want to remove member "{displayName}"?',
  },
  deleteYourselfConfirmationMessage: {
    id: 'memberSelectDeleteYourselfConfirmationMessage',
    defaultMessage: 'Are you sure you want to leave this group?',
  },
  memberStatusInvited: {
    id: 'memberSelectMemberStatusInvited',
    defaultMessage: 'Invitation sent',
  },
  memberStatusRejected: {
    id: 'memberSelectMemberStatusRejected',
    defaultMessage: 'Invitation rejected',
  },
});
