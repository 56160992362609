import { companyData, mainPrivacyUrl, serviceName, siteUrl } from '../../../../utils/contactData';

export const en = `
# Privacy policy

1. The Service ${serviceName} is provided under the address ${siteUrl}.

1. The data is managed by the Administrator:

    ${companyData.name}
    ${companyData.addressStreet},
    ${companyData.addressZipCode} ${companyData.addressCity},
    ${companyData.addressCountry}

1. The Service is subject to jurisdiction of the seat of the Company (Poland).
1. Full and applicable Privacy Policy available in Polish under: [${siteUrl}${mainPrivacyUrl}](${mainPrivacyUrl}).
`;
