import { NonIdealState } from '@blueprintjs/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IconNames } from '@blueprintjs/icons';

import { getRouteHome } from '../../../utils/navRoutes';

import { Page } from '../../Page';
import { Container, Size as ContainerSize } from '../../Container';
import { LocaleLink } from '../../LocaleLink';

import { messages } from './messages';

export const Error404: React.FC = () => {
  return (
    <Page size={ContainerSize.SM}>
      <Container size={ContainerSize.SM} top bottom>
        <NonIdealState
          icon={IconNames.SATELLITE}
          title={<FormattedMessage {...messages.e404Title} />}
          description={
            <>
              <p>
                <FormattedMessage {...messages.e404Description} />
              </p>

              <p>
                <FormattedMessage {...messages.e404Hint} />{' '}
                <LocaleLink to={getRouteHome()}>
                  <FormattedMessage {...messages.e404HomeLabel} />
                </LocaleLink>
              </p>
            </>
          }
        />
      </Container>
    </Page>
  );
};
