import { Colors } from '@blueprintjs/core';

export const routeTransitionProps = {
  atEnter: { opacity: 0 },
  atLeave: { opacity: 0 },
  atActive: { opacity: 1 },
  className: 'routerAnimatedSwitch',
};

export const ICON_COLOR = {
  defaultOverBg: Colors.WHITE,
};

export const ICON_BG_COLOR = {
  default: Colors.LIGHT_GRAY3,
  neutral: Colors.GRAY4,
  success: Colors.GREEN4,
  danger: Colors.RED4,
};

export const ICON_SIZE = {
  default: 16,
  listDefault: 32,
  listSmall: 24,
  prominent: 48,
};
