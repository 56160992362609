import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  subjectPlaceholder: {
    id: 'expenseFormSubjectPlaceholder',
    defaultMessage: 'what was it?',
  },
  subjectDefault: {
    id: 'expenseFormSubjectDefault',
    defaultMessage: 'shopping',
  },
  for: {
    id: 'expenseFormFor',
    defaultMessage: 'for',
  },
  paid: {
    id: 'expenseFormPaid',
    defaultMessage: 'paid',
  },
  paidBy: {
    id: 'expenseFormPaidBy',
    defaultMessage: 'by',
  },
  paidByFirstMember: {
    id: 'expenseFormPaidByFirstMember',
    defaultMessage: 'by',
  },
  me: {
    id: 'expenseFormMe',
    defaultMessage: 'me',
  },
  shouldBeSplit: {
    id: 'expenseFormShouldBeSplit',
    defaultMessage: 'should be split',
  },
  toEveryone: {
    id: 'expenseFormToEveryone',
    defaultMessage: 'to everyone',
  },
  asFollows: {
    id: 'expenseFormAsFollows',
    defaultMessage: 'as follows:',
  },
  totalShouldMatch: {
    id: 'expenseFormTotalShouldMatch',
    defaultMessage: 'Amounts should add up to {amount}',
  },
});
