import { Button, FormGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CurrencyCode } from '../../../models/Currency';
import { FilterConfig, FilterChange, FilterKind, FilterValueTimestampRange, FilterValueNumberRange } from '../_types';
import { isValueTruthy } from '../helpers';

import { commonMessages } from '../../../utils/commonMessages';

import { DatePickerButton } from '../../DatePickerButton';
import { MemberSelectMultiple } from '../../_form/MemberSelect/Multiple';
import { MemberSelectSingle } from '../../_form/MemberSelect/Single';
import { AmountInput } from '../../_form/AmountInput';
import { ForceFocusInput } from '../../_form/ForceFocusInput';
import { CurrencySelect } from '../../_form/CurrencySelect';

import style from './style.module.scss';

export type FilterFieldProps = FilterConfig & {
  onChange: (change: FilterChange) => unknown;
};

export const FilterField: React.FC<FilterFieldProps> = ({ onChange, id, kind, value, label = id }) => {
  const isActive = isValueTruthy(value);
  switch (kind) {
    case FilterKind.TEXT: {
      return (
        <FormGroup label={isActive ? <strong>{label}</strong> : label}>
          <ForceFocusInput
            instanceId={id}
            value={value as string}
            onChange={val => onChange({ id, value: val || undefined })}
            rightElement={
              value ? (
                <Button minimal icon={IconNames.CROSS} onClick={() => onChange({ id, value: undefined })} />
              ) : undefined
            }
          />
        </FormGroup>
      );
    }
    case FilterKind.CURRENCY: {
      return (
        <FormGroup label={isActive ? <strong>{label}</strong> : label}>
          <CurrencySelect
            initialCurrencyCode={value as CurrencyCode | undefined}
            onChange={val => onChange({ id, value: val || undefined })}
          />
          {value && <Button minimal icon={IconNames.CROSS} onClick={() => onChange({ id, value: undefined })} />}
        </FormGroup>
      );
    }
    case FilterKind.MEMBER_MULTIPLE: {
      return (
        <FormGroup label={isActive ? <strong>{label}</strong> : label}>
          <MemberSelectMultiple
            fill
            key={JSON.stringify(value)}
            initialMemberIds={value as string[] | undefined}
            onChange={ids => onChange({ id, value: ids })}
          />
        </FormGroup>
      );
    }
    case FilterKind.MEMBER_SINGLE: {
      return (
        <FormGroup label={isActive ? <strong>{label}</strong> : label}>
          <MemberSelectSingle
            key={JSON.stringify(value)}
            initialMemberId={value as string}
            onChange={value => onChange({ id, value })}
          />
          {value && <Button minimal icon={IconNames.CROSS} onClick={() => onChange({ id, value: undefined })} />}
        </FormGroup>
      );
    }
    case FilterKind.DATE_RANGE: {
      const val = value as FilterValueTimestampRange | undefined;
      return (
        <FormGroup label={isActive ? <strong>{label}</strong> : label}>
          <div className={style.splitRow}>
            <div className={style.splitRowPart}>
              <span className={style.splitRowLabel}>
                <FormattedMessage {...commonMessages.rangeFrom} tagName={val?.from ? 'strong' : 'span'} />
              </span>

              <DatePickerButton
                value={val?.from}
                onChange={v => onChange({ id, value: { ...(val || {}), from: v } })}
              />

              {val?.from && (
                <Button
                  minimal
                  icon={IconNames.CROSS}
                  onClick={() => onChange({ id, value: val.to ? { ...val, from: undefined } : undefined })}
                />
              )}
            </div>

            <div className={style.splitRowPart}>
              <span className={style.splitRowLabel}>
                <FormattedMessage {...commonMessages.rangeTo} tagName={val?.to ? 'strong' : 'span'} />
              </span>

              <DatePickerButton value={val?.to} onChange={v => onChange({ id, value: { ...(val || {}), to: v } })} />

              {val?.to && (
                <Button
                  minimal
                  icon={IconNames.CROSS}
                  onClick={() => onChange({ id, value: val.from ? { ...val, to: undefined } : undefined })}
                />
              )}
            </div>
          </div>
        </FormGroup>
      );
    }
    case FilterKind.NUMBER_RANGE: {
      const val = value as FilterValueNumberRange | undefined;

      return (
        <FormGroup label={isActive ? <strong>{label}</strong> : label}>
          <div className={style.splitRow}>
            <div className={style.splitRowPart}>
              <span className={style.splitRowLabel}>
                <FormattedMessage {...commonMessages.rangeFrom} tagName={val?.from ? 'strong' : 'span'} />
              </span>

              <AmountInput
                value={val?.from ? val.from.toString() : ''}
                onChange={v => {
                  return onChange({ id, value: { ...(val || {}), from: v } });
                }}
                instanceId={`${id}-from`}
              />

              {val?.from && (
                <Button
                  minimal
                  icon={IconNames.CROSS}
                  onClick={() => onChange({ id, value: val.to ? { ...val, from: undefined } : undefined })}
                />
              )}
            </div>

            <div className={style.splitRowPart}>
              <span className={style.splitRowLabel}>
                <FormattedMessage {...commonMessages.rangeTo} tagName={val?.to ? 'strong' : 'span'} />
              </span>

              <AmountInput
                value={val?.to}
                onChange={v => onChange({ id, value: { ...(val || {}), to: v } })}
                instanceId={`${id}-to`}
              />
              {val?.to && (
                <Button
                  minimal
                  icon={IconNames.CROSS}
                  onClick={() => onChange({ id, value: val.from ? { ...val, to: undefined } : undefined })}
                />
              )}
            </div>
          </div>
        </FormGroup>
      );
    }
    default:
      return null;
  }
};
