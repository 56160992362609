import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { commonMessages } from '../../utils/commonMessages';
import { getRouteAbout, getRoutePrivacy, getRouteTerms } from '../../utils/navRoutes';

import { Container } from '../Container';
import { LocaleLink } from '../LocaleLink';

import style from './style.module.scss';

type Props = {
  className?: string;
  secondaryCopyright?: ReactNode;
};

export const NavigationFooterContent: React.FC<Props> = ({ className, secondaryCopyright }) => {
  return (
    <div className={classNames([style.navWrapper, className])}>
      <LocaleLink
        isNav
        activeClassName={style.navLinkCurrent}
        exact
        to={getRouteTerms()}
        className={classNames(['bp3-button', 'bp3-minimal', 'bp3-intent-primary', style.navLink])}
      >
        <FormattedMessage {...commonMessages.navTerms} />
      </LocaleLink>

      <LocaleLink
        isNav
        activeClassName={style.navLinkCurrent}
        exact
        to={getRoutePrivacy()}
        className={classNames(['bp3-button', 'bp3-minimal', 'bp3-intent-primary', style.navLink])}
      >
        <FormattedMessage {...commonMessages.navPrivacy} />
      </LocaleLink>

      <LocaleLink
        isNav
        activeClassName={style.navLinkCurrent}
        exact
        to={getRouteAbout()}
        className={classNames(['bp3-button', 'bp3-minimal', 'bp3-intent-primary', style.navLink])}
      >
        <FormattedMessage {...commonMessages.navAbout} />
      </LocaleLink>

      <span className="bp3-text-muted">
        © <FormattedMessage {...commonMessages.title} />
      </span>

      {secondaryCopyright && <span className="bp3-text-muted">{secondaryCopyright}</span>}
    </div>
  );
};

export const Footer: React.FC<Props> = ({ className, secondaryCopyright }) => {
  return (
    <footer className={classNames([style.wrapper, className])}>
      <Container top bottom className={style.container}>
        <section className={style.part}>
          <LocaleLink
            isNav
            activeClassName={style.navLinkCurrent}
            exact
            to={getRouteTerms()}
            className={classNames(['bp3-button', 'bp3-minimal', 'bp3-intent-primary', style.navLink])}
          >
            <FormattedMessage {...commonMessages.navTerms} />
          </LocaleLink>

          <LocaleLink
            isNav
            activeClassName={style.navLinkCurrent}
            exact
            to={getRoutePrivacy()}
            className={classNames(['bp3-button', 'bp3-minimal', 'bp3-intent-primary', style.navLink])}
          >
            <FormattedMessage {...commonMessages.navPrivacy} />
          </LocaleLink>

          <LocaleLink
            isNav
            activeClassName={style.navLinkCurrent}
            exact
            to={getRouteAbout()}
            className={classNames(['bp3-button', 'bp3-minimal', 'bp3-intent-primary', style.navLink])}
          >
            <FormattedMessage {...commonMessages.navAbout} />
          </LocaleLink>
        </section>

        <section className={classNames(style.part, style.textSecondary)}>
          <span>
            © <FormattedMessage {...commonMessages.title} />
          </span>
          <br />
          {secondaryCopyright && <small>{secondaryCopyright}</small>}
        </section>
      </Container>
    </footer>
  );
};
