import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from 'react-router';
import { useMutation } from '@apollo/client';
import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { GroupDataInput } from '../../../../models/Group';

import { CREATE_GROUP, GET_USER_GROUPS } from '../../../../api/queries';

import { commonMessages } from '../../../../utils/commonMessages';
import { MainToaster } from '../../../../utils/toaster';
import { getRouteGroupList } from '../../../../utils/navRoutes';
import { LocaleContext } from '../../../../contexts/LocaleContext';

import { Page } from '../../../Page';
import { Container, Size as ContainerSize } from '../../../Container';
import { GroupForm } from '../../../GroupForm';
import { Title } from '../../../Title';
import { BackLink } from '../../../BackLink';

export const GroupNew: React.FC = () => {
  const { locale } = React.useContext(LocaleContext);
  const { formatMessage } = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createGroup, { data, error }] = useMutation(CREATE_GROUP, { refetchQueries: [GET_USER_GROUPS] });

  const routePrefix = `/${locale}`;
  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);

  const handleSubmit = async (rawInput: GroupDataInput) => {
    setIsSubmitting(true);
    const input: Partial<GroupDataInput> = { ...rawInput };
    delete input.members; // TODO: remove when handling contacts
    await createGroup({ variables: { input } });
  };

  useEffect(() => {
    if (error) {
      setIsSubmitting(false);
      MainToaster.show({
        message: `${formatMessage(commonMessages.genericError)}: ${error.message}`,
        icon: IconNames.DISABLE,
        intent: Intent.DANGER,
      });
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setIsSubmitting(false);
      MainToaster.show({
        message: `${formatMessage(commonMessages.success)}`,
        icon: IconNames.TICK,
        intent: Intent.SUCCESS,
      });
      setShouldRedirectToList(true);
    }
  }, [data]);

  if (shouldRedirectToList) {
    return <Redirect to={`${routePrefix}/${getRouteGroupList()}`} />;
  }

  return (
    <Page size={ContainerSize.SM}>
      <Container size={ContainerSize.SM} top>
        <Title
          condensed
          center
          backLink={<BackLink to={getRouteGroupList()} messageDescriptor={commonMessages.actionCancel} />}
        >
          <FormattedMessage {...commonMessages.groupAdd} />
        </Title>
      </Container>

      <Container size={ContainerSize.SM} left={false} right={false}>
        <GroupForm onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </Container>
    </Page>
  );
};
