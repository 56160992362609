import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  e404Title: {
    id: 'errorPage404Title',
    defaultMessage: 'Page not found',
  },
  e404Description: {
    id: 'errorPage404Description',
    defaultMessage: "The page you're looking for doesn't seem to be here, sorry!",
  },
  e404Hint: {
    id: 'errorPage404Hint',
    defaultMessage: 'Try browsing for something else from ',
  },
  e404HomeLabel: {
    id: 'errorPage404HomeLabel',
    defaultMessage: 'the Home Page',
  },
  eUnknownTitle: {
    id: 'errorPageUnknownTitle',
    defaultMessage: 'Technical difficulties',
  },
  eUnknownDescription: {
    id: 'errorPageUnknownDescription',
    defaultMessage: 'We are doing our best to get back online ASAP.',
  },
});
