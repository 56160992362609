import classNames from 'classnames';
import React from 'react';

import style from './style.module.scss';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tagName?: React.ElementType<any>;
  className?: string;
  center?: boolean;
  condensed?: boolean;
  wrapped?: boolean;
  secondaryContent?: React.ReactNode;
  backLink?: React.ReactNode;
};
export const Title: React.FC<Props> = ({
  children,
  tagName = 'h1',
  className = '',
  center = false,
  condensed = false,
  wrapped = false,
  secondaryContent = null,
  backLink = null,
}) => {
  const Component = tagName;
  return (
    <>
      {backLink && <div className={style.backLink}>{backLink}</div>}

      <Component
        className={classNames([
          className,
          style.title,
          {
            [style['title--center']]: center,
            [style['title--condensed']]: condensed,
            [style['title--wrapped']]: wrapped,
          },
        ])}
      >
        <span className={style.primaryContent}>{children}</span>

        {secondaryContent && <div className={style.secondaryContent}>{secondaryContent}</div>}
      </Component>
    </>
  );
};
