import { Button, Intent, Radio, RadioGroup } from '@blueprintjs/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { intersectionWith, startCase, uniq, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Control, Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import { CurrencyCode, CurrencyPair, DEFAULT_CURRENCY_CODE, ExchangeRate } from '../../models/Currency';
import { isExistingInstance } from '../../models/ExistingInstance';
import { Group, GroupDataInput, GroupDataInfo } from '../../models/Group';
import { GroupMember, GroupMemberId } from '../../models/GroupMember';
import { GroupBalanceCurrencyKind } from '../../models/Group';
import { Expense } from '../../models/Expense';
import { Payment } from '../../models/Payment';

import { AuthContext } from '../../contexts/AuthContext';
import { ExchangeRatesContext } from '../../contexts/ExchangeRatesContext';

import yup from '../../utils/yup';
import { commonMessages } from '../../utils/commonMessages';
import { getMessage } from '../../utils/getMessages';

import { IndicatorLoading } from '../IndicatorLoading';
import { Title } from '../Title';
import { Container } from '../Container';
import { StickyFooter } from '../StickyFooter';
import { Field } from '../_form/Field';
import { MemberSelection, MemberSelectListSimple } from '../_form/MemberSelect/ListSimple';
import { CurrencySelect } from '../_form/CurrencySelect';
import { AmountInput } from '../_form/AmountInput';

import { messages } from './messages';
import style from './style.module.scss';

export type Props = {
  onSubmit: (formData: GroupDataInput) => void;
  initialData?: GroupDataInput | Group;
  withMembersField?: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
};

const schema = yup.object().shape({
  displayName: yup.string().required(),
  members: yup
    .array()
    .of(
      yup.object().shape({
        displayName: yup.string().required(),
        id: yup.string().required(),
      })
    )
    .min(1),
  balanceCurrencyKind: yup.string().required(),
  selectedSingleCurrency: yup.string().when('balanceCurrencyKind', {
    is: GroupBalanceCurrencyKind.SINGLE,
    then: yup.string().required(),
  }),
  exchangeRates: yup.array().of(
    yup.object().shape({
      baseCode: yup.string().required(),
      quoteCode: yup.string().required(),
      value: yup
        .string()
        .required()
        .test('greater than 0', 'Has to be greater than 0', val => parseFloat(val || '') > 0),
    })
  ),
});

type Values = GroupDataInput;

const getInitialDataExpenses = (initialData: Props['initialData']): Expense[] => {
  if (initialData && 'expenses' in initialData) {
    return initialData.expenses;
  }
  return [];
};
const getInitialDataPayments = (initialData: Props['initialData']): Payment[] => {
  if (initialData && 'payments' in initialData) {
    return initialData.payments;
  }
  return [];
};

export const GroupForm: React.FC<Props> = ({
  onSubmit,
  initialData,
  withMembersField = true,
  isSubmitting = false,
  disabled = false,
}) => {
  const { formatMessage } = useIntl();

  const { profile } = useContext(AuthContext).user!;
  const { exchangeRatesByCurrency, getExchangeRate, pipDenominator } = useContext(ExchangeRatesContext);

  const [selectedMembers, setSelectedMembers] = useState<GroupMember[]>([profile]);

  const usedCurrencies: CurrencyCode[] = uniq([
    ...getInitialDataExpenses(initialData).map(({ currencyCode }) => currencyCode),
    ...getInitialDataPayments(initialData).map(({ currencyCode }) => currencyCode),
  ]);
  const initialSettlementSingleCurrencyCode =
    initialData?.selectedSingleCurrency || usedCurrencies?.[0] || DEFAULT_CURRENCY_CODE;

  const getInitialExchangeRates = (selectedCode: CurrencyCode) =>
    usedCurrencies.reduce((acc, code) => {
      if (code !== selectedCode) {
        const initialExchangeRate = initialData?.exchangeRates?.find(
          ({ baseCode, quoteCode }) => quoteCode === selectedCode && baseCode === code
        );
        if (initialExchangeRate) {
          acc.push(initialExchangeRate);
        } else {
          const exchangeRate = getExchangeRate({ baseCode: code, quoteCode: selectedCode });
          if (exchangeRate) {
            acc.push(exchangeRate);
          }
        }
      }
      return acc;
    }, [] as ExchangeRate[]);

  const emptyInitialInfoValues: GroupDataInfo = {
    displayName: '',
    description: '',
    balanceCurrencyKind: GroupBalanceCurrencyKind.ORIGINAL,
    selectedSingleCurrency: initialSettlementSingleCurrencyCode,
    exchangeRates: [],
  };

  const initialDataFilled = {
    ...emptyInitialInfoValues,
    ...(initialData || { members: [profile] }), //add current user when creating group
    ...(initialData?.exchangeRates
      ? {
          exchangeRates: getInitialExchangeRates(initialSettlementSingleCurrencyCode),
        }
      : {}), //adapt exchange rates to allCurrencies order
  };
  const isGroupNew = !isExistingInstance(initialDataFilled);

  const {
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm<Values>({
    resolver: yupResolver(schema),
    defaultValues: initialDataFilled,
    mode: 'onChange',
  });

  const { fields, update } = useFieldArray({
    control,
    name: 'exchangeRates',
  });

  const onFormSubmit: SubmitHandler<Values> = data => {
    const sendableData = {
      ...data,
      ...(data.members?.reduce(
        (acc, member) => {
          const { id, displayName } = member;
          id === displayName
            ? (acc.memberEmails = [...acc.memberEmails, id])
            : (acc.members = [...acc.members, member]);
          return acc;
        },
        { members: [] as GroupMember[], memberEmails: [] as string[] }
      ) || {}),
      ...(data.exchangeRates
        ? {
            exchangeRates: data.exchangeRates.map(({ value, ...rest }) => ({
              value: parseInt((value as unknown) as string),
              ...rest,
            })),
          }
        : {}),
    };
    if (!withMembersField) {
      //prevent update to empty
      delete sendableData.members;
      delete sendableData.memberEmails;
    }
    if (data.balanceCurrencyKind === GroupBalanceCurrencyKind.ORIGINAL) {
      //prevent update to defaults
      delete sendableData.selectedSingleCurrency;
      delete sendableData.exchangeRates;
    }

    onSubmit(sendableData);
  };

  useEffect(() => {
    setValue('members', selectedMembers, { shouldValidate: true });
  }, [selectedMembers]);

  const allMembers: GroupMember[] = uniqBy([profile, ...selectedMembers], 'id');

  const handleMembersAdd = ({ selected, created }: MemberSelection) => {
    const newMembers: GroupMember[] = created.map(item => ({ id: item, displayName: item }));
    const pickedMembers: GroupMember[] = intersectionWith(allMembers, selected, (member, id) => id === member.id);
    setSelectedMembers([...selectedMembers, ...pickedMembers, ...newMembers]);
  };

  const handleMemberRemove = (memberId: GroupMemberId) => {
    setSelectedMembers(selectedMembers.filter(({ id }) => id !== memberId));
  };

  const { balanceCurrencyKind, selectedSingleCurrency } = watch();

  useEffect(() => {
    setValue('exchangeRates', getInitialExchangeRates(selectedSingleCurrency!));
  }, [selectedSingleCurrency]);

  const updateExchangeRate = (value: number, currencyPair: CurrencyPair, index: number) => {
    update(index, { ...currencyPair, value });
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onFormSubmit)}>
      <Container>
        <Field
          disabled={disabled}
          name="displayName"
          isRequired
          control={(control as unknown) as Control<Record<string, unknown>>}
          autoFocus
        />

        <Field
          disabled={disabled}
          name="description"
          control={(control as unknown) as Control<Record<string, unknown>>}
        />

        <Container top left={false} right={false}>
          <Title tagName="h2">
            <FormattedMessage {...commonMessages.settlementCurrency} />
          </Title>

          <section>
            <Controller
              render={({ field }) => {
                return (
                  <RadioGroup selectedValue={field.value} {...field}>
                    {[GroupBalanceCurrencyKind.ORIGINAL, GroupBalanceCurrencyKind.SINGLE].map(kind => (
                      <Radio
                        key={kind}
                        label={formatMessage(
                          getMessage(`settleCurrencyKindLabel${startCase(kind.toLowerCase())}`, messages)
                        )}
                        value={kind}
                      />
                    ))}
                  </RadioGroup>
                );
              }}
              name="balanceCurrencyKind"
              control={control}
            />
          </section>
          {balanceCurrencyKind === GroupBalanceCurrencyKind.SINGLE && (
            <section className={style.singleCurrencySettings}>
              <section className={style.singleCurrencySettings__primary}>
                <Title tagName="h5" condensed>
                  <FormattedMessage {...messages.selectedCurrencyLabel} />:{' '}
                </Title>

                <Controller
                  render={({ field }) => {
                    return (
                      <div className={style.row}>
                        <CurrencySelect
                          onChange={currencyCode => {
                            field.onChange(currencyCode);
                          }}
                          initialCurrencyCode={initialSettlementSingleCurrencyCode}
                        />
                      </div>
                    );
                  }}
                  name="selectedSingleCurrency"
                  control={control}
                />
              </section>

              {fields.length > 0 && (
                <section className={style.singleCurrencySettings__secondary}>
                  <Title tagName="h5" condensed>
                    <FormattedMessage {...messages.exchangeRatesLabel} />:
                  </Title>

                  {!selectedSingleCurrency || !Object.keys(exchangeRatesByCurrency).length ? (
                    <IndicatorLoading />
                  ) : (
                    fields.map((field, i) => {
                      const { baseCode, quoteCode, value } = field;
                      const systemExchangeRate = getExchangeRate({ baseCode, quoteCode });
                      return (
                        <div
                          key={`${baseCode}-${quoteCode}`}
                          className={classNames('bp3-input-group', style.exchangeRateRow, style.row, {
                            'bp3-intent-danger': !!errors?.exchangeRates?.[i],
                          })}
                        >
                          <span>1 {baseCode} =</span>
                          <>
                            <AmountInput
                              value={value / pipDenominator}
                              disabled={disabled}
                              className={style.exchangeRateRow__input}
                              onChange={val =>
                                updateExchangeRate(Math.round(val * pipDenominator), { baseCode, quoteCode }, i)
                              }
                              currencyPrecision={4}
                              instanceId={`${baseCode}-${quoteCode}`}
                              intent={value <= 0 ? Intent.DANGER : Intent.NONE}
                            />

                            <span>{quoteCode}</span>

                            {systemExchangeRate && (
                              <Button
                                small
                                minimal
                                className={classNames(style.exchangeRateRow__default, {
                                  [style['exchangeRateRow__default--hidden']]: systemExchangeRate.value === value,
                                })}
                                onClick={() => updateExchangeRate(systemExchangeRate.value, { baseCode, quoteCode }, i)}
                              >
                                <FormattedMessage {...messages.exchangeRateSuggestedLabel} />:{' '}
                                {systemExchangeRate.value / pipDenominator}
                              </Button>
                            )}
                          </>
                        </div>
                      );
                    })
                  )}
                </section>
              )}
            </section>
          )}
        </Container>

        {withMembersField && (
          <Container top left={false} right={false}>
            <Title tagName="h2">
              <FormattedMessage {...commonMessages.members} />
            </Title>
            <MemberSelectListSimple
              disabled={disabled}
              members={allMembers}
              initialMemberIds={selectedMembers.map(({ id }) => id)}
              onAdd={handleMembersAdd}
              onDelete={handleMemberRemove}
              preventRemoveWhen={isGroupNew ? id => id === profile.id : undefined}
              shouldConfirmDelete={!isGroupNew}
            />
          </Container>
        )}
      </Container>

      <StickyFooter>
        <Button
          large
          type="submit"
          intent={Intent.SUCCESS}
          disabled={!isValid || isSubmitting}
          loading={isSubmitting}
          text={<FormattedMessage {...(isGroupNew ? commonMessages.groupAdd : commonMessages.actionSave)} />}
        />
      </StickyFooter>
    </form>
  );
};
