import { Button, ButtonProps } from '@blueprintjs/core';
import classNames from 'classnames';
import React from 'react';

import style from './style.module.scss';

type MainButtonProps = ButtonProps;

export const MainButton: React.FC<MainButtonProps> = ({ className, ...other }) => {
  return <Button large {...other} className={classNames(className, style.wrapper)} />;
};
