import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { getMessage } from '../../../../../utils/getMessages';
import { getRouteSignUp } from '../../../../../utils/navRoutes';

import { Container, Size } from '../../../../Container';
import { LocaleLink } from '../../../../LocaleLink';
import { ReactComponent as IllustratioHero } from '../../../../../assets/hero.svg';
import { ReactComponent as IllustrationTravel } from '../../../../../assets/travel.svg';
import { ReactComponent as IllustrationMultimedia } from '../../../../../assets/multimedia.svg';
import { ReactComponent as IllustrationEating } from '../../../../../assets/eating.svg';
import { ReactComponent as IllustrationSport } from '../../../../../assets/sport.svg';

import { messages } from '../../messages';

import style from './style.module.scss';

type Props = {
  transparent?: boolean;
  withCta?: boolean;
};

const USECASES = [
  { name: 'Split', Illustration: IllustrationEating },
  { name: 'Accumulate', Illustration: IllustrationMultimedia },
  { name: 'InRound', Illustration: IllustrationSport },
  { name: 'Summary', Illustration: IllustrationTravel },
];

export const LandingContent: React.FC<Props> = () => {
  return (
    <main className={style.page}>
      <section className={style.hero}>
        <Container className={style.hero__content} size={Size.SM}>
          <h1 className={style.hero__text}>
            <FormattedMessage {...messages.heroTitle} values={{ br: <br /> }} />
          </h1>

          <IllustratioHero className={style.hero__illustration} />

          <LocaleLink
            isNav
            activeClassName={style.navLinkCurrent}
            to={getRouteSignUp()}
            className={classNames(['bp3-button', 'bp3-intent-primary', 'bp3-large', style.hero__action])}
          >
            <FormattedMessage {...messages.heroCta} />
          </LocaleLink>
        </Container>
      </section>
      <div>
        {USECASES.map(({ name, Illustration }, i) => {
          const IllustrationComponent = Illustration as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
          return (
            <section
              key={name}
              className={classNames(style.depiction, { [style['depiction--inversed']]: i % 2 === 1 })}
            >
              <Container className={style.depiction__content} size={Size.SM}>
                <IllustrationComponent className={style.depiction__illustration} />

                <div className={style.depiction__text}>
                  <h2 className={style.depiction__title}>
                    <FormattedMessage {...getMessage(`usecase${name}Title`, messages)} />
                  </h2>
                  <p>
                    <FormattedMessage
                      {...getMessage(`usecase${name}Description`, messages)}
                      values={{
                        br: <br />,
                        buzzword: (
                          <FormattedMessage tagName="strong" {...getMessage(`usecase${name}Buzzword`, messages)} />
                        ),
                      }}
                    />
                  </p>
                </div>
              </Container>
            </section>
          );
        })}
      </div>
      <div className={style.cta}>
        <Container size={Size.SM}>
          <p className={style.cta__text}>
            <FormattedMessage {...messages.ctaMessage} />
          </p>

          <LocaleLink
            isNav
            activeClassName={style.navLinkCurrent}
            to={getRouteSignUp()}
            className={classNames(['bp3-button', 'bp3-intent-primary', 'bp3-large', style.cta__action])}
          >
            <FormattedMessage {...messages.ctaAction} />
          </LocaleLink>
        </Container>
      </div>
    </main>
  );
};

export const LandingContentMemoized = React.memo(LandingContent);
