import React, {
  ChangeEvent,
  ComponentType,
  FC,
  ImgHTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, FormGroup, FormGroupProps, Icon, InputGroupProps, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { commonMessages } from '../../../utils/commonMessages';

import { ICON_SIZES, Size } from '../../Avatar';

import style from './style.module.scss';

export type Props = InputGroupProps & {
  renderPreviewAs?: ComponentType<ImgHTMLAttributes<HTMLImageElement>>;
  initialImageURL?: string;
  formGroupProps?: FormGroupProps;
};
type FileValue = string | ArrayBuffer | null;

export const ImageInput: FC<Props> = ({
  intent = Intent.NONE,
  placeholder = '',
  renderPreviewAs = ImageInputPlaceholder,
  initialImageURL,
  formGroupProps,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  const [preview, setPreview] = useState<ReactNode>(null);
  const [inputText, setInputText] = useState<string | undefined>(placeholder);
  const [previewUrl, setPreviewUrl] = useState<FileValue>(null);
  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const PreviewComponent = renderPreviewAs;

  const callOnChange = (value: File | null) => {
    onChange && (onChange as (param: unknown) => unknown)(value);
  };

  const onClearClick = () => {
    setFile(null);
    callOnChange(file);
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setFile(file);
      };
      reader.readAsDataURL(file);

      callOnChange(file);
    }
  };

  const showPrompt = useCallback(
    (e: React.KeyboardEvent<HTMLSpanElement>) => {
      switch (e.key.toLowerCase()) {
        case 'enter':
        case 'space': {
          if (inputRef?.current) {
            inputRef.current!.click();
          }
          break;
        }
      }
    },
    [inputRef]
  );

  useEffect(() => {
    if (file && previewUrl) {
      setInputText(file.name);
      setPreview(
        <div className={style.previewWrapper}>
          <PreviewComponent
            src={typeof previewUrl === 'string' && previewUrl.startsWith('data:image') ? previewUrl : undefined}
            alt="Preview"
            className={style.preview}
          />
        </div>
      );
    } else {
      setPreview(null);
      setInputText('');
    }
  }, [file]);

  return (
    <div className={classNames([style.wrapper])}>
      {preview || (
        <div className={style.previewWrapper}>
          <PreviewComponent src={initialImageURL} alt="initial image" className={style.preview} />
        </div>
      )}

      <FormGroup {...formGroupProps} className={style.inputWrapper}>
        <label
          className={classNames(style.pseudoInput, [
            'bp3-file-input',
            { 'bp3-file-input-has-selection': !!previewUrl },
          ])}
        >
          <input type="file" onChange={handleImageChange} className={style.input} ref={inputRef} />
          <span
            tabIndex={0}
            onKeyUp={showPrompt}
            className={classNames(['bp3-file-upload-input', `bp3-intent-${intent}`, style.inputText])}
            data-button-text={formatMessage(commonMessages.fileInputBrowse)}
          >
            {inputText}
          </span>
        </label>

        <Button onClick={onClearClick} minimal icon={IconNames.CROSS} />
      </FormGroup>
    </div>
  );
};

type ImageInputPlaceholderProps = {
  src: string | undefined;
};

const ImageInputPlaceholder = ({ src }: ImageInputPlaceholderProps) =>
  src ? (
    <img src={src} className={style.preview} />
  ) : (
    <div className={style.placeholder}>
      <Icon icon="mountain" iconSize={ICON_SIZES[Size.DEFAULT] / 0.8} className={style.placeholderIcon} />
    </div>
  );
