import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addMember: {
    id: 'memberAmountsArrayAddMember',
    defaultMessage: 'Add member',
  },
  noMembersTitle: {
    id: 'memberAmountsArrayNoMembersTitle',
    defaultMessage: 'No members selected',
  },
  noMembersDescription: {
    id: 'memberAmountsArrayNoMembersDescription',
    defaultMessage: 'Add someone below.',
  },
  addEveryone: {
    id: 'memberAmountsArrayAddEveryone',
    defaultMessage: 'Add everyone',
  },
  removeEveryone: {
    id: 'memberAmountsArrayRemoveEveryone',
    defaultMessage: 'Remove everyone',
  },
});
