import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  splitEven: {
    id: 'splitKindSelectSplitEven',
    defaultMessage: 'evenly',
  },
  splitValue: {
    id: 'splitKindSelectSplitValue',
    defaultMessage: 'by value',
  },
  emptyLabel: {
    id: 'splitKindSelectEmptyLabel',
    defaultMessage: 'Select split kind',
  },
});
