import { companyData, mainTosUrl, serviceName, siteUrl } from '../../../../utils/contactData';

export const en = `
# Terms of service

1. The Service ${serviceName} is provided under the address ${siteUrl}.

1. The Service is operated by the Operator:

    ${companyData.name}
    ${companyData.addressStreet},
    ${companyData.addressZipCode} ${companyData.addressCity},
    ${companyData.addressCountry},
    KRS ${companyData.krs},
    NIP ${companyData.nip},
    REGON ${companyData.regon}

1. The Service is subject to jurisdiction of the seat of the Company (Poland).
1. Full and applicable Terms and conditions are available in Polish under: [${siteUrl}${mainTosUrl}](${mainTosUrl}).
`;
