import React from 'react';
import { BrowserRouter as Router, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import { AuthProvider } from '../../contexts/AuthContext';
import { LocaleProvider } from '../../contexts/LocaleContext';
import { routeTransitionProps } from '../../utils/visual';
import {
  getRouteAbout,
  getRouteChangePassword,
  getRouteGroupList,
  getRouteInviteConfirm,
  getRoutePrivacy,
  getRouteResetPassword,
  getRouteSettings,
  getRouteSignIn,
  getRouteSignUp,
  getRouteSignUpConfirm,
  getRouteTerms,
} from '../../utils/navRoutes';
import { AvailableLocale, getInitialLocale, isLocale, locales } from '../../lang';

import { Layout } from '../Layout';
import { Home } from '../_pages/Home';
import { SignIn } from '../_pages/_auth/SignIn';
import { SignUp } from '../_pages/_auth/SignUp';
import { SignUpConfirm } from '../_pages/_auth/SignUpConfirm';
import { InviteConfirm } from '../_pages/_auth/InviteConfirm';
import { ResetPassword } from '../_pages/_auth/ResetPassword';
import { ChangePassword } from '../_pages/_auth/ChangePassword';
import { Settings } from '../_pages/Settings';
import { About } from '../_pages/About';
import { Terms } from '../_pages/Terms';
import { Privacy } from '../_pages/Privacy';
import { Groups } from '../_pages/Groups';
import { ErrorBoundary } from '../ErrorBoundary';

type LocalizedRouteParams = {
  lang: AvailableLocale;
};

const Content: React.FC<LocalizedRouteParams> = ({ lang }) => {
  const routePrefix = `/${lang}`;
  const { pathname } = useLocation();

  return (
    <AnimatedSwitch {...routeTransitionProps}>
      <Route exact path={routePrefix} component={Home} />

      <Route
        render={() => (
          <Layout>
            <ErrorBoundary path={pathname}>
              <AnimatedSwitch {...routeTransitionProps}>
                <Route exact path={routePrefix} component={Home} />

                <Route path={`${routePrefix}/${getRouteSignIn()}`} component={SignIn} />

                <Route path={`${routePrefix}/${getRouteSignUp()}`} component={SignUp} />
                <Route path={`${routePrefix}/${getRouteSignUpConfirm()}`} component={SignUpConfirm} />
                <Route path={`${routePrefix}/${getRouteInviteConfirm()}`} component={InviteConfirm} />
                <Route path={`${routePrefix}/${getRouteResetPassword()}`} component={ResetPassword} />
                <Route path={`${routePrefix}/${getRouteChangePassword()}`} component={ChangePassword} />

                <Route path={`${routePrefix}/${getRouteSettings()}`} component={Settings} />

                <Route path={`${routePrefix}/${getRouteAbout()}`} component={About} />
                <Route path={`${routePrefix}/${getRouteTerms()}`} component={Terms} />
                <Route path={`${routePrefix}/${getRoutePrivacy()}`} component={Privacy} />

                <Route path={`${routePrefix}/${getRouteGroupList()}`} component={Groups} />

                <Redirect to={routePrefix} />
              </AnimatedSwitch>
            </ErrorBoundary>
          </Layout>
        )}
      />
    </AnimatedSwitch>
  );
};

const LocalizedRoute: React.FC<LocalizedRouteParams> = ({ lang }) => {
  if (!isLocale(lang)) {
    return <Redirect to={getInitialLocale()} />;
  }

  return (
    <LocaleProvider lang={lang}>
      <Content lang={lang} />
    </LocaleProvider>
  );
};

export const App: React.FC = () => (
  <Router>
    <AuthProvider>
      <AnimatedSwitch {...routeTransitionProps}>
        {locales.map(lang => (
          <Route key={lang} path={`/${lang}`} render={() => <LocalizedRoute lang={lang} />} />
        ))}

        <Redirect to={getInitialLocale()} />
      </AnimatedSwitch>
    </AuthProvider>
  </Router>
);
