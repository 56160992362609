import { intersectionBy, unionBy } from 'lodash';

import { FilterValue, FilterConfig, FilterConjunctive, FilterValueSingle } from './_types';

export function applyFilters<T>(
  items: T[],
  filters: FilterConfig[],
  filteringFunctionFactory: (filterConfig: FilterConfig) => (item: T, value: FilterValueSingle) => boolean
): T[] {
  if (!filters.length) {
    return items;
  }

  const resultsPerFilter = filters.map(filter => {
    const testFn = filteringFunctionFactory(filter);

    if (Array.isArray(filter.value)) {
      let results: T[] = [];
      switch (filter.optionsConjunctive) {
        case FilterConjunctive.OR: {
          break;
        }
        case FilterConjunctive.AND:
        default: {
          results = [...items];
          break;
        }
      }

      filter.value.forEach(v => {
        switch (filter.optionsConjunctive) {
          case FilterConjunctive.OR: {
            results = unionBy(
              results,
              items.filter(item => testFn(item, v)),
              'id'
            );
            break;
          }
          case FilterConjunctive.AND:
          default: {
            results = intersectionBy(
              results,
              items.filter(item => testFn(item, v)),
              'id'
            );
            break;
          }
        }
      });

      return results;
    } else {
      return filter.value ? items.filter(item => testFn(item, filter.value as FilterValueSingle)) : [...items];
    }
  });

  return intersectionBy(...resultsPerFilter, 'id');
}

export const isValueTruthy = (value: FilterValue | undefined): boolean => {
  if (value === undefined) {
    return false;
  }
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  if (typeof value === 'object') {
    if (value === null) {
      return false;
    }
    const keys = Object.keys(value);
    if (keys.length < 1) {
      return false;
    }
    if (keys.every(key => (value as Record<string, unknown>)[key] === undefined)) {
      return false;
    }
  }
  return true;
};

export const getActiveFilters = (filters: FilterConfig[]): FilterConfig[] =>
  filters.filter(({ value }) => isValueTruthy(value));
