import classNames from 'classnames';
import React from 'react';

import style from './style.module.scss';

type StickyHeaderProps = { className?: string; classNameContent?: string };

export const StickyHeader: React.FC<StickyHeaderProps> = ({ className, classNameContent, children }) => {
  return (
    <header className={classNames(className, style.wrapper)}>
      <div className={classNames(classNameContent, style.content)}>{children}</div>
    </header>
  );
};
