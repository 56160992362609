import { Button, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { find, round } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TransitionGroup } from 'react-transition-group';

import { GroupMember, GroupMemberId } from '../../../models/GroupMember';
import { MemberAmount } from '../../../models/MemberAmount';
import { TransitionGroupItem } from '../../TransitionGroupItem';

import { MemberAmountInputRow } from '../MemberAmountInputRow';
import { MemberSelectSingle } from '../MemberSelect/Single';

import { messages } from './messages';
import style from './style.module.scss';

type Props = {
  members: GroupMember[];
  amount: number;
  onChange: (memberAmounts: MemberAmount[]) => void;
  precision?: number;
  values: MemberAmount[];
  unit: string;
  disabled?: boolean;
};

export const MemberAmountsArray: React.FC<Props> = ({
  members,
  amount,
  onChange,
  values,
  unit,
  precision = 2,
  disabled = false,
}) => {
  const [memberAmounts, setMemberAmounts] = useState<MemberAmount[]>(values);

  const total = memberAmounts.reduce((acc, { amount }) => round(acc + amount, precision), 0);
  const delta = round(total - amount, precision);

  useEffect(() => {
    onChange(memberAmounts);
  }, [memberAmounts]);

  useEffect(() => {
    setMemberAmounts(values);
  }, [values]);

  const onMemberAdd = (memberId: GroupMemberId) => {
    const member = find(members, ['id', memberId]) as GroupMember;
    setMemberAmounts([...memberAmounts, { member, amount: Math.max(-delta, 0) }]);
  };

  const onMemberDelete = (memberId: GroupMemberId) => {
    setMemberAmounts(memberAmounts.filter(({ member: { id } }) => id !== memberId));
  };

  const onMemberAmountChange = (memberId: GroupMemberId, memberAmount: number) => {
    const memberIndex = memberAmounts.findIndex(({ member: { id } }) => id === memberId);
    const newValue = { ...memberAmounts[memberIndex], amount: memberAmount };
    const newValues = [...memberAmounts];
    newValues.splice(memberIndex, 1, newValue);
    setMemberAmounts(newValues);
  };

  const skippedMembers = members.filter(
    ({ id }) => !memberAmounts.some(({ member: { id: existingId } }) => id === existingId)
  );

  const onAddEveryoneClick = () => {
    setMemberAmounts([
      ...memberAmounts,
      ...skippedMembers.map(member => ({ amount: round(-delta / skippedMembers.length, precision), member })),
    ]);
  };

  const onRemoveEveryoneClick = () => {
    setMemberAmounts([]);
  };

  return (
    <>
      <section>
        {memberAmounts.length > 0 ? (
          <TransitionGroup className={style.list}>
            {memberAmounts.map(({ member, amount: memberAmount }) => {
              return (
                <TransitionGroupItem key={member.id}>
                  <MemberAmountInputRow
                    disabled={disabled}
                    key={`${member.id}-${memberAmount}`}
                    member={member}
                    initialAmount={memberAmount}
                    onChange={onMemberAmountChange}
                    onDelete={onMemberDelete}
                    currencyPrecision={precision}
                    unit={unit}
                  />
                </TransitionGroupItem>
              );
            })}
          </TransitionGroup>
        ) : (
          <NonIdealState
            title={<FormattedMessage {...messages.noMembersTitle} />}
            description={
              <span>
                <FormattedMessage {...messages.noMembersDescription} />
              </span>
            }
          />
        )}
      </section>

      <div className={style.actions}>
        {skippedMembers.length > 0 ? (
          <div className={style.actions__add}>
            <Button icon={IconNames.PLUS} onClick={onAddEveryoneClick}>
              <FormattedMessage {...messages.addEveryone} />
            </Button>

            <MemberSelectSingle
              disabled={disabled}
              members={skippedMembers}
              onChange={onMemberAdd}
              label={<FormattedMessage {...messages.addMember} />}
            />
          </div>
        ) : (
          <span />
        )}

        {memberAmounts.length > 0 ? (
          <Button icon={IconNames.CROSS} onClick={onRemoveEveryoneClick}>
            <FormattedMessage {...messages.removeEveryone} />
          </Button>
        ) : (
          <span />
        )}
      </div>
    </>
  );
};
