import { ExistingInstance } from './ExistingInstance';
import { Expense } from './Expense';
import { Payment } from './Payment';
import { GroupMember } from './GroupMember';
import { CurrencyCode, ExchangeRate } from './Currency';

export type GroupId = string;

export type GroupData = {
  displayName: string;
  description?: string;
  members: GroupMember[];
  expenses: Expense[];
  payments: Payment[];
};

export enum GroupBalanceCurrencyKind {
  ORIGINAL = 'ORIGINAL',
  SINGLE = 'SINGLE',
}

export type GroupBalanceConfig = {
  balanceCurrencyKind: GroupBalanceCurrencyKind;
  selectedSingleCurrency?: CurrencyCode;
  exchangeRates?: ExchangeRate[];
};

export type GroupDataMembers = Pick<GroupData, 'members'>;
export type GroupDataInfo = Pick<GroupData, 'displayName' | 'description'> & GroupBalanceConfig;
export type GroupDataInput = GroupDataInfo & Partial<GroupDataMembers> & { memberEmails?: string[] };

export type Group = GroupData & GroupBalanceConfig & ExistingInstance<GroupId>;

export type GroupLike = Group | GroupData;
