import React from 'react';

import { Navigation } from '../Navigation';
import { Footer } from '../Footer';

import style from './style.module.scss';

export const Layout: React.FC = ({ children }) => (
  <div className={style.wrapper}>
    <Navigation />

    <main className={style.container}>{children}</main>

    <Footer className="hidden-to-sm" />
  </div>
);
