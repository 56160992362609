import { MessageDescriptor } from 'react-intl';

import { commonMessages } from './commonMessages';

export const getMessage = (
  messageKey: string,
  messagesObject: Record<string, MessageDescriptor> = commonMessages
): MessageDescriptor => {
  return messagesObject[messageKey as keyof typeof messagesObject];
};
