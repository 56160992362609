import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm, SubmitHandler } from 'react-hook-form';

import { SignUpMutationVariables } from '../../../../models/_graphql';

import { SIGN_UP } from '../../../../api/queries';

import { commonMessages } from '../../../../utils/commonMessages';
import yup from '../../../../utils/yup';

import { RegistrationLayout } from '../_partials/RegistrationLayout';
import { ProfileForm } from '../../../ProfileForm';
import { Title } from '../../../Title';

import { messages } from './messages';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  displayName: yup.string().required(),
  password: yup.string().required().min(8),
});

const emptyInitialValues = {
  displayName: '',
  email: '',
  password: '',
};

export const SignUp: React.FC = () => {
  const {
    formState: { isDirty },
    reset,
  } = useForm<SignUpMutationVariables>({
    resolver: yupResolver(schema),
    defaultValues: emptyInitialValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [signUp, { data, loading }] = useMutation(SIGN_UP);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const isErrorVisible = hasError && !isDirty;
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (data) {
      if (data.signUp) {
        setHasSuccess(true);
      } else {
        setHasError(true);
      }
    }
  }, [data]);

  const onSubmit: SubmitHandler<Partial<SignUpMutationVariables>> = async input => {
    const variables = input as SignUpMutationVariables; // form validation ensures this
    setEmail(variables.email);
    reset(variables);

    try {
      await signUp({ variables });
    } catch (e) {
      setHasError(true);
    }
  };

  return (
    <RegistrationLayout>
      <Title center condensed>
        <FormattedMessage {...commonMessages.authSignUp} />
      </Title>

      {hasSuccess ? (
        <span className="text-center">
          <FormattedMessage {...messages.success} values={{ email }} />
        </span>
      ) : (
        <>
          {isErrorVisible && (
            <p className="text-intent-danger">
              <FormattedMessage {...commonMessages.genericError} />
            </p>
          )}

          <ProfileForm onSubmit={onSubmit} withEmailField withPasswordField withConsentField isSubmitting={loading} />
        </>
      )}
    </RegistrationLayout>
  );
};
