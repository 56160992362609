import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Expense, ExpenseInput, ExpenseInputData } from '../../../../models/Expense';

import { DELETE_EXPENSE, GET_GROUP, UPDATE_EXPENSE } from '../../../../api/queries';

import { LocaleContext } from '../../../../contexts/LocaleContext';
import { GroupContext } from '../../../../contexts/GroupContext';

import { commonMessages } from '../../../../utils/commonMessages';
import { MainToaster } from '../../../../utils/toaster';

import { Page } from '../../../Page';
import { Container, Size as ContainerSize } from '../../../Container';
import { Title } from '../../../Title';
import { ExpenseForm } from '../../../ExpenseForm';
import { getRouteExpenseList } from '../../../../utils/navRoutes';
import { DialogButton } from '../../../DialogButton';

import { BackLink } from '../../../BackLink';

import { scaleExpenseToCents } from '../../../../utils/scaleAmount';

import { messages } from './messages';

type Props = {
  expense: Expense;
};

export type RouteProps = {
  groupId: string;
  expenseId: string;
};

export const ExpenseEdit: React.FC<Props & RouteComponentProps<RouteProps>> = ({ expense }) => {
  const { locale } = useContext(LocaleContext);
  const { group } = useContext(GroupContext);

  const { formatMessage } = useIntl();

  const routePrefix = `/${locale}`;
  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingDelete, setIsSubmittingDelete] = useState(false);

  const [updateExpense] = useMutation(UPDATE_EXPENSE, { refetchQueries: [GET_GROUP] });
  const [deleteExpense] = useMutation(DELETE_EXPENSE, { refetchQueries: [GET_GROUP] });

  // convert Expense => ExpenseInput
  const initialData: ExpenseInput = {
    ...expense,
    splitAmounts: expense.splitAmounts.map(({ amount, member }) => ({
      amount,
      memberId: member.id,
    })),
    payerId: expense.payer.id,
  };

  const showSuccessToast = () => {
    MainToaster.show({
      message: `${formatMessage(commonMessages.success)}`,
      icon: IconNames.TICK,
      intent: Intent.SUCCESS,
    });
  };

  const showErrorToast = (error: Error) => {
    MainToaster.show({
      message: `${formatMessage(commonMessages.genericError)}: ${error.message}`,
      icon: IconNames.DISABLE,
      intent: Intent.DANGER,
    });
  };

  const handleSubmit = async (expenseInputData: ExpenseInputData) => {
    setIsSubmitting(true);
    try {
      const variables = {
        groupId: group.id,
        expenseId: expense.id,
        input: scaleExpenseToCents(expenseInputData),
      };
      await updateExpense({ variables });

      setShouldRedirectToList(true);
      showSuccessToast();
    } catch (e) {
      showErrorToast(e as Error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onExpenseDelete = async () => {
    try {
      setIsSubmittingDelete(true);
      await deleteExpense({ variables: { groupId: group.id, expenseId: expense.id } });
      setShouldRedirectToList(true);
      showSuccessToast();
    } catch (e) {
      showErrorToast(e as Error);
    } finally {
      setIsSubmittingDelete(false);
    }
  };

  if (shouldRedirectToList) {
    return <Redirect to={`${routePrefix}/${getRouteExpenseList({ groupId: group.id })}`} />;
  }

  return (
    <Page size={ContainerSize.SM}>
      <Container size={ContainerSize.SM} top bottom>
        <Title
          center
          wrapped
          condensed
          backLink={
            <BackLink to={getRouteExpenseList({ groupId: group.id })} messageDescriptor={commonMessages.actionCancel} />
          }
          secondaryContent={
            <>
              <DialogButton
                dialogProps={{ title: <FormattedMessage {...commonMessages.confirmationLabel} /> }}
                toggleButtonProps={{
                  intent: Intent.DANGER,
                  outlined: true,
                  small: true,
                  text: <FormattedMessage {...commonMessages.actionDelete} />,
                  loading: isSubmittingDelete,
                  disabled: isSubmitting,
                }}
                actionButtons={[
                  {
                    onClick: onExpenseDelete,
                    intent: Intent.DANGER,
                    text: <FormattedMessage {...commonMessages.actionDelete} />,
                  },
                  {
                    text: <FormattedMessage {...commonMessages.actionCancel} />,
                  },
                ]}
              >
                <FormattedMessage {...messages.deleteConfirmationMessage} values={{ subject: expense.subject }} />
              </DialogButton>
            </>
          }
        >
          <FormattedMessage {...commonMessages.expenseEdit} /> "{expense.subject}"
        </Title>
      </Container>

      <ExpenseForm
        onSubmit={handleSubmit}
        initialData={initialData}
        isSubmitting={isSubmitting}
        disabled={isSubmittingDelete}
      />
    </Page>
  );
};
