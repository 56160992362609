import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  noItems: {
    id: 'groupListNoItems',
    defaultMessage: "You don't have any groups yet.",
  },
  noItemsDescription: {
    id: 'groupListNoItemsDescription',
    defaultMessage: 'Create one now and start settling!',
  },
});
