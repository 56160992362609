import { DatePicker, DatePickerProps } from '@blueprintjs/datetime';
import React, { useCallback } from 'react';
import MomentLocaleUtils from 'react-day-picker/moment';

//IMPORTANT NOTE: When adding new locale, update all places marked like this
import 'moment/locale/pl';

import { LocaleContext } from '../../contexts/LocaleContext';
import { TimestampString } from '../../models/TimestampString';

type Props = Omit<DatePickerProps, 'onChange' | 'value'> & {
  onChange: (date: TimestampString) => unknown;
  value?: TimestampString;
};

export const DatePickerLoalized: React.FC<Props> = ({ onChange, value = null, ...otherProps }) => {
  const { locale } = React.useContext(LocaleContext);
  const handleOnChange = useCallback(
    (date: Date) => {
      date && onChange(date.getTime().toString());
    },
    [onChange]
  );

  return (
    <DatePicker
      locale={locale}
      localeUtils={MomentLocaleUtils}
      onChange={handleOnChange}
      value={value === null ? value : new Date(parseInt(value))}
      {...otherProps}
    />
  );
};
