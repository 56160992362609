import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  markPaid: {
    id: 'groupBalanceMarkPaid',
    defaultMessage: 'Mark paid',
  },
  allSettled: {
    id: 'groupBalanceAllSettled',
    defaultMessage: 'All settled!',
  },
  noPayments: {
    id: 'groupBalanceNoPayments',
    defaultMessage: 'No payments yet',
  },
  initialDuesHeading: {
    id: 'groupBalanceInitialDuesHeading',
    defaultMessage: 'Initial dues',
  },
  dueListItem: {
    id: 'groupBalanceDueListItem',
    defaultMessage: '{debtor} owes {creditor} {amount}',
  },
  paymentsHeading: {
    id: 'groupBalancePaymentsHeading',
    defaultMessage: 'Payments',
  },
  paymentListItem: {
    id: 'groupBalancePaymentListItem',
    defaultMessage: '{payer} paid {payee} {amount} at {date}',
  },
  unsettle: {
    id: 'groupBalanceUnsettle',
    defaultMessage: 'Unsettle',
  },
});
