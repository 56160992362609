import { Icon, IconSize } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import React from 'react';

import { ICON_SIZE } from '../../utils/visual';

import style from './style.module.scss';

export type Props = {
  iconName: IconName;
  secondaryIconName?: IconName;
  size?: IconSize;
  bgColor?: string;
  color?: string;
};

const ICON_PADDING_RATIO = 0.4;
const MULTIPLE_ICONS_SIZE_RATIO = 0.8;

export const ComposedIcon: React.FC<Props> = ({
  iconName,
  secondaryIconName,
  size = ICON_SIZE.default,
  bgColor,
  color = 'currentColor',
}) => {
  const sizeModifier = bgColor ? MULTIPLE_ICONS_SIZE_RATIO : 1;

  const content = (
    <>
      <Icon icon={iconName} size={Math.round(size * (secondaryIconName ? sizeModifier : 1))} color={color} />
      {secondaryIconName && (
        <Icon
          icon={secondaryIconName}
          size={Math.round(size * sizeModifier)}
          color={color}
          style={{ marginRight: -Math.round(0.2 * size) }}
        />
      )}
    </>
  );

  if (!bgColor) return content;

  const outerDimension = Math.round((1 + 2 * ICON_PADDING_RATIO) * size);

  return (
    <span
      className={style.wrapper}
      style={{
        width: outerDimension,
        height: outerDimension,
        padding: Math.round(ICON_PADDING_RATIO * size),
        backgroundColor: bgColor,
      }}
    >
      {content}
    </span>
  );
};
