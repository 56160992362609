import { Menu, MenuItem, MenuDivider, Button, ButtonProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Popover2, Popover2Props } from '@blueprintjs/popover2';
import { isToday, isYesterday, endOfToday, startOfToday, startOfYesterday } from 'date-fns';
import React, { useState } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';

import { TimestampString } from '../../models/TimestampString';

import { commonMessages } from '../../utils/commonMessages';
import { DatePickerLoalized } from '../DatePickerLoalized';

type Props = {
  value?: TimestampString;
  onChange: (value: TimestampString) => unknown;
  buttonProps?: Partial<ButtonProps>;
  popoverProps?: Partial<Popover2Props>;
};

const startOfTodayInt = startOfToday().getTime();
const startOfYesterdayInt = startOfYesterday().getTime();

export const DatePickerButton: React.FC<Props> = ({ value, onChange, popoverProps = {}, buttonProps = {} }) => {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const onDatepickerChange = (date: TimestampString) => {
    onChange(date);
    setIsDatePickerVisible(false);
  };

  const valueInt = value ? parseInt(value) : 0;
  return (
    <Popover2
      {...popoverProps}
      isOpen={isDatePickerVisible}
      onClose={() => {
        setIsDatePickerVisible(false);
      }}
      content={
        <>
          <Menu>
            <MenuItem
              icon={!!valueInt && isToday(valueInt) ? IconNames.SELECTION : IconNames.CIRCLE}
              active={!!valueInt && isToday(valueInt)}
              onClick={() => {
                onChange(startOfTodayInt.toString());
                setIsDatePickerVisible(false);
              }}
              text={<FormattedMessage {...commonMessages.timeToday} />}
            />

            <MenuItem
              icon={!!valueInt && isYesterday(valueInt) ? IconNames.SELECTION : IconNames.CIRCLE}
              active={!!valueInt && isYesterday(valueInt)}
              onClick={() => {
                onChange(startOfYesterdayInt.toString());
                setIsDatePickerVisible(false);
              }}
              text={<FormattedMessage {...commonMessages.timeYesterday} />}
            />

            <MenuDivider />

            <DatePickerLoalized
              value={value}
              highlightCurrentDay
              onChange={onDatepickerChange}
              canClearSelection={true}
              maxDate={endOfToday()}
            />
          </Menu>
        </>
      }
    >
      <Button
        {...buttonProps}
        onClick={() => {
          setIsDatePickerVisible(true);
        }}
        text={
          !valueInt ? (
            <FormattedMessage {...commonMessages.datePlaceholder} />
          ) : isToday(valueInt) ? (
            <FormattedMessage {...commonMessages.timeToday} />
          ) : isYesterday(valueInt) ? (
            <FormattedMessage {...commonMessages.timeYesterday} />
          ) : (
            <FormattedDate value={new Date(valueInt)} />
          )
        }
      />
    </Popover2>
  );
};
