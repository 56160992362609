import { Button, Collapse, InputGroup, Intent } from '@blueprintjs/core';
import { round, sortBy } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useContext } from 'react';
import { startOfToday } from 'date-fns';

import { GroupContext } from '../../contexts/GroupContext';

import { GroupMember, GroupMemberId } from '../../models/GroupMember';
import { SplitKind } from '../../models/SplitKind';
import { CURRENCIES_MAP, CurrencyCode, DEFAULT_CURRENCY_CODE } from '../../models/Currency';
import { MemberAmount } from '../../models/MemberAmount';
import { ExpenseInput, ExpenseInputData } from '../../models/Expense';
import { isExistingInstance } from '../../models/ExistingInstance';

import { splitAmountEvenly } from '../../utils/splitAmountEvenly';
import { commonMessages } from '../../utils/commonMessages';
import { usePrevious } from '../../hooks/usePrevious';

import { CurrencySelect } from '../_form/CurrencySelect';
import { SplitKindSelect } from '../_form/SplitKindSelect';
import { MemberSelectSingle } from '../_form/MemberSelect/Single';
import { MemberAmountsArray } from '../_form/MemberAmountsArray';
import { AmountInput } from '../_form/AmountInput';
import { Container, Size as ContainerSize } from '../Container';
import { StickyFooter } from '../StickyFooter';
import { DatePickerButton } from '../DatePickerButton';

import style from './style.module.scss';
import { messages } from './messages';

export type Props = {
  onSubmit: (formData: ExpenseInputData) => void;
  initialData: ExpenseInput | ExpenseInputData;
  isSubmitting?: boolean;
  disabled?: boolean;
};

export const ExpenseForm: React.FC<Props> = ({ onSubmit, initialData, isSubmitting = false, disabled = false }) => {
  const { group, currentMember } = useContext(GroupContext);
  const initialPayer = group.members.find(({ id }) => id === initialData.payerId)!;
  const { formatMessage } = useIntl();
  const meLabel = formatMessage(messages.me);

  const isExpenseNew = !isExistingInstance(initialData);

  const [currency, setCurrency] = useState<CurrencyCode>(initialData.currencyCode);
  const [payer, setPayer] = useState<GroupMember>(initialPayer);
  const [subject, setSubject] = useState<string>(initialData.subject);
  const [splitKind, setSplitKind] = useState<SplitKind>(initialData.splitKind);

  const startOfTodayInt = startOfToday().getTime();
  const [paidAt, setPaidAt] = useState<string>(initialData.paidAt || startOfTodayInt.toString());

  const amountsUnit = CURRENCIES_MAP[currency].symbol;
  const amountsPrecision = CURRENCIES_MAP[currency].precision;
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(true);

  const [amount, setAmount] = useState<number>(initialData.amount);
  const [membersTotal, setMembersTotal] = useState(amount);

  const [memberAmounts, setMemberAmounts] = useState<MemberAmount[]>(
    sortBy(
      initialData.splitAmounts.map(({ memberId, amount }) => ({
        amount,
        member: group.members.find(({ id }: { id: GroupMemberId }) => id === memberId) as GroupMember,
      })),
      'displayName'
    )
  );
  const previousMemberAmounts = usePrevious(memberAmounts);
  const evenAmountsByValue = splitAmountEvenly(amount, memberAmounts.length, amountsPrecision);

  const actualMembersTotal = memberAmounts.reduce((acc, { amount }) => round(acc + amount, amountsPrecision), 0);
  const membersTotalDelta = actualMembersTotal - membersTotal;
  const membersAmountsHasError = membersTotalDelta !== 0;

  const equalizeMemberAmounts = () => {
    const evenAmounts = evenAmountsByValue;
    const updatedMemberAmounts = memberAmounts.map((memberAmount, index) => ({
      ...memberAmount,
      amount: evenAmounts[index],
    }));
    setMemberAmounts(updatedMemberAmounts);
  };

  useEffect(() => {
    if (splitKind === SplitKind.EVEN) {
      equalizeMemberAmounts();
    }
  }, [currency]);

  useEffect(() => {
    setMembersTotal(amount);
    if (splitKind === SplitKind.EVEN) {
      equalizeMemberAmounts();
    } else {
      setIsCollapseOpen(true);
    }
  }, [splitKind]);

  useEffect(() => {
    setMembersTotal(amount);

    if (splitKind === SplitKind.EVEN) {
      equalizeMemberAmounts();
    }
  }, [amount]);

  useEffect(() => {
    if (splitKind === SplitKind.EVEN && previousMemberAmounts?.length !== memberAmounts.length) {
      equalizeMemberAmounts();
    }
  }, [memberAmounts]);

  const onCollapseButtonClick = () => {
    setIsCollapseOpen(!isCollapseOpen);
  };

  const onPayerChange = (selectedId: GroupMemberId) => {
    const member = group.members.find(({ id }: { id: GroupMemberId }) => id === selectedId);
    setPayer(member! as GroupMember);
  };

  const onSubjectChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setSubject(value);
  };
  const subjectPlaceholder = formatMessage(messages.subjectPlaceholder);

  const isSubmitPossible = !disabled && !membersAmountsHasError && amount > 0 && subject.length > 0;

  const onSubmitClick = () => {
    const formData = {
      subject,
      payerId: payer.id,
      currencyCode: currency,
      splitKind,
      amount,
      splitAmounts: memberAmounts.map(({ member, amount }) => ({ amount, memberId: member.id })),
      paidAt,
    };
    isSubmitPossible && onSubmit(formData);
  };

  return (
    <>
      <Container size={ContainerSize.SM} left={false} right={false}>
        <Container size={ContainerSize.SM} className={classNames(style.sentence, 'bp3-large')}>
          <div className={style.sentencePart}>
            <AmountInput
              large
              disabled={disabled}
              className={style.amountInput}
              defaultValue={amount}
              onChange={setAmount}
              currencyPrecision={(CURRENCIES_MAP[currency] || CURRENCIES_MAP[DEFAULT_CURRENCY_CODE]).precision}
              instanceId={'expense-total-amunt'}
            />

            <CurrencySelect disabled={disabled} initialCurrencyCode={currency} onChange={setCurrency} />
          </div>

          <div className={style.sentencePart}>
            <FormattedMessage {...messages.paid} />

            <DatePickerButton value={paidAt} onChange={setPaidAt} buttonProps={{ className: style.datepickerToggle }} />
          </div>

          <div className={style.sentencePart}>
            {payer.id === currentMember.id ? (
              <FormattedMessage {...messages.paidByFirstMember} />
            ) : (
              <FormattedMessage {...messages.paidBy} />
            )}
            <MemberSelectSingle
              disabled={disabled}
              members={[
                { ...currentMember, displayName: meLabel },
                ...group.members.filter(({ id }: { id: GroupMemberId }) => id !== currentMember.id),
              ]}
              onChange={onPayerChange}
              shouldSortOprions={false}
              label={payer.id === currentMember.id ? meLabel : payer.displayName}
            />
          </div>

          <div className={style.sentencePart}>
            <FormattedMessage {...messages.for} />

            <InputGroup
              disabled={disabled}
              size={Math.max(subject.length, subjectPlaceholder.length)}
              className={style.subjectInput}
              large
              value={subject}
              onChange={onSubjectChange}
              placeholder={subjectPlaceholder}
            />
          </div>
          <div className={style.sentencePart}>
            <FormattedMessage {...messages.shouldBeSplit} />
            <SplitKindSelect disabled={disabled} splitKind={splitKind} onChange={argument => setSplitKind(argument)} />
          </div>
          <div className={style.sentencePart}>
            {memberAmounts.length === group.members.length && (
              <FormattedMessage {...messages.toEveryone} tagName="span" />
            )}{' '}
            {(splitKind !== SplitKind.EVEN || memberAmounts.length !== group.members.length) && (
              <FormattedMessage {...messages.asFollows} tagName="span" />
            )}
          </div>
        </Container>

        <Container size={ContainerSize.SM} top bottom>
          <Button
            className={style.detailsToggle}
            onClick={onCollapseButtonClick}
            minimal
            rightIcon={isCollapseOpen ? 'chevron-up' : 'chevron-down'}
          >
            {`${
              isCollapseOpen
                ? formatMessage(commonMessages.visibilityHide)
                : formatMessage(commonMessages.visibilityShow)
            } ${formatMessage(commonMessages.amounts)}`}
          </Button>
        </Container>

        <Collapse isOpen={isCollapseOpen} keepChildrenMounted>
          <Container size={ContainerSize.SM} bottom>
            <MemberAmountsArray
              disabled={disabled}
              members={group.members}
              amount={membersTotal}
              values={memberAmounts}
              onChange={setMemberAmounts}
              precision={amountsPrecision}
              unit={amountsUnit}
            />
          </Container>
        </Collapse>

        <Container size={ContainerSize.SM}>
          <p className={classNames('text-intent-danger', style.error)}>
            {membersAmountsHasError && (
              <>
                <FormattedMessage
                  {...messages.totalShouldMatch}
                  values={{ amount: membersTotal.toFixed(amountsPrecision) }}
                />{' '}
                {'('}
                <FormattedMessage
                  {...(membersTotalDelta > 0 ? commonMessages.amountExceeded : commonMessages.amountMissing)}
                  values={{ delta: Math.abs(membersTotalDelta).toFixed(amountsPrecision) }}
                />
                {')'}
              </>
            )}
          </p>
        </Container>

        <StickyFooter>
          <Button
            large
            type="submit"
            intent={Intent.SUCCESS}
            disabled={!isSubmitPossible}
            loading={isSubmitting}
            onClick={onSubmitClick}
          >
            <FormattedMessage {...(isExpenseNew ? commonMessages.expenseAdd : commonMessages.actionSave)} />
          </Button>
        </StickyFooter>
      </Container>
    </>
  );
};
