import React from 'react';

import { TextPage } from '../../TextPage';

//IMPORTANT NOTE: When adding new locale, update all places marked like this
import { pl } from './content/pl';
import { en } from './content/en';

export const Privacy: React.FC = () => {
  return <TextPage content={{ en, pl }} />;
};
