import { useMutation } from '@apollo/client';
import { Intent } from '@blueprintjs/core';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useLocation } from 'react-router';
import { SubmitHandler } from 'react-hook-form';
import { IconNames } from '@blueprintjs/icons';

import { SIGN_UP_VIA_INVITE_CONFIRM } from '../../../../api/queries';

import { AuthContext } from '../../../../contexts/AuthContext';
import { commonMessages } from '../../../../utils/commonMessages';
import { getRouteGroupList } from '../../../../utils/navRoutes';

import { RegistrationLayout } from '../_partials/RegistrationLayout';
import { ProfileForm } from '../../../ProfileForm';

import { MainToaster } from '../../../../utils/toaster';

type FormVariables = {
  displayName: string;
  password: string;
  consent: boolean;
};

export const InviteConfirm: React.FC = () => {
  const { search } = useLocation();
  const { formatMessage } = useIntl();

  const query = new URLSearchParams(search);

  const [signUpViaInviteConfirm, { data, error, loading }] = useMutation(SIGN_UP_VIA_INVITE_CONFIRM);
  const { updateUser } = useContext(AuthContext);

  const [hasSuccess, setHasSuccess] = useState(false);

  const token = query.get('token');
  const email = query.get('email');

  useEffect(() => {
    if (data?.signUpViaInviteConfirm) {
      updateUser(data.signUpViaInviteConfirm);
    }
  }, [data]);

  const onSubmit: SubmitHandler<Partial<FormVariables>> = async input => {
    const variables = { ...input, email, token };

    try {
      await signUpViaInviteConfirm({ variables });
      setHasSuccess(true);
    } catch (e) {
      showErrorToast(e as Error);
    }
  };

  const showErrorToast = (error: Error) => {
    MainToaster.show({
      message: `${formatMessage(commonMessages.genericError)}: ${error.message}`,
      icon: IconNames.DISABLE,
      intent: Intent.DANGER,
    });
  };

  if (hasSuccess) {
    return <Redirect to={getRouteGroupList()} />;
  }

  return (
    <RegistrationLayout>
      <h1>
        <FormattedMessage {...commonMessages.authSignUp} />
      </h1>

      {error && (
        <p className="text-intent-danger">
          <FormattedMessage {...commonMessages.genericError} />
          <span>: {error ? error.message : ''}</span>
        </p>
      )}

      <ProfileForm onSubmit={onSubmit} withPasswordField withConsentField isSubmitting={loading} />
    </RegistrationLayout>
  );
};
