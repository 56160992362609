import { Profile } from './User';

export type GroupMemberId = string;

export type GroupMemberInput = {
  displayName: string;
};

export enum GroupMemberStatus {
  INVITED = 'INVITED',
  REJECTED = 'REJECTED',
  JOINED = 'JOINED',
  LEFT = 'LEFT',
}

export type GroupMember = {
  id: GroupMemberId;
  displayName: string;
  profile?: Profile;
  status?: GroupMemberStatus;
  email?: string;
};
