import { Button, HTMLInputProps, InputGroupProps, InputGroup, Tooltip } from '@blueprintjs/core';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { commonMessages } from '../../../utils/commonMessages';

type Props = InputGroupProps & HTMLInputProps;

export const PasswordField: React.FC<Props> = props => {
  const { formatMessage } = useIntl();
  const [showPassword, setShowPassword] = useState(false);

  const handleLockClick = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword, setShowPassword]);

  const lockButton = (
    <Tooltip
      content={`${formatMessage(
        showPassword ? commonMessages.visibilityHide : commonMessages.visibilityShow
      )} ${formatMessage(commonMessages.password)}`}
    >
      <Button icon={showPassword ? 'unlock' : 'lock'} minimal={true} onClick={handleLockClick} />
    </Tooltip>
  );

  return (
    <InputGroup
      {...props}
      type={showPassword ? 'text' : 'password'}
      rightElement={lockButton}
      placeholder={`${formatMessage(commonMessages.password)}...`}
    />
  );
};
