import { IconNames } from '@blueprintjs/icons';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, NonIdealState } from '@blueprintjs/core';

import { Expense } from '../../../../../../models/Expense';
import { CurrencyCode } from '../../../../../../models/Currency';

import { GroupContext } from '../../../../../../contexts/GroupContext';

import { commonMessages } from '../../../../../../utils/commonMessages';
import { getRouteExpenseEdit, getRouteExpenseNew } from '../../../../../../utils/navRoutes';
import { ICON_BG_COLOR, ICON_COLOR, ICON_SIZE } from '../../../../../../utils/visual';

import { LocaleLink } from '../../../../../LocaleLink';
import { EnhancedList, Props as EnhancedListProps } from '../../../../../EnhancedList';
import { CurrencyAmount } from '../../../../../CurrencyAmount';
import { AntiContainer, Container } from '../../../../../Container';
import { StickyHeader } from '../../../../../StickyHeader';

import style from './style.module.scss';
import { messages } from './messages';

const enhancedListCommonProps: Partial<EnhancedListProps> = {
  defaultIconName: IconNames.CREDIT_CARD,
  defaultIconBgColor: ICON_BG_COLOR.default,
  defaultIconColor: ICON_COLOR.defaultOverBg,
  iconSize: ICON_SIZE.listSmall,
};

type Props = {
  filteredExpenses: Expense[];
  filtersWidget: React.ReactNode;
  redirectFromPath?: string;
  setCurrencyFilterValue: (currencyCode: CurrencyCode) => unknown;
};

type Total = {
  currencyCode: CurrencyCode;
  amount: number;
  count: number;
};

const calculateTotals = (expenses: Expense[]) =>
  expenses.reduce((acc, expense) => {
    const totalIndex = acc.findIndex(({ currencyCode }) => currencyCode === expense.currencyCode);
    if (totalIndex >= 0) {
      acc[totalIndex].amount += expense.amount;
      acc[totalIndex].count += 1;
    } else {
      acc.push({
        currencyCode: expense.currencyCode,
        amount: expense.amount,
        count: 1,
      });
    }
    return acc;
  }, [] as Total[]);

export const ExpenseList: React.FC<Props> = ({
  filteredExpenses,
  filtersWidget,
  redirectFromPath,
  setCurrencyFilterValue,
}) => {
  const { formatDate } = useIntl();
  const { group, currentMember } = useContext(GroupContext);

  const totals = calculateTotals(filteredExpenses);
  return (
    <AntiContainer>
      <StickyHeader>
        <Container className={style.actionsWrapper}>
          <LocaleLink
            to={{ pathname: getRouteExpenseNew({ groupId: group.id }), state: { from: redirectFromPath } }}
            className="bp3-button bp3-intent-primary bp3-icon-add"
          >
            <FormattedMessage {...commonMessages.expenseAdd} />
          </LocaleLink>

          {group.expenses.length > 0 && filtersWidget}
        </Container>
      </StickyHeader>

      {group.expenses.length > 0 ? (
        filteredExpenses.length > 0 ? (
          <Container top bottom>
            <Container bottom right={false} left={false}>
              {filteredExpenses.length === group.expenses.length ? (
                <FormattedMessage {...messages.summaryAllExpenses} values={{ countAll: group.expenses.length }} />
              ) : (
                <FormattedMessage
                  {...messages.summarySomeExpenses}
                  values={{ countAll: group.expenses.length, count: filteredExpenses.length }}
                />
              )}
              {totals.length === 1 && (
                <>
                  {' '}
                  <FormattedMessage {...messages.totalFor} /> <CurrencyAmount {...totals[0]} />
                </>
              )}
              {totals.length > 1 && (
                <ul className={style.summaryList}>
                  {totals.map(total => (
                    <li key={total.currencyCode}>
                      <Button
                        outlined
                        onClick={() => {
                          setCurrencyFilterValue(total.currencyCode);
                        }}
                      >
                        {total.count} <FormattedMessage {...messages.totalFor} /> <CurrencyAmount {...total} />
                      </Button>
                    </li>
                  ))}
                </ul>
              )}
            </Container>

            <EnhancedList
              {...enhancedListCommonProps}
              items={[
                ...filteredExpenses.map(expense => {
                  const { id, subject, amount, currencyCode, payer, paidAt, splitAmounts } = expense;
                  const isPaidByUser = payer.id === currentMember.id;
                  const isUserInvolved = splitAmounts.some(({ member: { id } }) => id === currentMember.id);

                  return {
                    id,
                    groupName: formatDate(new Date(parseInt(paidAt))),

                    header: (
                      <span className={style.expenseItem}>
                        <span className={style.expenseItem__price}>
                          <CurrencyAmount amount={amount} currencyCode={currencyCode} />
                        </span>
                        <span className={style.expenseItem__subject}>{subject}</span>
                      </span>
                    ),
                    detail: (
                      <small className="bp3-text-muted">
                        <FormattedMessage {...messages.paidBy} />{' '}
                        {isPaidByUser ? <FormattedMessage tagName="strong" {...messages.paidYou} /> : payer.displayName}{' '}
                        <FormattedMessage {...messages.paidForShort} />{' '}
                        {isUserInvolved ? (
                          <small>
                            <FormattedMessage tagName="strong" {...messages.paidYou} />
                            {splitAmounts.length > 1 && (
                              <>
                                {' '}
                                <FormattedMessage {...messages.paidAnd} />{' '}
                                <FormattedMessage
                                  {...messages.paidPeople}
                                  values={{ number: splitAmounts.length - 1 }}
                                />
                              </>
                            )}
                          </small>
                        ) : (
                          <FormattedMessage {...messages.paidPeople} values={{ number: splitAmounts.length }} />
                        )}
                      </small>
                    ),
                    actions: (
                      <div className={style.expenseItem__actions}>
                        <LocaleLink to={getRouteExpenseEdit({ groupId: group.id, expenseId: id })}>
                          <FormattedMessage {...commonMessages.actionEdit} />
                        </LocaleLink>

                        <LocaleLink
                          to={{ pathname: getRouteExpenseNew({ groupId: group.id }), state: { data: expense } }}
                        >
                          <FormattedMessage {...commonMessages.actionCopy} />
                        </LocaleLink>
                      </div>
                    ),
                  };
                }),
              ]}
            />
          </Container>
        ) : (
          <Container bottom>
            <NonIdealState
              className="mt-default"
              icon={IconNames.SEARCH}
              title={<FormattedMessage {...messages.noItemsFiltered} />}
              description={<FormattedMessage {...messages.noItemsFilteredDescription} />}
            />
          </Container>
        )
      ) : null}

      {!group.expenses.length && (
        <Container bottom>
          <NonIdealState
            className="mt-default"
            icon={IconNames.CREDIT_CARD}
            title={<FormattedMessage {...messages.noItems} />}
            description={<FormattedMessage {...messages.noItemsDescription} />}
          />
        </Container>
      )}
    </AntiContainer>
  );
};
