import { sortBy, sortedIndexBy } from 'lodash';

import { CurrencyCode, ExchangeRate } from '../models/Currency';
import { Expense } from '../models/Expense';
import { Due } from '../models/Due';
import { Payment } from '../models/Payment';
import { GroupMember } from '../models/GroupMember';

import { roundToCurrencyPrecision } from './roundToCurrencyPrecision';

type Props = {
  expenses: Expense[];
  payments: Payment[];
  selectedCurrencyCode?: CurrencyCode;
  exchangeRates?: ExchangeRate[];
  pipDenominator?: number;
};

type BalanceSheet = {
  [memberId: string]: {
    spent: number;
    shouldSpend: number;
    paid: number;
    received: number;
    member: GroupMember;
  };
};
type BalanceSheetPerCurrency = {
  [currencyCode: string]: BalanceSheet;
};
type UserBalanceMap = {
  [memberId: string]: number;
};
type UserBalanceMapsPerCurrency = {
  [currencyCode: string]: UserBalanceMap;
};
type BalanceStackItem = { member: GroupMember; amount: number };
type BalanceStack = BalanceStackItem[];
type BalanceStackPerCurrency = {
  [currencyCode: string]: BalanceStack;
};

export const calculateDues = ({
  expenses,
  payments,
  selectedCurrencyCode,
  exchangeRates = [],
  pipDenominator = 10000,
}: Props): Due[] => {
  const membersMap: { [id: string]: GroupMember } = {};
  const membersMapPush = (member: GroupMember) => {
    if (!membersMap[member.id]) {
      membersMap[member.id] = member;
    }
  };

  const balanceSheetPerCurrency = expenses.reduce(
    (byCurrency, { currencyCode, amount: totalAmount, payer, splitAmounts }) => {
      const balanceSheet: BalanceSheet = {
        [payer.id]: { ...(byCurrency[currencyCode] || {}) }[payer.id] || {
          spent: 0,
          shouldSpend: 0,
          paid: 0,
          received: 0,
          member: payer,
        },
        ...(byCurrency[currencyCode] || {}),
      };
      balanceSheet[payer.id].spent += totalAmount;
      membersMapPush(payer);

      splitAmounts.forEach(({ member, amount }) => {
        if (balanceSheet[member.id]) {
          balanceSheet[member.id].shouldSpend += amount;
        } else {
          balanceSheet[member.id] = { spent: 0, shouldSpend: amount, paid: 0, received: 0, member };
        }
        membersMapPush(member);
      });

      byCurrency[currencyCode] = balanceSheet;
      return byCurrency;
    },
    {} as BalanceSheetPerCurrency
  );

  payments.forEach(({ currencyCode, payee, payer, amount }) => {
    const balanceSheet: BalanceSheet = {
      [payer.id]: { spent: 0, shouldSpend: 0, paid: 0, received: 0, member: payer },
      [payee.id]: { spent: 0, shouldSpend: 0, paid: 0, received: 0, member: payee },
      ...(balanceSheetPerCurrency[currencyCode] || {}),
    };

    balanceSheet[payer.id].paid += amount;
    balanceSheet[payee.id].received += amount;

    balanceSheetPerCurrency[currencyCode] = balanceSheet;
    membersMapPush(payer);
    membersMapPush(payee);
  });

  const userBalanceMapsPerCurrency: UserBalanceMapsPerCurrency = Object.entries(balanceSheetPerCurrency).reduce(
    (perCurrency, [currencyCode, balanceSheet]) => {
      const userBalanceMap = Object.entries(balanceSheet).reduce(
        (balance, [memberId, { spent, shouldSpend, paid, received }]) => {
          const balanceAmount = roundToCurrencyPrecision(spent - shouldSpend + paid - received, currencyCode);
          if (balanceAmount !== 0) {
            balance[memberId] = balanceAmount;
          }
          return balance;
        },
        {} as UserBalanceMap
      );

      perCurrency[currencyCode] = userBalanceMap;
      return perCurrency;
    },
    {} as UserBalanceMapsPerCurrency
  );

  let finalUserBalanceMapsPerCurrency = userBalanceMapsPerCurrency;
  if (selectedCurrencyCode) {
    finalUserBalanceMapsPerCurrency = Object.entries(userBalanceMapsPerCurrency).reduce(
      (acc, [currencyCode, userBalanceMap]) => {
        if (selectedCurrencyCode === currencyCode) {
          return acc;
        }

        const exchangeRate = exchangeRates.find(
          ({ baseCode, quoteCode }) => baseCode === currencyCode && quoteCode === selectedCurrencyCode
        );

        const convertedUserBalanceMap = { ...userBalanceMap };
        for (const userId in userBalanceMap) {
          convertedUserBalanceMap[userId] = roundToCurrencyPrecision(
            userBalanceMap[userId] * (exchangeRate!.value / pipDenominator),
            selectedCurrencyCode
          );
        }

        const convertedBalanceSum = roundToCurrencyPrecision(
          Object.values(convertedUserBalanceMap).reduce((acc, amount) => acc + amount, 0),
          selectedCurrencyCode
        );

        if (Math.abs(convertedBalanceSum) !== 0) {
          const maxBalance = Object.entries(convertedUserBalanceMap).reduce(
            (acc, [memberId, amount]) => {
              if (!acc.id) {
                return { id: memberId, value: amount };
              }
              return amount > acc.value ? { id: memberId, value: amount } : acc;
            },
            { id: '', value: 0 }
          );

          convertedUserBalanceMap[maxBalance.id] = roundToCurrencyPrecision(
            maxBalance.value - convertedBalanceSum,
            selectedCurrencyCode
          );
        }

        for (const userId in userBalanceMap) {
          acc[selectedCurrencyCode][userId] = roundToCurrencyPrecision(
            (acc[selectedCurrencyCode][userId] || 0) + convertedUserBalanceMap[userId],
            selectedCurrencyCode
          );
        }

        return acc;
      },
      userBalanceMapsPerCurrency[selectedCurrencyCode]
        ? { [selectedCurrencyCode]: { ...userBalanceMapsPerCurrency[selectedCurrencyCode] } }
        : ({ [selectedCurrencyCode]: {} } as UserBalanceMapsPerCurrency)
    );
  }

  const balanceStacksPerCurrency = Object.entries(finalUserBalanceMapsPerCurrency).reduce(
    (perCurrency, [currencyCode, userBalanceMap]) => {
      const balanceStack: BalanceStack = Object.entries(userBalanceMap)
        .map(([memberId, amount]) => ({
          member: membersMap[memberId],
          amount,
        }))
        .filter(({ amount }) => Math.abs(amount) > 0);

      perCurrency[currencyCode] = sortBy(balanceStack, 'amount');
      return perCurrency;
    },
    {} as BalanceStackPerCurrency
  );

  const dues: Due[] = [];
  Object.entries(balanceStacksPerCurrency).forEach(([currencyCode, balanceStack]) => {
    const remainingBalances = [...balanceStack];

    while (remainingBalances.length) {
      if (remainingBalances.length === 1) {
        throw new Error('Wrong data: dues cannot be balanced!');
      }

      const maxDebt = remainingBalances.shift()!;
      const maxCredit = remainingBalances.pop()!;
      const delta = roundToCurrencyPrecision(maxCredit.amount + maxDebt.amount, currencyCode); //maxDebt.amount is negative

      dues.push({
        currencyCode: currencyCode as CurrencyCode,
        debtor: maxDebt.member,
        creditor: maxCredit.member,
        amount: delta > 0 ? -maxDebt.amount : maxCredit.amount,
      });

      if (Math.abs(delta) !== 0) {
        const newBalanceItem: BalanceStackItem = {
          member: delta > 0 ? maxCredit.member : maxDebt.member,
          amount: delta,
        };

        const newItemIndex = sortedIndexBy(remainingBalances, newBalanceItem, 'amount');

        remainingBalances.splice(newItemIndex, 0, newBalanceItem);
      }
    }
  });

  return dues;
};
