import React, { ReactElement, useContext } from 'react';
import { Redirect } from 'react-router';

import { User } from '../models/User';

import { AuthContext } from '../contexts/AuthContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { getRouteHome } from '../utils/navRoutes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ReturnValue = { user?: User; returnContent: ReactElement<any, any> | null };

export function useUserOrRedirect(redirectPath = getRouteHome()): ReturnValue {
  const { locale } = React.useContext(LocaleContext);
  const routePrefix = `/${locale}`;

  const { user, isInitialized } = useContext(AuthContext);

  if (!isInitialized) {
    return { returnContent: null };
  }

  if (!user) {
    return { returnContent: <Redirect to={`${routePrefix}/${redirectPath}`} /> };
  }

  return { returnContent: null, user };
}
