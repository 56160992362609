import { useMutation } from '@apollo/client';
import React, { useContext, useRef, useState } from 'react';

import { Button, Intent, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import { sortBy } from 'lodash';

import { Due } from '../../../../../../models/Due';
import { PaymentInputData, PaymentId, Payment } from '../../../../../../models/Payment';
import { GroupBalanceCurrencyKind } from '../../../../../../models/Group';

import { CREATE_PAYMENT, DELETE_PAYMENT, GET_GROUP } from '../../../../../../api/queries';

import { GroupContext } from '../../../../../../contexts/GroupContext';
import { calculateDues } from '../../../../../../utils/calculateDues';
import { commonMessages } from '../../../../../../utils/commonMessages';
import { MainToaster } from '../../../../../../utils/toaster';
import { ICON_BG_COLOR, ICON_COLOR } from '../../../../../../utils/visual';
import { scaleAmountToCents } from '../../../../../../utils/scaleAmount';

import { EnhancedList } from '../../../../../EnhancedList';
import { Title } from '../../../../../Title';
import { CurrencyAmount } from '../../../../../CurrencyAmount';
import { Container } from '../../../../../Container';

import { messages } from './messages';

type LocalPaymentId = string;

export const GroupBalance: React.FC = () => {
  const {
    group: { id: groupId, expenses, payments, balanceCurrencyKind, selectedSingleCurrency, exchangeRates = [] },
    currentMember,
  } = useContext(GroupContext);
  const { formatMessage } = useIntl();

  const [submittingPaidLocalIdsVisual, setSubmittingPaidLocalIdsVisual] = useState<LocalPaymentId[]>([]);
  const submittingPaidLocalIds = useRef<LocalPaymentId[]>([]);

  const [submittingDeletePaymentIdsVisual, setSubmittingDeletePaymentIdsVisual] = useState<PaymentId[]>([]);
  const submittingDeletePaymentIds = useRef<PaymentId[]>([]);

  const [createPayment] = useMutation(CREATE_PAYMENT, { refetchQueries: [GET_GROUP] });
  const [deletePayment] = useMutation(DELETE_PAYMENT, { refetchQueries: [GET_GROUP] });

  const isDueMine = ({ debtor, creditor }: Due) => creditor.id === currentMember.id || debtor.id === currentMember.id;
  const isPaymentMine = ({ payer, payee }: Payment) => payee.id === currentMember.id || payer.id === currentMember.id;

  const dues: Due[] = calculateDues({
    expenses,
    payments,
    selectedCurrencyCode: balanceCurrencyKind === GroupBalanceCurrencyKind.SINGLE ? selectedSingleCurrency : undefined,
    exchangeRates,
  });

  const generateLocalPaymentId = (member1: string, member2: string, currencyCode: string): LocalPaymentId => {
    return `${member1}-${member2}-${currencyCode}`;
  };

  const updatePaidLocalIdsVisualState = () => {
    setSubmittingPaidLocalIdsVisual(submittingPaidLocalIds.current);
  };

  const updateDeletePaymentIdsVisualState = () => {
    setSubmittingDeletePaymentIdsVisual(submittingDeletePaymentIds.current);
  };

  const showSuccessToast = () => {
    MainToaster.show({
      message: `${formatMessage(commonMessages.success)}`,
      icon: IconNames.TICK,
      intent: Intent.SUCCESS,
    });
  };

  const showErrorToast = (error: Error) => {
    MainToaster.show({
      message: `${formatMessage(commonMessages.genericError)}: ${error.message}`,
      icon: IconNames.DISABLE,
      intent: Intent.DANGER,
    });
  };

  const onDeleteClick = async (id: PaymentId) => {
    submittingDeletePaymentIds.current = [...submittingDeletePaymentIds.current, id];
    updateDeletePaymentIdsVisualState();
    try {
      await deletePayment({
        variables: {
          groupId,
          paymentId: id,
        },
      });
      showSuccessToast();
    } catch (e) {
      showErrorToast(e as Error);
    } finally {
      submittingDeletePaymentIds.current = submittingDeletePaymentIds.current.filter(item => item !== id);
      updateDeletePaymentIdsVisualState();
    }
  };

  const onMarkPaidClick = async (paymentData: PaymentInputData) => {
    const localId = generateLocalPaymentId(paymentData.payerId, paymentData.payeeId, paymentData.currencyCode);
    submittingPaidLocalIds.current = [...submittingPaidLocalIds.current, localId];
    updatePaidLocalIdsVisualState();
    try {
      await createPayment({
        variables: {
          groupId,
          input: { ...paymentData, amount: scaleAmountToCents(paymentData) },
        },
      });
      showSuccessToast();
    } catch (e) {
      showErrorToast(e as Error);
    } finally {
      submittingPaidLocalIds.current = submittingPaidLocalIds.current.filter(id => id !== localId);
      updatePaidLocalIdsVisualState();
    }
  };
  return (
    <>
      <Container left={false} right={false} bottom>
        {dues.length ? (
          <EnhancedList
            defaultIconName="person"
            defaultIconBgColor={ICON_BG_COLOR.default}
            defaultIconColor={ICON_COLOR.defaultOverBg}
            items={sortBy(dues, due => (isDueMine(due) ? -1 : 1)).map(due => {
              const { debtor, creditor, currencyCode, amount } = due;
              const localPaymentId = generateLocalPaymentId(due.debtor.id, due.creditor.id, due.currencyCode);
              const HeaderTagName = isDueMine(due) ? 'strong' : 'span';

              return {
                id: localPaymentId,
                header: (
                  <HeaderTagName className="text-size-increased">
                    <FormattedMessage
                      {...messages.dueListItem}
                      values={{
                        debtor: debtor.displayName,
                        creditor: creditor.displayName,
                        amount: <CurrencyAmount amount={amount} currencyCode={currencyCode} />,
                      }}
                    />
                  </HeaderTagName>
                ),
                actions: (
                  <Button
                    intent={Intent.SUCCESS}
                    outlined
                    icon={IconNames.TICK}
                    loading={submittingPaidLocalIdsVisual.includes(localPaymentId)}
                    onClick={() => {
                      onMarkPaidClick({ payeeId: creditor.id, payerId: debtor.id, currencyCode, amount });
                    }}
                  >
                    <FormattedMessage {...messages.markPaid} />
                  </Button>
                ),
              };
            })}
          />
        ) : (
          <NonIdealState title={<FormattedMessage {...messages.allSettled} />} icon={IconNames.CLEAN} />
        )}
      </Container>

      <section>
        <Title tagName="h3" condensed>
          <FormattedMessage {...messages.paymentsHeading} />
        </Title>

        {payments.length ? (
          <EnhancedList
            condensed
            iconSize={24}
            defaultIconName="person"
            defaultIconBgColor={ICON_BG_COLOR.default}
            defaultIconColor={ICON_COLOR.defaultOverBg}
            items={sortBy(payments, payment => (isPaymentMine(payment) ? -1 : 1)).map(payment => {
              const { id, payer, payee, currencyCode, amount, paidAt } = payment;
              const HeaderTagName = isPaymentMine(payment) ? 'strong' : 'span';
              return {
                id,
                header: (
                  <HeaderTagName>
                    <FormattedMessage
                      {...messages.paymentListItem}
                      values={{
                        payer: payer.displayName,
                        payee: payee.displayName,
                        amount: <CurrencyAmount amount={amount} currencyCode={currencyCode} />,
                        date: <FormattedDate value={new Date(parseInt(paidAt)).toISOString()} />,
                      }}
                    />
                  </HeaderTagName>
                ),
                actions: (
                  <Button
                    intent={Intent.DANGER}
                    outlined
                    icon={IconNames.CROSS}
                    onClick={() => onDeleteClick(id)}
                    loading={submittingDeletePaymentIdsVisual.includes(id)}
                  >
                    <FormattedMessage {...commonMessages.actionDelete} />
                  </Button>
                ),
              };
            })}
          />
        ) : (
          <p>
            <FormattedMessage {...messages.noPayments} />
          </p>
        )}
      </section>
    </>
  );
};
