import { Button, MenuItem } from '@blueprintjs/core';
import { IItemRendererProps, ItemRenderer, Select } from '@blueprintjs/select';
import classNames from 'classnames';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { LocaleContext } from '../../contexts/LocaleContext';
import { AvailableLocale, locales, languageNames } from '../../lang';

import style from './style.module.scss';

type LanguageOption = {
  value: AvailableLocale;
  label: string;
};

const LanguageOptionSelect = Select.ofType<LanguageOption>();

const itemRenderer: ItemRenderer<LanguageOption> = (
  { value, label },
  { handleClick, modifiers }: IItemRendererProps
) => (
  <MenuItem
    key={value}
    disabled={modifiers.disabled}
    text={
      <>
        <span className={classNames('mr-tiny', 'fi', `fi-${getCountryName(value)}`, 'fis', style.flag)} /> {label}
      </>
    }
    onClick={handleClick}
  />
);

type Props = {
  className?: string;
};

export const LocaleSwitch: React.FC<Props> = ({ className }) => {
  const history = useHistory();
  const location = useLocation();
  const { locale } = React.useContext(LocaleContext);

  const onItemSelect = (option: LanguageOption) => {
    handleLocaleChange(option.value);
  };

  const handleLocaleChange = React.useCallback(
    (locale: AvailableLocale) => {
      const regex = new RegExp(`^/(${locales.join('|')})`);

      const currentUrl = `${location.pathname}${location.search || ''}${location.hash || ''}`;
      const url = currentUrl.replace(regex, `/${locale}`);

      history.push(url);
    },
    [history, location]
  );

  return (
    <LanguageOptionSelect
      className={className}
      filterable={false}
      items={locales.map(getLocaleOption)}
      itemRenderer={itemRenderer}
      onItemSelect={onItemSelect}
      itemsEqual={areOptionsEqual}
    >
      <Button minimal>
        <span className={classNames('fi', `fi-${getCountryName(locale)}`, 'fis', style.flag)} />
      </Button>
    </LanguageOptionSelect>
  );
};

function getLocaleOption(locale: AvailableLocale): LanguageOption {
  return { value: locale, label: languageNames[locale as AvailableLocale] };
}

function getCountryName(locale: AvailableLocale) {
  if (locale === 'en') return 'gb';
  return locale;
}

function areOptionsEqual(option1: LanguageOption, option2: LanguageOption): boolean {
  return option1.value === option2.value;
}
