import { Intent } from '@blueprintjs/core';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm, SubmitHandler, Control } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { isExistingInstance } from '../../models/ExistingInstance';

import { ProfileInput } from '../../models/User';
import { commonMessages } from '../../utils/commonMessages';
import { getRoutePrivacy, getRouteTerms } from '../../utils/navRoutes';
import { validationMessages } from '../../utils/validationMessages';
import yup from '../../utils/yup';
import { Avatar } from '../Avatar';
import { LocaleLink } from '../LocaleLink';
import { MainButton } from '../MainButton';
import { Field } from '../_form/Field';

type Values = ProfileInput & {
  password?: string;
  avatar?: File | string | null;
  email?: string;
  consent?: boolean;
};

export type Props = {
  onSubmit: (formData: Values) => void;
  initialData?: Values;
  isSubmitting?: boolean;
  withPasswordField?: boolean;
  withAvatarField?: boolean;
  withEmailField?: boolean;
  withConsentField?: boolean;
};

export const ProfileForm: React.FC<Props> = ({
  initialData,
  onSubmit,
  isSubmitting,
  withPasswordField,
  withAvatarField,
  withEmailField,
  withConsentField,
}) => {
  const schema = yup.object().shape({
    displayName: yup.string().required(),
    ...(withPasswordField
      ? {
          password: yup.string().required().min(8),
        }
      : {}),
    ...(withEmailField
      ? {
          email: yup.string().required().email(),
        }
      : {}),
    ...(withConsentField
      ? {
          consent: yup.boolean().required().oneOf([true], validationMessages.mixedRequired),
        }
      : {}),
  });

  const emptyInitialValues: Values = {
    displayName: '',
    avatar: null,
    ...(withPasswordField ? { password: '' } : {}),
    ...(withEmailField ? { email: '' } : {}),
    ...(withConsentField ? { consent: false } : {}),
  };

  const initialDataFilled = {
    ...emptyInitialValues,
    ...(initialData || {}),
  };

  const isNew = !isExistingInstance(initialDataFilled);

  const { control, handleSubmit } = useForm<Values>({
    resolver: yupResolver(schema),
    defaultValues: initialDataFilled,
    mode: 'onChange',
    shouldUnregister: true,
  });

  const onFormSubmit: SubmitHandler<Values> = data => {
    const sendableData = { ...data };
    delete sendableData.consent;

    //prevent updates to empty
    if (!withPasswordField) {
      delete sendableData.password;
    }
    if (!withEmailField) {
      delete sendableData.email;
    }
    if (!withAvatarField) {
      delete sendableData.avatar;
    }
    onSubmit(sendableData);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      {withAvatarField && (
        <Field
          type="photo"
          renderPreviewAs={Avatar}
          name="avatar"
          labelDescriptor={commonMessages.avatar}
          placeholderDescriptor={commonMessages.fileInputPlaceholder}
          control={(control as unknown) as Control<Record<string, unknown>>}
        />
      )}

      <Field isRequired name="displayName" control={(control as unknown) as Control<Record<string, unknown>>} />

      {withEmailField && (
        <Field name="email" isRequired control={(control as unknown) as Control<Record<string, unknown>>} />
      )}

      {withPasswordField && (
        <Field
          name="password"
          type="password"
          isRequired
          control={(control as unknown) as Control<Record<string, unknown>>}
        />
      )}

      {withConsentField && (
        <Field
          name="consent"
          type="checkbox"
          isRequired
          labelContent={
            <FormattedMessage
              {...commonMessages.legalConsent}
              values={{
                termsLink: (
                  <LocaleLink to={getRouteTerms()} target="_blank">
                    <FormattedMessage {...commonMessages.navTerms} />
                  </LocaleLink>
                ),
                privacyLink: (
                  <LocaleLink to={getRoutePrivacy()} target="_blank">
                    <FormattedMessage {...commonMessages.navPrivacy} />
                  </LocaleLink>
                ),
              }}
            />
          }
          control={(control as unknown) as Control<Record<string, unknown>>}
        />
      )}

      <MainButton type="submit" intent={Intent.SUCCESS} loading={isSubmitting}>
        <FormattedMessage {...(isNew ? commonMessages.authSignUp : commonMessages.actionSave)} />
      </MainButton>
    </form>
  );
};
