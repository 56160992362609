import { Button, ButtonProps, Classes, Dialog, DialogProps } from '@blueprintjs/core';
import React from 'react';

type Props = {
  toggleButtonProps?: Partial<ButtonProps>;
  confirmButtonProps?: Partial<ButtonProps>;
  cancelButtonProps?: Partial<ButtonProps>;
  dialogProps?: Partial<DialogProps>;
  actionButtons?: (Partial<ButtonProps> & { preventClose?: boolean })[];
};

export const DialogButton: React.FC<Props> = ({
  children,
  toggleButtonProps = {},
  actionButtons = [],
  dialogProps = {},
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleButtonClick = React.useCallback(() => setIsOpen(!isOpen), []);
  const handleClose = React.useCallback(() => setIsOpen(false), []);

  return (
    <>
      <Button {...toggleButtonProps} onClick={handleButtonClick} />

      <Dialog {...dialogProps} isOpen={isOpen} onClose={handleClose}>
        <section className={Classes.DIALOG_BODY}>{children}</section>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {actionButtons.map(({ preventClose, ...props }, i) => {
              const actionButtonOnClick = (e: React.MouseEvent<HTMLElement>) => {
                if (props.onClick) props.onClick(e);
                if (!preventClose) handleClose();
              };
              return <Button key={i} {...props} onClick={actionButtonOnClick} />;
            })}
          </div>
        </div>
      </Dialog>
    </>
  );
};
