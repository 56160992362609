import { defineMessages } from 'react-intl';

export const commonMessages = defineMessages({
  title: {
    id: 'commonTitle',
    defaultMessage: 'Settler',
  },
  navHome: {
    id: 'commonNavHomeLabel',
    defaultMessage: 'Home',
  },
  navAbout: {
    id: 'commonNavAboutLabel',
    defaultMessage: 'About',
  },
  navSettings: {
    id: 'commonNavSettingsLabel',
    defaultMessage: 'Settings',
  },
  navTerms: {
    id: 'commonNavTermsLabel',
    defaultMessage: 'Terms of service',
  },
  navPrivacy: {
    id: 'commonNavPrivacyLabel',
    defaultMessage: 'Privacy policy',
  },
  authChangePassword: {
    id: 'commonAuthChangePasswordLabel',
    defaultMessage: 'Change password',
  },
  authResetPassword: {
    id: 'commonAuthResetPasswordLabel',
    defaultMessage: 'Reset password',
  },
  authSignIn: {
    id: 'commonAuthSignInLabel',
    defaultMessage: 'Sign in',
  },
  authSignUp: {
    id: 'commonAuthSignUpLabel',
    defaultMessage: 'Sign up',
  },
  authSignUpConfirm: {
    id: 'commonAuthSignUpConfirmLabel',
    defaultMessage: 'Sign up confirmation',
  },
  authSignOut: {
    id: 'commonAuthSignOutLabel',
    defaultMessage: 'Sign out',
  },
  menu: {
    id: 'commonMenu',
    defaultMessage: 'Menu',
  },
  noResults: {
    id: 'commonNoResults',
    defaultMessage: 'No results.',
  },
  amountMissing: {
    id: 'commonAmountMissing',
    defaultMessage: '{delta} missing',
  },
  amountExceeded: {
    id: 'commonAmountExceeded',
    defaultMessage: 'exceeded by {delta}',
  },
  visibilityHide: {
    id: 'commonVisibilityHide',
    defaultMessage: 'Hide',
  },
  visibilityShow: {
    id: 'commonVisibilityShow',
    defaultMessage: 'Show',
  },
  amount: {
    id: 'commonAmount',
    defaultMessage: 'Amount',
  },
  amounts: {
    id: 'commonAmounts',
    defaultMessage: 'amounts',
  },
  details: {
    id: 'commonDetails',
    defaultMessage: 'details',
  },
  history: {
    id: 'commonHistory',
    defaultMessage: 'History',
  },
  balance: {
    id: 'commonBalance',
    defaultMessage: 'Balance',
  },
  expenses: {
    id: 'commonExpenses',
    defaultMessage: 'Expenses',
  },
  expenseAdd: {
    id: 'commonExpenseAdd',
    defaultMessage: 'Add new expense',
  },
  expenseEdit: {
    id: 'commonExpenseEdit',
    defaultMessage: 'Edit expense',
  },
  loading: {
    id: 'commonLoading',
    defaultMessage: 'Loading',
  },
  groupsYour: {
    id: 'commonGroupsYour',
    defaultMessage: 'Your groups',
  },
  groupInvites: {
    id: 'commonGroupInvites',
    defaultMessage: 'Group invites',
  },
  groupAdd: {
    id: 'commonGroupAdd',
    defaultMessage: 'Create new group',
  },
  groupEdit: {
    id: 'commonGroupEdit',
    defaultMessage: 'Edit group',
  },
  settle: {
    id: 'commonSettle',
    defaultMessage: 'Settle',
  },
  success: {
    id: 'commonSuccess',
    defaultMessage: 'Success',
  },
  fileInputPlaceholder: {
    id: 'commonFileInputPlaceholder',
    defaultMessage: 'Choose a file',
  },
  fileInputBrowse: {
    id: 'commonFileInputBrowse',
    defaultMessage: 'Browse',
  },
  password: {
    id: 'commonPasswordLabel',
    defaultMessage: 'Password',
  },
  passwordOld: {
    id: 'commonPasswordOldLabel',
    defaultMessage: 'Old password',
  },
  passwordNew: {
    id: 'commonPasswordNewLabel',
    defaultMessage: 'New password',
  },
  legalConsent: {
    id: 'commonLegalConsentLabel',
    defaultMessage: 'I am familiar with and agree to {termsLink} and {privacyLink}',
  },
  displayName: {
    id: 'commonDisplayNameLabel',
    defaultMessage: 'Name',
  },
  avatar: {
    id: 'commonAvatarLabel',
    defaultMessage: 'Photo',
  },
  profileData: {
    id: 'commonProfileDataLabel',
    defaultMessage: 'Profile data',
  },
  description: {
    id: 'commonDescriptionLabel',
    defaultMessage: 'Description',
  },
  members: {
    id: 'commonMembersLabel',
    defaultMessage: 'Members',
  },
  currency: {
    id: 'commonCurrency',
    defaultMessage: 'Currency',
  },
  settlementCurrency: {
    id: 'commonSettlementCurrency',
    defaultMessage: 'Settlement currency',
  },
  data: {
    id: 'commonDataLabel',
    defaultMessage: 'Data',
  },
  actionDelete: {
    id: 'commonActionDelete',
    defaultMessage: 'Delete',
  },
  actionAdd: {
    id: 'commonActionAdd',
    defaultMessage: 'Add',
  },
  actionCancel: {
    id: 'commonActionCancel',
    defaultMessage: 'Cancel',
  },
  actionGoBack: {
    id: 'commonActionGoBack',
    defaultMessage: 'Go back',
  },
  actionClose: {
    id: 'commonActionClose',
    defaultMessage: 'Close',
  },
  actionClear: {
    id: 'commonActionClear',
    defaultMessage: 'Clear',
  },
  actionSave: {
    id: 'commonActionSave',
    defaultMessage: 'Save',
  },
  actionLeave: {
    id: 'commonActionLeave',
    defaultMessage: 'Leave',
  },
  actionReject: {
    id: 'commonActionReject',
    defaultMessage: 'Reject',
  },
  actionAccept: {
    id: 'commonActionAccept',
    defaultMessage: 'Accept',
  },
  actionEdit: {
    id: 'commonActionEdit',
    defaultMessage: 'Edit',
  },
  actionCopy: {
    id: 'commonActionCopy',
    defaultMessage: 'Copy',
  },
  genericError: {
    id: 'commonGenericError',
    defaultMessage: 'This did not work',
  },
  confirmationLabel: {
    id: 'commonConfirmationLabel',
    defaultMessage: 'Please confirm',
  },
  timeToday: {
    id: 'commonTimeToday',
    defaultMessage: 'today',
  },
  timeYesterday: {
    id: 'commonTimeYesterday',
    defaultMessage: 'yesterday',
  },
  comingSoon: {
    id: 'commonComingSoon',
    defaultMessage: 'Coming soon!',
  },
  filters: {
    id: 'commonFilters',
    defaultMessage: 'Filters',
  },
  rangeFrom: {
    id: 'commonRangeFrom',
    defaultMessage: 'from',
  },
  rangeTo: {
    id: 'commonRangeTo',
    defaultMessage: 'to',
  },
  dateRangeLabel: {
    id: 'commonDateRangeLabel',
    defaultMessage: 'Date range',
  },
  datePlaceholder: {
    id: 'commonDatePlaceholder',
    defaultMessage: 'Select date',
  },
  text: {
    id: 'commonText',
    defaultMessage: 'text',
  },
});
