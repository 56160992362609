import { useMutation } from '@apollo/client';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Redirect } from 'react-router';
import { pick, uniqBy } from 'lodash';

import { GroupDataInfo } from '../../../../models/Group';
import { GroupMemberId } from '../../../../models/GroupMember';
import { Due } from '../../../../models/Due';

import {
  GET_GROUP,
  GET_USER_GROUPS,
  UPDATE_GROUP,
  DELETE_GROUP,
  INVITE_TO_GROUP_BY_EMAILS,
  REMOVE_GROUP_MEMBER,
} from '../../../../api/queries';

import { LocaleContext } from '../../../../contexts/LocaleContext';
import { GroupContext } from '../../../../contexts/GroupContext';

import { getRouteGroupList, getRouteGroupShow } from '../../../../utils/navRoutes';
import { commonMessages } from '../../../../utils/commonMessages';
import { MainToaster } from '../../../../utils/toaster';
import { calculateDues } from '../../../../utils/calculateDues';

import { Page } from '../../../Page';
import { Container, Size as ContainerSize } from '../../../Container';
import { Title } from '../../../Title';
import { GroupForm } from '../../../GroupForm';
import { MemberSelection, MemberSelectListSimple } from '../../../_form/MemberSelect/ListSimple';
import { DialogButton } from '../../../DialogButton';
import { BackLink } from '../../../BackLink';

import { messages } from './messages';

export const GroupEdit: React.FC = () => {
  const [isSubmittingDelete, setIsSubmittingDelete] = useState(false);
  const [isSubmittingInfo, setIsSubmittingInfo] = useState(false);
  const [isSubmittingAdd, setIsSubmittingAdd] = useState(false);
  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);
  const [submittingDeleteIdsVisual, setSubmittingDeleteIdsVisual] = useState<GroupMemberId[]>([]);
  const submittingDeleteIds = useRef<GroupMemberId[]>([]);

  const { formatMessage } = useIntl();

  const { group, currentMember } = useContext(GroupContext);
  const dues: Due[] = useMemo(() => calculateDues({ expenses: group.expenses, payments: group.payments }), [
    group.expenses,
    group.payments,
  ]);

  const { locale } = useContext(LocaleContext);
  const routePrefix = `/${locale}`;

  const showSuccessToast = () => {
    MainToaster.show({
      message: `${formatMessage(commonMessages.success)}`,
      icon: IconNames.TICK,
      intent: Intent.SUCCESS,
    });
  };

  const showErrorToast = (error: Error) => {
    MainToaster.show({
      message: `${formatMessage(commonMessages.genericError)}: ${error.message}`,
      icon: IconNames.DISABLE,
      intent: Intent.DANGER,
    });
  };

  const [updateGroup] = useMutation(UPDATE_GROUP, {
    refetchQueries: [GET_GROUP, GET_USER_GROUPS],
  });
  const [inviteToGroupByEmails] = useMutation(INVITE_TO_GROUP_BY_EMAILS, {
    refetchQueries: [GET_GROUP],
  });
  const [removeGroupMember] = useMutation(REMOVE_GROUP_MEMBER, {
    refetchQueries: [GET_GROUP, GET_USER_GROUPS],
  });
  const [deleteGroup] = useMutation(DELETE_GROUP, { refetchQueries: [GET_USER_GROUPS] });

  const handleInfoSubmit = async (groupData: GroupDataInfo) => {
    setIsSubmittingInfo(true);
    try {
      const variables = {
        id: group.id,
        ...pick(groupData, [
          'displayName',
          'description',
          'balanceCurrencyKind',
          'selectedSingleCurrency',
          'exchangeRates',
        ]),
      };
      await updateGroup({ variables });

      showSuccessToast();
    } catch (e) {
      showErrorToast(e as Error);
    } finally {
      setIsSubmittingInfo(false);
    }
  };

  const handleMembersAdd = async ({ created }: MemberSelection) => {
    setIsSubmittingAdd(true);
    try {
      //TODO: restore when introducing contacts
      // if (selected.length) await groupMembersAdd({ groupId: group.id, memberIds: selected });
      if (created.length) await inviteToGroupByEmails({ variables: { groupId: group.id, emails: created } });
      showSuccessToast();
    } catch (e) {
      showErrorToast(e as Error);
    } finally {
      setIsSubmittingAdd(false);
    }
  };

  const updateDeleteIdsVisualState = () => {
    setSubmittingDeleteIdsVisual(submittingDeleteIds.current);
  };

  const handleMemberRemove = async (memberId: GroupMemberId) => {
    submittingDeleteIds.current = [...submittingDeleteIds.current, memberId];
    updateDeleteIdsVisualState();
    try {
      await removeGroupMember({ variables: { groupId: group.id, memberId } });
      showSuccessToast();
    } catch (e) {
      showErrorToast(e as Error);
    } finally {
      submittingDeleteIds.current = submittingDeleteIds.current.filter(id => id !== memberId);
      updateDeleteIdsVisualState();
    }
  };

  const onGroupDelete = async () => {
    setIsSubmittingDelete(true);
    try {
      await deleteGroup({ variables: { id: group.id } });

      setShouldRedirectToList(true);
      showSuccessToast();
    } catch (e) {
      showErrorToast(e as Error);
    } finally {
      setIsSubmittingDelete(false);
    }
  };

  if (shouldRedirectToList) {
    return <Redirect to={`${routePrefix}/${getRouteGroupList()}`} />;
  }

  return (
    <Page size={ContainerSize.SM}>
      <Container size={ContainerSize.SM} top bottom>
        <Title
          condensed
          center
          wrapped
          backLink={
            <BackLink to={getRouteGroupShow({ groupId: group.id })} messageDescriptor={commonMessages.actionGoBack} />
          }
          secondaryContent={
            <>
              <DialogButton
                dialogProps={{ title: <FormattedMessage {...commonMessages.confirmationLabel} /> }}
                toggleButtonProps={{
                  intent: Intent.DANGER,
                  outlined: true,
                  small: true,
                  text: <FormattedMessage {...commonMessages.actionDelete} />,
                  loading: isSubmittingDelete,
                  disabled: isSubmittingAdd || isSubmittingInfo || submittingDeleteIdsVisual.length > 0,
                }}
                actionButtons={[
                  {
                    onClick: onGroupDelete,
                    intent: Intent.DANGER,
                    text: <FormattedMessage {...commonMessages.actionDelete} />,
                  },
                  {
                    text: <FormattedMessage {...commonMessages.actionCancel} />,
                  },
                ]}
              >
                <FormattedMessage {...messages.deleteConfirmationMessage} values={{ displayName: group.displayName }} />
              </DialogButton>
            </>
          }
        >
          <FormattedMessage {...commonMessages.groupEdit} /> "{group.displayName}"
        </Title>

        <Title tagName="h2" condensed>
          <FormattedMessage {...commonMessages.data} />
        </Title>

        <GroupForm
          disabled={isSubmittingDelete}
          onSubmit={handleInfoSubmit}
          initialData={group}
          withMembersField={false}
          isSubmitting={isSubmittingInfo}
        />
      </Container>

      <Container size={ContainerSize.SM} top bottom>
        <Title tagName="h2">
          <FormattedMessage {...commonMessages.members} />
        </Title>

        <MemberSelectListSimple
          disabled={isSubmittingDelete}
          members={uniqBy([currentMember, ...group.members], 'id')}
          initialMemberIds={group.members.map(({ id }) => id)}
          onAdd={handleMembersAdd}
          isSubmittingAdd={isSubmittingAdd}
          onDelete={handleMemberRemove}
          submittingDeleteIds={submittingDeleteIdsVisual}
          preventRemoveWhen={id =>
            submittingDeleteIdsVisual.includes(id) ||
            dues.some(({ debtor, creditor }) => debtor.id === id || creditor.id === id)
          }
        />
      </Container>
    </Page>
  );
};
