import { Checkbox, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { omit, pick } from 'lodash';
import React, { ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { Control, ControllerRenderProps, useController } from 'react-hook-form';

import { ImageInput, Props as ImageInputProps } from '../ImageInput';
import { PasswordField } from '../PasswordField';

import { commonMessages } from '../../../utils/commonMessages';
import { ValidationMessageDescriptor } from '../../../utils/yup';

type BaseProps = {
  name: string;
  id?: string;
  type?: string;
  isRequired?: boolean;
  labelDescriptor?: MessageDescriptor;
  labelContent?: ReactNode;
  labelInfo?: ReactNode;
  placeholder?: string;
  placeholderDescriptor?: MessageDescriptor;
  defaultValue?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  hidden?: boolean;
  inputMode?: React.HTMLAttributes<HTMLLIElement>['inputMode'];
  renderPreviewAs?: ImageInputProps['renderPreviewAs'];
};

export type InputPropsType = BaseProps & Pick<ControllerRenderProps, 'onChange' | 'onBlur' | 'value' | 'name'>;

type Props = BaseProps & {
  control: Control;
  render?: (inputProps: InputPropsType) => React.ReactElement;
};

export const Field: React.FC<Props> = ({
  name,
  id = name,
  type = 'text',
  isRequired,
  labelDescriptor,
  labelContent,
  labelInfo,
  placeholder,
  placeholderDescriptor,
  control,
  render,
  hidden,
  inputMode,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const {
    field,
    fieldState: { isDirty, error },
  } = useController({ name, control });

  const labelInfoContent = labelInfo ? labelInfo : isRequired ? '*' : '';
  const errorMessageProps = error ? (error.message as ValidationMessageDescriptor) : null;
  const intent = errorMessageProps ? Intent.DANGER : isDirty ? Intent.SUCCESS : Intent.NONE;
  const localizedName =
    name in commonMessages ? formatMessage((commonMessages as Record<string, MessageDescriptor>)[name]) : '';
  const placeholderContent: string = placeholder
    ? placeholder
    : placeholderDescriptor
    ? formatMessage(placeholderDescriptor)
    : labelDescriptor
    ? formatMessage(labelDescriptor)
    : localizedName || name;

  const usedInputMode = inputMode || (type === 'email' || name === 'email' ? 'email' : undefined);
  const inputModePropObject = usedInputMode ? { inputMode: usedInputMode } : {};

  const inputProps = {
    ...pick(field, 'name', 'onChange', 'onBlur', 'value'),
    type,
    id,
    placeholder: placeholderContent,
    intent,
    hidden,
    ...omit(props, 'inputMode'),
  };

  const labelContentText: string = labelDescriptor ? formatMessage(labelDescriptor) : localizedName || name;
  const labelFinalContent: ReactNode =
    labelContent || labelContentText.charAt(0).toUpperCase() + labelContentText.slice(1);

  const formGroupProps = {
    label: labelFinalContent,
    labelFor: id,
    labelInfo: labelInfoContent,
    intent,
    helperText: intent === Intent.DANGER && errorMessageProps ? <FormattedMessage {...errorMessageProps} /> : '',
  };

  const content =
    type === 'password' ? (
      <PasswordField {...inputProps} />
    ) : type === 'photo' ? (
      <ImageInput {...inputProps} formGroupProps={formGroupProps} />
    ) : type === 'select' ? (
      <div className="bp3-select bp3-fill">
        <select {...inputProps} />
      </div>
    ) : type === 'checkbox' ? (
      <Checkbox {...inputProps} checked={!!inputProps.value}>
        <span>{formGroupProps.label}</span>&nbsp;
        {formGroupProps.labelInfo && <span className="bp3-text-muted">{formGroupProps.labelInfo}</span>}
      </Checkbox>
    ) : render ? (
      render({ ...inputProps, ...inputModePropObject })
    ) : (
      <InputGroup {...inputProps} {...inputModePropObject} />
    );

  return hidden || type === 'photo' ? (
    content
  ) : (
    <FormGroup {...(type === 'checkbox' ? omit(formGroupProps, ['label', 'labelFor', 'labelInfo']) : formGroupProps)}>
      {content}
    </FormGroup>
  );
};
