import { Button, Intent, Tab, Tabs } from '@blueprintjs/core';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { pick } from 'lodash';
import { IconNames } from '@blueprintjs/icons';

import { CurrencyCode } from '../../../../models/Currency';

import { GroupContext } from '../../../../contexts/GroupContext';

import { getRouteGroupEdit, getRouteGroupList } from '../../../../utils/navRoutes';
import { commonMessages } from '../../../../utils/commonMessages';

import { Page } from '../../../Page';
import { Container, Size as ContainerSize } from '../../../Container';
import { Title } from '../../../Title';
import { LocaleLink } from '../../../LocaleLink';
import { BackLink } from '../../../BackLink';
import {
  FilterKind,
  FilterConjunctive,
  getActiveFilters,
  FilterConfig,
  FiltersDialogButton,
  FilterValue,
  isValueTruthy,
} from '../../../Filters';

import { ExpenseList } from './components/ExpenseList';
import { GroupBalance } from './components/GroupBalance';
import { ExpenseFilterId, sortExpenses, filterExpenses } from './helpers';
import { messages } from './messages';
import style from './style.module.scss';

const TAB_HASH_MAP = {
  expenses: { id: 'expenses', hash: '' },
  balance: { id: 'balance', hash: 'balance' },
  history: { id: 'history', hash: 'history' },
};

export const GroupShow: React.FC = () => {
  const location = useLocation();
  const locationFilterParams = new URLSearchParams(location.search).get('filters');
  const locationFilters = locationFilterParams ? JSON.parse(locationFilterParams) : {};

  const history = useHistory();
  const { group } = useContext(GroupContext);

  const initialFilters = [
    {
      id: ExpenseFilterId.EXPENSE_PAID_BY,
      label: <FormattedMessage {...messages.paidBy} />,
      kind: FilterKind.MEMBER_SINGLE,
      options: group.members.map(({ id, displayName }) => ({ value: id, label: displayName })),
    },
    {
      id: ExpenseFilterId.EXPENSE_PAID_FOR,
      label: <FormattedMessage {...messages.paidFor} />,
      kind: FilterKind.MEMBER_MULTIPLE,
      options: group.members.map(({ id, displayName }) => ({ value: id, label: displayName })),
      optionsConjunctive: FilterConjunctive.AND,
    },
    {
      id: ExpenseFilterId.EXPENSE_DATE_RANGE,
      label: <FormattedMessage {...commonMessages.dateRangeLabel} />,
      kind: FilterKind.DATE_RANGE,
    },
    {
      id: ExpenseFilterId.EXPENSE_CURRENCY,
      label: <FormattedMessage {...commonMessages.currency} />,
      kind: FilterKind.CURRENCY,
      options: group.members.map(({ id, displayName }) => ({ value: id, label: displayName })),
    },
    {
      id: ExpenseFilterId.EXPENSE_AMOUNT_RANGE,
      label: <FormattedMessage {...commonMessages.amount} />,
      kind: FilterKind.NUMBER_RANGE,
    },
    {
      id: ExpenseFilterId.EXPENSE_SUBJECT,
      label: <FormattedMessage {...commonMessages.text} />,
      kind: FilterKind.TEXT,
    },
  ].map(filter => {
    return { ...filter, value: locationFilters[filter.id] };
  });
  const initialActiveFilters = getActiveFilters(initialFilters);

  const [filters, setFilters] = useState<FilterConfig[]>(initialFilters);
  const [filteredExpenses, setFilteredExpenses] = useState(
    initialActiveFilters.length > 0 ? group.expenses : sortExpenses(filterExpenses(group.expenses, initialFilters))
  );

  const stringifyFilters = () => {
    const filtersObj = filters.reduce((filterObj, { id, value }) => {
      if (isValueTruthy(value)) {
        filterObj[id] = value!;
      }
      return filterObj;
    }, {} as { [id: string]: FilterValue });
    return Object.keys(filtersObj).length > 0 ? `?filters=${JSON.stringify(filtersObj)}` : '';
  };

  const [filterQueryString, setFilterQueryString] = useState(stringifyFilters());
  const activeFilters = getActiveFilters(filters);
  useEffect(() => {
    setFilteredExpenses(sortExpenses(filterExpenses(group.expenses, filters)));
  }, [group.expenses]);

  useEffect(() => {
    history.push({
      ...pick(location, ['pathname', 'hash']),
      search: filterQueryString,
    });
  }, [filterQueryString]);

  useEffect(() => {
    setFilteredExpenses(sortExpenses(filterExpenses(group.expenses, filters)));
    setFilterQueryString(stringifyFilters());
  }, [filters]);

  const onTabChange = (tabId: string) => {
    const hash = TAB_HASH_MAP[tabId as keyof typeof TAB_HASH_MAP].hash || TAB_HASH_MAP.expenses.hash;
    history.push({
      ...pick(location, ['pathname', 'search']),
      hash: `#${hash}`,
    });
  };

  const initialTabId =
    Object.values(TAB_HASH_MAP).find(({ hash }) => hash === location.hash.replace('#', ''))?.id ||
    TAB_HASH_MAP.expenses.id;

  const setCurrencyFilterValue = (currencyCode: CurrencyCode) => {
    const index = filters.findIndex(({ id }) => id === ExpenseFilterId.EXPENSE_CURRENCY);
    if (index >= 0) {
      const newFilters = [...filters];
      newFilters[index].value = currencyCode;
      setFilters(newFilters);
    }
  };

  const onClearFilters = () => {
    setFilters(filters.map(filter => ({ ...filter, value: undefined })));
  };
  const filtersWidget = (
    <div>
      <FiltersDialogButton filters={filters} onChange={setFilters} resultsCount={filteredExpenses.length} />
      {activeFilters.length > 0 && (
        <Button minimal onClick={onClearFilters} intent={Intent.DANGER} icon={IconNames.CROSS} />
      )}
    </div>
  );

  return (
    <Page size={ContainerSize.SM}>
      <Container size={ContainerSize.SM} top tagName="div">
        <Title
          backLink={<BackLink to={getRouteGroupList()} messageDescriptor={commonMessages.groupsYour} />}
          condensed
          center
          wrapped
        >
          {group.displayName}
        </Title>
      </Container>

      {group.description && (
        <Container size={ContainerSize.SM} bottom centered>
          {group.description}
        </Container>
      )}

      <Container size={ContainerSize.SM}>
        <Tabs defaultSelectedTabId={initialTabId} large className={style.tabs} onChange={onTabChange}>
          <Tab
            id={TAB_HASH_MAP.expenses.id}
            title={<FormattedMessage {...commonMessages.expenses} />}
            panel={
              <ExpenseList
                filteredExpenses={filteredExpenses}
                filtersWidget={filtersWidget}
                redirectFromPath={`${location.pathname}${filterQueryString}`}
                setCurrencyFilterValue={setCurrencyFilterValue}
              />
            }
          />
          <Tab
            id={TAB_HASH_MAP.balance.id}
            title={<FormattedMessage {...commonMessages.balance} />}
            panel={<GroupBalance />}
          />
          <Tab
            id={TAB_HASH_MAP.history.id}
            title={<FormattedMessage {...commonMessages.history} />}
            // panel={<GroupHistory groupId={group.id} />}
            panel={
              <p className="bp3-text-muted text-center">
                <FormattedMessage {...commonMessages.comingSoon} />
              </p>
            }
          />
          <Tabs.Expander />

          <LocaleLink to={getRouteGroupEdit({ groupId: group.id })}>
            <FormattedMessage {...commonMessages.groupEdit} />
          </LocaleLink>
        </Tabs>
      </Container>
    </Page>
  );
};
