import { Intent } from '@blueprintjs/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useForm, Control, SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { SignInMutationVariables } from '../../../../models/_graphql';
import { commonMessages } from '../../../../utils/commonMessages';
import yup from '../../../../utils/yup';
import { getRouteResetPassword } from '../../../../utils/navRoutes';

import { SIGN_IN } from '../../../../api/queries';

import { RegistrationLayout } from '../_partials/RegistrationLayout';
import { Field } from '../../../_form/Field';
import { AuthContext } from '../../../../contexts/AuthContext';
import { LocaleLink } from '../../../LocaleLink';
import { MainButton } from '../../../MainButton';
import { Title } from '../../../Title';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const emptyInitialValues = {
  email: '',
  password: '',
};

export const SignIn: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
    reset,
    getValues,
  } = useForm<SignInMutationVariables>({
    resolver: yupResolver(schema),
    defaultValues: emptyInitialValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [signIn, { data, error }] = useMutation(SIGN_IN);
  const { updateUser, signOut } = useContext(AuthContext);
  const [hasError, setHasError] = useState(false);
  const isErrorVisible = hasError && !isDirty;

  useEffect(() => {
    if (error) {
      setHasError(true);
      signOut();
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      if (data.signIn) {
        updateUser(data.signIn);
      } else {
        signOut();
        setHasError(true);
      }
    }
  }, [data]);

  const onSubmit: SubmitHandler<SignInMutationVariables> = async variables => {
    reset(variables);
    //TODO: Add toast message
    await signIn({ variables });
  };

  return (
    <RegistrationLayout>
      <Title center condensed>
        <FormattedMessage {...commonMessages.authSignIn} />
      </Title>
      {isErrorVisible && (
        <p className="text-intent-danger">
          <FormattedMessage {...commonMessages.genericError} />
          <span>: {error ? error.message : ''}</span>
        </p>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field name="email" isRequired control={(control as unknown) as Control<Record<string, unknown>>} />

        <Field
          name="password"
          type="password"
          isRequired
          control={(control as unknown) as Control<Record<string, unknown>>}
        />

        <MainButton large type="submit" intent={Intent.SUCCESS} disabled={!isValid || isSubmitting || isErrorVisible}>
          <FormattedMessage {...commonMessages.authSignIn} />
        </MainButton>

        <p className="mb-zero text-center">
          <LocaleLink to={getRouteResetPassword({ email: getValues('email') })}>
            <FormattedMessage {...commonMessages.authResetPassword} />
          </LocaleLink>
        </p>
      </form>
    </RegistrationLayout>
  );
};
