export type CurrencyCode = 'USD' | 'CHF' | 'GBP' | 'PLN' | 'EUR';

export type Currency = {
  code: CurrencyCode;
  symbol: string;
  precision: number;
};

export type CurrencyPair = {
  baseCode: CurrencyCode;
  quoteCode: CurrencyCode;
};

export type ExchangeRate = CurrencyPair & {
  value: number;
};

export const CURRENCIES_MAP: { [key: string]: Currency } = {
  USD: { code: 'USD', symbol: '$', precision: 2 },
  CHF: { code: 'CHF', symbol: 'fr.', precision: 2 },
  GBP: { code: 'GBP', symbol: '£', precision: 2 },
  PLN: { code: 'PLN', symbol: 'zł', precision: 2 },
  EUR: { code: 'EUR', symbol: '€', precision: 2 },
};

export const ALL_CURRENCIES = ['USD', 'EUR', 'GBP', 'CHF', 'PLN'];
export const DEFAULT_CURRENCY_CODE = CURRENCIES_MAP.USD.code;
