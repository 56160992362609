import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  noItems: {
    id: 'expenseListNoItems',
    defaultMessage: 'This group has no expenses yet.',
  },
  noItemsDescription: {
    id: 'expenseListNoItemsDescription',
    defaultMessage: 'Add them now and start settling!',
  },
  noItemsFiltered: {
    id: 'expenseListNoItemsFiltered',
    defaultMessage: 'No expenses found.',
  },
  noItemsFilteredDescription: {
    id: 'expenseListNoItemsFilteredDescription',
    defaultMessage: 'Try changing the filters.',
  },
  paidBy: {
    id: 'expenseListPaidBy',
    defaultMessage: 'Paid by',
  },
  paidForShort: {
    id: 'expenseListPaidForShort',
    defaultMessage: 'for',
  },
  paidYou: {
    id: 'expenseListPaidYou',
    defaultMessage: 'you',
  },
  paidAnd: {
    id: 'expenseListPaidAnd',
    defaultMessage: 'and',
  },
  paidPeople: {
    id: 'expenseListPaidPeople',
    defaultMessage: '{number, plural, =0 {} one {# person} other {# persons}}',
  },
  totalFor: {
    id: 'expenseListTotalFor',
    defaultMessage: 'for',
  },
  summaryAllExpenses: {
    id: 'expenseListSummaryAllExpenses',
    defaultMessage: 'Showing all {countAll, plural, =0 {} one {# expense} other {# expenses}}',
  },
  summarySomeExpenses: {
    id: 'expenseListSummarySomeExpenses',
    defaultMessage: 'Showing {count} of {countAll, plural, =0 {} one {# expense} other {# expenses}}',
  },
});
