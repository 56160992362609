import { IconSize } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import classNames from 'classnames';
import React from 'react';
import { TransitionGroup } from 'react-transition-group';

import { ICON_SIZE } from '../../utils/visual';

import { ComposedIcon } from '../ComposedIcon';
import { TransitionGroupItem } from '../TransitionGroupItem';

import style from './style.module.scss';

export type Item = {
  id: string | number;
  header: React.ReactNode;
  detail?: React.ReactNode;
  iconName?: IconName;
  secondaryIconName?: IconName;
  iconColor?: string;
  iconBgColor?: string;
  actions?: React.ReactNode;
  groupName?: string;
};
export type Props = {
  items: Item[];
  iconSize?: IconSize;
  defaultIconName?: IconName;
  defaultIconColor?: string;
  defaultIconBgColor?: string;
  condensed?: boolean;
};
export const EnhancedList: React.FC<Props> = ({
  items,
  defaultIconName,
  iconSize = ICON_SIZE.listDefault,
  defaultIconColor = 'currentColor',
  defaultIconBgColor,
  condensed,
}) => {
  if (!items.length) return null;

  return (
    <TransitionGroup
      component="ol"
      className={classNames(style.list, {
        [style['list--withIcon']]: defaultIconName || items.some(({ iconName }) => !!iconName),
        [style['list--withActions']]: items.some(({ actions }) => !!actions),
        [style['list--condensed']]: condensed,
      })}
    >
      {items.map(
        ({ id, header, detail, iconName, iconColor, iconBgColor, secondaryIconName, actions, groupName }, i) => {
          const usableIconName: IconName | undefined = iconName || defaultIconName;
          const prevGroupName = items[i - 1] && items[i - 1].groupName && items[i - 1].groupName;

          return (
            <TransitionGroupItem
              key={id}
              data-groupname={groupName}
              className={classNames(style.row, {
                [style['row--withDetail']]: !!detail,
                [style['row--withGroupName']]: !!groupName && groupName !== prevGroupName,
              })}
            >
              <li>
                <>
                  {usableIconName && (
                    <span className={style.row__icon}>
                      <ComposedIcon
                        iconName={usableIconName}
                        secondaryIconName={secondaryIconName}
                        size={iconSize}
                        color={iconColor || defaultIconColor}
                        bgColor={iconBgColor || defaultIconBgColor}
                      />
                    </span>
                  )}

                  <span className={style.row__header}>{header}</span>

                  {detail && <span className={style.row__detail}>{detail}</span>}

                  {actions && <span className={style.row__actions}>{actions}</span>}
                </>
              </li>
            </TransitionGroupItem>
          );
        }
      )}
    </TransitionGroup>
  );
};
