import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { isEqual } from 'lodash';

import { User } from '../models/User';

import { CURRENT_USER, SIGN_OUT } from '../api/queries';

import { storage as lStorage } from '../utils/storage';
import { apolloClient } from '../utils/apolloClient';

type ContextProps = {
  user: User | null;
  isInitialized: boolean;
  signOut: () => void;
  updateUser: (newUser: User | null) => void;
};

export const AuthContext = React.createContext<ContextProps>({
  user: null,
  isInitialized: false,
  signOut: () => null,
  updateUser: () => null,
});

export type AuthProviderProps = Partial<ContextProps>;

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  user: initialUser = null,
  updateUser: initialUpdateUser,
  signOut: initialSignOut,
}) => {
  const { loading, data, refetch } = useQuery(CURRENT_USER);
  const [signOutMutate] = useMutation(SIGN_OUT);
  const [user, setUser] = React.useState<ContextProps['user']>(initialUser);
  const [isInitialized, setIsInitialized] = React.useState<ContextProps['isInitialized']>(false);

  const updateUser =
    initialUpdateUser ||
    (async (user: User | null) => {
      setUser(user);
      lStorage.set('user', user);
      if (user) {
        refetch();
      } else {
        apolloClient.cache.reset();
        await signOutMutate();
      }
    });

  useEffect(() => {
    if (!loading && data) {
      if (!isEqual(data.currentUser, user)) {
        updateUser(data.currentUser);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!initialUser) {
      const storageUser = (lStorage.get('user') as User | null) || null;

      if (storageUser) {
        setUser(storageUser);
      }

      lStorage.on('user', user => {
        if (!user) {
          return signOut();
        } else {
          setUser(user as User);
        }
      });

      setIsInitialized(true);
      return () => lStorage.off('user');
    }
  }, []);

  const signOut: ContextProps['signOut'] =
    initialSignOut ||
    (() => {
      updateUser(null);
    });

  return (
    <AuthContext.Provider
      value={{
        user,
        isInitialized,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
