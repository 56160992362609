import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  settleCurrencyKindLabelOriginal: {
    id: 'groupFormCurrencyKindLabelOriginal',
    defaultMessage: 'original - calculate the dues separately in each currency',
  },
  settleCurrencyKindLabelSingle: {
    id: 'groupFormCurrencyKindLabelSingle',
    defaultMessage: 'single - calculate all the dues in one selected currency',
  },
  selectedCurrencyLabel: {
    id: 'groupFormSelectedCurrencyLabel',
    defaultMessage: 'Currency',
  },
  exchangeRatesLabel: {
    id: 'groupFormExchangeRatesLabel',
    defaultMessage: 'Exchange rates',
  },
  exchangeRateSuggestedLabel: {
    id: 'groupFormExchangeRateSuggestedLabel',
    defaultMessage: 'Suggested',
  },
});
