import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { Props as LocaleLinkProps, LocaleLink } from '../LocaleLink';

import style from './style.module.scss';

type Props = LocaleLinkProps & {
  className?: string;
  messageDescriptor?: MessageDescriptor;
};

export const BackLink: React.FC<Props> = ({ className = '', messageDescriptor, children, ...other }) => {
  return (
    <LocaleLink className={classNames(className, style.wrapper)} {...other}>
      <Icon icon={IconNames.CHEVRON_LEFT} size={12} />
      {messageDescriptor ? <FormattedMessage {...messageDescriptor} /> : children}
    </LocaleLink>
  );
};
