import { useQuery } from '@apollo/client';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import { GET_EXCHANGE_RATES } from '../api/queries';
import { CurrencyPair, ExchangeRate } from '../models/Currency';

type ContextProps = {
  isInitialized: boolean;
  getExchangeRate: (currencyPair: CurrencyPair) => ExchangeRate | undefined;
  exchangeRatesByCurrency: { [key: string]: ExchangeRate[] };
  pipDenominator: number;
};

export const ExchangeRatesContext = React.createContext<ContextProps>({
  isInitialized: false,
  getExchangeRate: () => undefined,
  exchangeRatesByCurrency: {},
  pipDenominator: 10000,
});

export type ExchangeRatesProviderProps = Partial<ContextProps>;

export const ExchangeRatesProvider: React.FC<ExchangeRatesProviderProps> = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [exchangeRates, setExchangeRates] = useState<ExchangeRate[]>([]);
  const { data } = useQuery(GET_EXCHANGE_RATES);

  useEffect(() => {
    if (data?.getExchangeRates) {
      setExchangeRates(data.getExchangeRates.exchangeRates);
      setIsInitialized(true);
    }
  }, [data]);

  const getExchangeRate: ContextProps['getExchangeRate'] = ({ baseCode, quoteCode }) => {
    if (!isInitialized) {
      return undefined;
    }

    const exchangeRate = exchangeRates.find(({ baseCode: bc, quoteCode: qc }) => bc === baseCode && qc == quoteCode);

    return exchangeRate;
  };

  return (
    <ExchangeRatesContext.Provider
      value={{
        isInitialized,
        getExchangeRate,
        exchangeRatesByCurrency: groupBy(exchangeRates, 'baseCode'),
        pipDenominator: 10000,
      }}
    >
      {children}
    </ExchangeRatesContext.Provider>
  );
};
