import { CURRENCIES_MAP, Currency, CurrencyCode } from '../models/Currency';
import { ExpenseLike } from '../models/Expense';
import { GroupLike } from '../models/Group';

type Props = {
  amount: number;
  currency?: Currency;
  currencyCode?: CurrencyCode;
};

export const scaleAmountToCents = ({ amount, currency, currencyCode }: Props): number => {
  if (!(currency || currencyCode)) {
    throw new Error('Cannot scale amount without currency');
  } else {
    const _currency = currency || CURRENCIES_MAP[currencyCode!];
    return Math.round(amount * 10 ** _currency.precision);
  }
};

export const scaleAmountFromCents = ({ amount, currency, currencyCode }: Props): number => {
  if (!(currency || currencyCode)) {
    throw new Error('Cannot scale amount without currency');
  } else {
    const _currency = currency || CURRENCIES_MAP[currencyCode!];
    return Math.round(amount) / 10 ** _currency.precision;
  }
};

export function scaleExpenseToCents<T extends ExpenseLike>(expenseLike: T): T {
  return {
    ...expenseLike,
    amount: scaleAmountToCents(expenseLike),
    splitAmounts: expenseLike.splitAmounts.map((splitAmount: { amount: number }) => ({
      ...splitAmount,
      amount: scaleAmountToCents({ amount: splitAmount.amount, currencyCode: expenseLike.currencyCode }),
    })),
  };
}

export function scaleExpenseFromCents<T extends ExpenseLike>(expenseLike: T): T {
  return {
    ...expenseLike,
    amount: scaleAmountFromCents(expenseLike),
    splitAmounts: expenseLike.splitAmounts.map((splitAmount: { amount: number }) => ({
      ...splitAmount,
      amount: scaleAmountFromCents({ amount: splitAmount.amount, currencyCode: expenseLike.currencyCode }),
    })),
  };
}

export function scaleGroupFromCents<T extends GroupLike>(group: T): T {
  return {
    ...group,
    expenses: group.expenses.map(scaleExpenseFromCents),
    payments: group.payments.map(payment => ({
      ...payment,
      amount: scaleAmountFromCents(payment),
    })),
  };
}
