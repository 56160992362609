import { useMutation } from '@apollo/client';
import { Intent } from '@blueprintjs/core';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';

import { AuthContext } from '../../../../contexts/AuthContext';

import { SIGN_UP_CONFIRM } from '../../../../api/queries';

import { Title } from '../../../Title';
import { MainButton } from '../../../MainButton';
import { commonMessages } from '../../../../utils/commonMessages';
import { IndicatorLoading } from '../../../IndicatorLoading';
import { RegistrationLayout } from '../_partials/RegistrationLayout';

import { messages } from './messages';

export const SignUpConfirm: React.FC = () => {
  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const [signUpConfirm, { data, error, loading, called }] = useMutation(SIGN_UP_CONFIRM);
  const { updateUser } = useContext(AuthContext);

  const token = query.get('token');
  const email = query.get('email');

  const onConfirm = (): void => {
    signUpConfirm({ variables: { email, token } });
  };

  useEffect(() => {
    if (data && data.signUpConfirm) {
      updateUser(data.signUpConfirm);
    }
  }, [data]);

  return (
    <RegistrationLayout>
      <Title condensed center>
        <FormattedMessage {...commonMessages.authSignUpConfirm} />
      </Title>

      {error && (
        <p className="text-intent-danger text-center">
          <FormattedMessage {...commonMessages.genericError} />
          <span>: {error ? error.message : ''}</span>
        </p>
      )}

      {!called && (
        <MainButton intent={Intent.SUCCESS} large onClick={onConfirm}>
          <FormattedMessage {...messages.confirmButtonLabel} />
        </MainButton>
      )}

      {loading && <IndicatorLoading />}
    </RegistrationLayout>
  );
};
