import React, { useContext } from 'react';

import { Group } from '../models/Group';
import { GroupMember } from '../models/GroupMember';

import { AuthContext } from './AuthContext';

type ContextProps = {
  currentMember: GroupMember;
  group: Group;
};

export const GroupContext = React.createContext<ContextProps>({} as ContextProps);

export type GroupProviderProps = Partial<ContextProps>;

export const GroupProvider: React.FC<GroupProviderProps> = ({ children, group }) => {
  const { profile } = useContext(AuthContext).user!;

  const existingGroup = group! as Group;

  const currentMember = existingGroup.members.find(member => member.profile?.id === profile.id) as GroupMember;

  return <GroupContext.Provider value={{ group: existingGroup, currentMember }}>{children}</GroupContext.Provider>;
};
