import React from 'react';
import { Link, LinkProps, NavLink, NavLinkProps } from 'react-router-dom';

import { LocaleContext } from '../../contexts/LocaleContext';

export type Props = (LinkProps | NavLinkProps) & { isNav?: boolean };

export const LocaleLink: React.FC<Props> = ({ to, isNav, ...props }) => {
  const { locale } = React.useContext(LocaleContext);

  let localizedTo: LinkProps['to'] = '';
  switch (typeof to) {
    case 'string':
      localizedTo = `/${[locale, to].join('/')}`;
      break;
    case 'object':
      localizedTo = { ...to, pathname: `/${[locale, to.pathname].join('/')}` };
  }

  if (isNav) {
    return <NavLink {...(props as NavLinkProps)} to={localizedTo} />;
  }

  return <Link {...(props as LinkProps)} to={localizedTo} />;
};
