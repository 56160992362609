import { defineMessages } from 'react-intl';

export const validationMessages = defineMessages({
  mixedDefault: {
    id: 'validationMixedDefault',
    defaultMessage: 'Value is invalid',
  },
  mixedRequired: {
    id: 'validationMixedRequired',
    defaultMessage: 'Value is required',
  },
  mixedOneOf: {
    id: 'validationMixedOneOf',
    defaultMessage: 'Value must be: {values}',
  },
  mixedNotOneOf: {
    id: 'validationMixedNotOneOf',
    defaultMessage: 'Value must not be: {values}',
  },
  mixedDefined: {
    id: 'validationMixedDefined',
    defaultMessage: 'Value must be defined',
  },
  stringLength: {
    id: 'validationStringLength',
    defaultMessage: 'Exactly {length} characters',
  },
  stringMin: {
    id: 'validationStringMin',
    defaultMessage: 'At least {min} characters',
  },
  stringMax: {
    id: 'validationStringMax',
    defaultMessage: 'At most {max} characters',
  },
  stringMatches: {
    id: 'validationStringMatches',
    defaultMessage: 'Value must match the following: {regex}',
  },
  stringEmail: {
    id: 'validationStringEmail',
    defaultMessage: 'Invalid email',
  },
  stringUrl: {
    id: 'validationStringUrl',
    defaultMessage: 'Invalid URL',
  },
  stringUuid: {
    id: 'validationStringUuid',
    defaultMessage: 'Invalid ID',
  },
  stringTrim: {
    id: 'validationStringTrim',
    defaultMessage: 'Value must not start or end with a space',
  },
  stringLowercase: {
    id: 'validationStringLowercase',
    defaultMessage: 'Value must be a lowercase string',
  },
  stringUppercase: {
    id: 'validationStringUppercase',
    defaultMessage: 'Value must be an upper case string',
  },
  numberMin: {
    id: 'validationNumberMin',
    defaultMessage: 'Value must be greater than or equal to {min}',
  },
  numberMax: {
    id: 'validationNumberMax',
    defaultMessage: 'Value must be less than or equal to {max}',
  },
  numberLessThan: {
    id: 'validationNumberLessThan',
    defaultMessage: 'Value must be less than {less}',
  },
  numberMoreThan: {
    id: 'validationNumberMoreThan',
    defaultMessage: 'Value must be greater than {more}',
  },
  numberNotEqual: {
    id: 'validationNumberNotEqual',
    defaultMessage: 'Value must be not equal to {notEqual}',
  },
  numberPositive: {
    id: 'validationNumberPositive',
    defaultMessage: 'Value must be a positive number',
  },
  numberNegative: {
    id: 'validationNumberNegative',
    defaultMessage: 'Value must be a negative number',
  },
  numberInteger: {
    id: 'validationNumberInteger',
    defaultMessage: 'Value must be an integer',
  },
  dateMin: {
    id: 'validationDateMin',
    defaultMessage: 'Date must be later than {min}',
  },
  dateMax: {
    id: 'validationDateMax',
    defaultMessage: 'Date must be earlier than {min}',
  },
  objectNoUnknown: {
    id: 'validationObjectNoUnknown',
    defaultMessage: 'Missing: {unknown}',
  },
  arrayMin: {
    id: 'validationArrayMin',
    defaultMessage: '{min} at minimum',
  },
  arrayMax: {
    id: 'validationArrayMax',
    defaultMessage: '{max} at maximum',
  },
  fileSizeMax: {
    id: 'validationFileSizeMax',
    defaultMessage: 'Maximum file size is {max}',
  },
  fileSizeMin: {
    id: 'validationFileSizeMin',
    defaultMessage: 'Minimum file size is {max}',
  },
  fileFormat: {
    id: 'validationFileFormat',
    defaultMessage: 'Unsupported file format',
  },
});
